import logo from '../../utils/assets/logo.svg'
import dashboard from '../../utils/assets/dashboard.svg'
import dashboardActive from '../../utils/assets/dashboard-active.svg'
import message from '../../utils/assets/message.svg'
import messageActive from '../../utils/assets/message-active.svg'
import about from '../../utils/assets/about.svg'
import aboutActive from '../../utils/assets/about-active.svg'
import wallet from '../../utils/assets/wallet-money.svg'
import walletActive from '../../utils/assets/wallet-active.svg'
import folder from '../../utils/assets/folder.svg'
import folderActive from '../../utils/assets/folder-active.svg'
import userArrow from '../../utils/assets/user-arrow.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import './nav-bar-style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL, getEmail, getPhone, smallScreen, useLanguage } from '../../utils/constants'
import { getCurrentUserById, getUsersById, setCurrentUser, logoutUser } from '../user/store/action'
import { fetchProjects, setProjectsOfType } from '../projects/store/action'
import { fetchScans } from '../scans/store/action'
import Modal from "react-bootstrap/Modal";

export function NavBar(props) {
    const { setShowMenu } = props;
    const currentUserData = localStorage.getItem('currentUser');
    const currentUserId = currentUserData ? JSON.parse(currentUserData)?.id : '';
    const usersIdListData = localStorage.getItem('usersIdList');
    const activeUsersId = usersIdListData ? JSON.parse(usersIdListData) : [];
    const language = localStorage.getItem('language')
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const activeUsers = useSelector((store) => store.userReducer.activeUsers);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const location = useLocation();
    const navigate = useNavigate()
    const [activeLink, setActiveLink] = useState('/login');
    const [isUserClick, setIsUserClick] = useState(false)
    const [isOpenFoldersType, setIsOpenFoldersType] = useState(false)
    const [isShowAreYouSure, setIsShowAreYouSure] = useState(false)
    const isSmallScreen = smallScreen()
    const t = useLanguage()
    const dispatch = useDispatch();
    const isCurrentUserEffect = useRef(true)
    const isUsersEffect = useRef(true)
    // const typesList = ['mezuza'];
    const typesList = ['mezuza', 'tefillin'];
    const projectsList = useSelector((store) => store.projectReducer.projects);

    useEffect(() => {
        if (currentUserId && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId, currentUser])

    const setProjectOfTypeFunc = async (type) => {
        setActiveLink(`/folders?type=${type}`)
        const projects = projectsList?.filter(proj => proj.type == type)
        await dispatch(setProjectsOfType(projects));
        if (isSmallScreen) setShowMenu(false);
    }

    const linkData = [
        { path: '/dashboard', icon: [dashboard, dashboardActive], text: t.navbar.homePage },
        { path: '/folders', icon: [folder, folderActive], text: t.navbar.folders },
        { path: '/payment-page', icon: [wallet, walletActive], text: t.navbar.accountManagement },
        { path: '/contact', icon: [message, messageActive], text: t.navbar.contact },
        currentUser?.Role == 'admin' ? { path: '/cropper', icon: [message, messageActive], text: 'cropper' } : {},
        // BASE_URL == 'prod' ? {} : { path: '/about', icon: [about, aboutActive], text: 'הורדת סריקות' },
    ];

    const renderLink = (link) => (
        <div key={link.path}>
            <Link to={link.path != '/folders' ? link.path : undefined} className={`${activeLink === link.path ? `active-link ${isSmallScreen ? 'mobile' : ''} ${currentLanguage == 'en-US' ? 'ltr' : ''}` : `no-underline-link  ${currentLanguage == 'en-US' ? 'ltr' : ''} ${isSmallScreen ? 'mobile' : ''}`}`}>
                <div>
                    <div className={isSmallScreen ? `link-mobile ${currentLanguage == 'en-US' ? 'ltr' : ''} ${activeLink === link.path ? 'active1' : ''} ` : `link ${currentLanguage == 'en-US' ? 'ltr' : ''}`} onClick={link.path == '/folders' ? () => { setActiveLink('/folders'); setIsOpenFoldersType(!isOpenFoldersType) } : isSmallScreen ? () => { setShowMenu(false) } : () => setIsOpenFoldersType(false)} >
                        <img src={activeLink === link.path ? link.icon[1] : link.icon[0]} className={isSmallScreen ? 'img-mobile' : 'img'} />
                        <div className={'link-text'}>{link.text}</div>
                    </div>
                </div>
            </Link>

            {isOpenFoldersType && link.path == '/folders' &&
                typesList.map(type =>
                    <Link onClick={() => setProjectOfTypeFunc(type)} to={`/folders?type=${type}`}
                        className={`folder-link ${currentLanguage == 'en-US' ? 'ltr' : isSmallScreen ? 'mobile' : ''} ${activeLink == `/folders?type=${type}` ? 'active' : ''}`} >
                        <img src={activeLink == `/folders?type=${type}` ? folderActive : folder} className='image-folder-link'></img>
                        <div>{t.scanTypes[type]}</div>
                    </Link>
                )}
        </div>
    );

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        if (!currentUserId && activeUsersId?.length > 0)
            navigate('/login')
    }, [currentUserId])


    useEffect(() => {
        if (activeUsersId && activeUsers?.length == 0 && isUsersEffect.current) {
            getUsersByIdFunc()
            isUsersEffect.current = false;
        }
    }, [activeUsersId]);

    const getUsersByIdFunc = async () => {
        try {
            const updatedActiveUsers = activeUsersId?.filter(user => {
                const userLastLogoutTimeString = user.time;
                if (userLastLogoutTimeString) {
                    const userLastLogoutTime = new Date(userLastLogoutTimeString);
                    const oneHourLater = new Date(userLastLogoutTime.getTime() + 60 * 60 * 1000);
                    const newTime = new Date();

                    return !(newTime >= oneHourLater);
                }

                return true; // Keep users without a valid time property
            });
            localStorage.setItem('usersIdList', JSON.stringify(updatedActiveUsers));
            await dispatch(getUsersById(updatedActiveUsers))
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setActiveLink(location.pathname + location.search);
    }, [location]);

    const handleClick = async () => {
        console.log('handleClick');
        setIsUserClick(!isUserClick)
        getUsersByIdFunc()
    }

    const changeCurrentUser = async (user) => {
        await dispatch(setCurrentUser(user))
        setIsUserClick(false)
        navigate('/dashboard')
    }

    const addUser = () => {
        if (activeUsersId.length < 10) {
            navigate('/login', { state: { addUser: 'add-user' } })
            setIsUserClick(false)
        }
        else alert('לא ניתן להוסיף משתמש')
    }

    const deleteUserFunc = async () => {
        try {
            await dispatch(logoutUser(currentUserId));
            setIsShowAreYouSure(false);
            setShowMenu(false);
            navigate('/login')
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        async function fetchProjectAndScans() {
            if (currentUserId) {
                await dispatch(fetchScans(currentUserId))
                await dispatch(fetchProjects(currentUserId))
            }
        }
        fetchProjectAndScans();

    }, [currentUserId])

    return (
        <div className={isSmallScreen ? 'container navbar-wrapper-mobile' : "container-fluid navbar-wrapper"}>
            <div className='running'>
                <img className={isSmallScreen ? 'img-logo-mobile' : 'img-logo'} src={logo} onClick={currentUserId ? isSmallScreen ? () => { setShowMenu(false); navigate('/dashboard'); } : () => navigate('/dashboard') : undefined}></img>
                <div className='version'>בהרצה</div>
            </div>

            {(currentUserId && currentUser && Object.keys(currentUser).length !== 0) ?
                (
                    <>
                        <nav className={`nav flex-column navbar ${isSmallScreen ? 'navbar-mobile' : ''}`}>
                            {linkData.map((link, index) => (
                                link.path &&
                                <React.Fragment key={index}>{renderLink(link)}</React.Fragment>
                            ))}
                        </nav>

                        <div className={`users-list ${activeUsers && activeUsers.length > 4 && 'overflow'}`}>
                            {isUserClick &&
                                <div type='button' className={`navbar-footer-btn ${isSmallScreen && 'mobile '} add`} onClick={isSmallScreen ? () => { setShowMenu(false); addUser(); } : addUser}> {t.navbar.addProfile}</div>
                            }
                            {isUserClick && activeUsers &&
                                activeUsers.map((user, index) => (
                                    user?._id != currentUser?._id &&
                                    <div type='button' className={isSmallScreen ? `user-wrapper-mobile ${currentLanguage == 'en-US' ? 'ltr' : ''}` : `user-wrapper ${currentLanguage == 'en-US' ? 'ltr' : ''}`} onClick={() => changeCurrentUser(user)} key={index}>
                                        {user?.profile ?
                                            <img src={user?.profile} className={isSmallScreen ? 'user-profile-mobile' : 'user-profile'}></img>
                                            :
                                            <div className={isSmallScreen ? 'user-profile-mobile text-center' : 'user-profile '} style={{ backgroundColor: user?.color }}>
                                                {user?.customerName.charAt(0).toUpperCase()}
                                            </div>}
                                        <div className={'user-name'}>{user?.customerName}</div>
                                    </div>
                                ))}

                            <div className={`${isSmallScreen ? `user-wrapper-mobile ` : 'user-wrapper'} ${isUserClick ? 'active' : ''}  ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>
                                {currentUser.profile ?
                                    <img src={currentUser?.profile} className={isSmallScreen ? 'user-profile-mobile' : 'user-profile'}></img>
                                    :
                                    <div className={`user-profile `} style={{ backgroundColor: currentUser?.color }}>
                                        <div className='profile-letter'>
                                            {currentUser?.customerName?.charAt(0).toUpperCase()}
                                        </div>
                                    </div>
                                }
                                <div className={'user-name'}>{currentUser?.customerName}</div>
                                <img src={userArrow} className={isSmallScreen ? (isUserClick ? 'user-arrow-mobile rotate' : 'user-arrow-mobile') : (isUserClick ? 'user-arrow rotate' : 'user-arrow')} onClick={handleClick}></img>
                            </div>

                            {isUserClick &&
                                <div className={`navbar-footer ${isSmallScreen ? 'mobile' : ''}`}>
                                    <div type='button' className={`navbar-footer-btn ${isSmallScreen && 'mobile'}`} onClick={isSmallScreen ? () => { setShowMenu(false); navigate('/edit-profile'); } : () => { navigate('/edit-profile'); setIsUserClick(false) }}>{t.navbar.editProfile}</div>
                                    <div type='button' className={`navbar-footer-btn ${isSmallScreen && 'mobile'}`} onClick={() => setIsShowAreYouSure(true)}>{t.navbar.removeProfile}</div>
                                </div>
                            }

                        </div>
                    </>
                )
                :
                (
                    <div className="position-fixed bottom-0 m-3 ">
                        {currentLanguage != 'en-US' ?
                            <div>
                                <div className="conpany-name">StamScanner</div>
                                <div className="about-company">תוכנה לבדיקת ספרי תורה וכתבי קודש באמצעות טכנולוגיה חדשנית</div>
                                <div className="location-company">
                                    <div className='small'>טלפון: {getPhone()}</div>
                                    <div className='small'>מייל: {getEmail()}</div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className={`conpany-name ltr`}>StamScanner</div>
                                <div className={`about-company ltr`}>Software for checking Torah scrolls and Holy Scriptures using innovative technology</div>
                                <div className={`location-company ltr`}>
                                    <div className='small'>Phone: {getPhone()}</div>
                                    <div className='small'>Mail: {getEmail()}</div>
                                </div>
                            </div>
                        }
                    </div>
                )
            }

            <Modal
                show={isShowAreYouSure}
                onHide={() => setIsShowAreYouSure(false)}
                centered
                className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
            >
                <Modal.Body>
                    <div
                        type="button"
                        className={isSmallScreen ? "are-you-sure mobile" : "are-you-sure"}
                    >
                        <div className={`back-text ${isSmallScreen ? "mobile" : ""}`}>
                            {t.uploadScans.areYouSure}
                        </div>
                    </div>
                    <div className={`frame-b ${isSmallScreen ? "mobile" : ""} ${currentLanguage == "en-US" ? "" : "reverse"}`}>
                        <div
                            type="button"
                            className={`btn-ok ${isSmallScreen ? 'mobile' : ''} col-6`}
                            onClick={deleteUserFunc}
                        >
                            <div className="ok-button">
                                <div>{t.uploadScans.ok}</div>
                            </div>
                        </div>
                        <div
                            type="button"
                            className={`btn-cancel ${isSmallScreen ? 'mobile' : ''} col-6`}
                            onClick={() => setIsShowAreYouSure(false)}
                        >
                            <div className={`btn-ok-text ${isSmallScreen ? 'mobile' : ''} cancel`}>
                                {t.uploadScans.cancel}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div >
    )
}


