
import * as UsersApiClient from '../api/user-api-client'
import { CHANGE_LANGUAGE, COMPARE_PASSWORD, GET_CURRENT_USER, GET_USER_LOGIN, GET_USERS, LOGOUT, SEND_EMAIL, SET_CURRENT_USER, UPDATE_DONT_SHOW_AGAIN, UPDATE_TYPE } from "./types";


export const getUserLogin = (user) => async dispatch => {
    try {
        const res = await UsersApiClient.get_user_login(user);
        console.log('res in getUserLogin', res);
        dispatch({
            type: GET_USER_LOGIN,
            payload: res
        })
        return res
    }
    catch (e) {
        console.log(e);
    }
}

export const getUsersById = (usersId) => async dispatch => {
    try {
        const res = await UsersApiClient.get_users_by_id(usersId);
        dispatch({
            type: GET_USERS,
            payload: res.data ? res.data : res.message
        })
        return res.data ? res.data : res.message
    }
    catch (e) {
        console.log(e);
    }
}

export const getCurrentUserById = (userId) => async dispatch => {
    try {
        const res = await UsersApiClient.get_current_user_by_id({ 'userId': userId });
        dispatch({
            type: GET_CURRENT_USER,
            payload: res.data
        })
        return res.data
    }
    catch (e) {
        console.log(e);
    }
}

export const getUsersByRole = (role) => async dispatch => {
    try {
        const res = await UsersApiClient.get_users_by_role({ 'role': role });
        dispatch({
            type: GET_CURRENT_USER,
            payload: res.data
        })
        return res.data
    }
    catch (e) {
        console.log(e);
    }
}


export const setCurrentUser = (user) => async dispatch => {
    try {
        dispatch({
            type: SET_CURRENT_USER,
            payload: user
        })
    }
    catch (e) {
        console.log(e);
    }
}

export const updateUserBalance = (userId, sumToPay) => async dispatch => {
    try {
        console.log("in updateUserBalance")
        const res = await UsersApiClient.updateUserBalance({ userId: userId, sumToPay: sumToPay });
        console.log("res", res)
        dispatch({
            type: SET_CURRENT_USER,
            payload: res.data
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }
}
export const updateUserBalanceInProgress = (userId, sumToPay) => async dispatch => {
    try {
        console.log("in updateUserBalance")
        const res = await UsersApiClient.updateUserBalanceInProgress({ userId: userId, sumToPay: sumToPay });
        console.log("res", res)
        dispatch({
            type: SET_CURRENT_USER,
            payload: res.data
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }
}
export const updateUserLastPayment = (userId, sumToPay) => async dispatch => {
    try {
        const res = await UsersApiClient.updateUserLastPayment({ userId: userId, sumToPay: sumToPay });
        console.log("res", res)
        dispatch({
            type: SET_CURRENT_USER,
            payload: res.data
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }
}
export const updateUserLastPaymentAndBalance = (userId, sumToPay, refoundSum) => async dispatch => {
    try {
        const res = await UsersApiClient.updateUserLastPaymentAndBalance({ userId: userId, sumToPay: sumToPay, refoundSum: refoundSum });
        console.log("res", res)
        dispatch({
            type: SET_CURRENT_USER,
            payload: res.data
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }
}

export const createUser = (user) => async dispatch => {

    try {
        const res = await UsersApiClient.create_user(user);
        console.log('createUser res', res);
        dispatch({
            type: GET_USER_LOGIN,
            payload: res
        })
        return res.data;
    }
    catch (e) {
        console.log(e);
    }
}

export const getUserGoogelLogin = (token) => async dispatch => {
    try {
        const res = await UsersApiClient.getUserGoogelLogin(token);
        console.log('res in getUserGoogelLogin', res);
        dispatch({
            type: GET_USER_LOGIN,
            payload: res
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }
}

export const isEmailExist = (usersEmail) => async dispatch => {
    try {
        const res = await UsersApiClient.is_email_exist(usersEmail);
        return res;
    }
    catch (e) {
        console.log(e);
    }
}

export const sendMail = (obj) => async dispatch => {
    try {
        const res = await UsersApiClient.send_email(obj);
        dispatch({
            type: SEND_EMAIL,
            payload: obj
        })
    }
    catch (e) {
        console.log(e);
    }

}

export const updateUserPassword = (obj) => async dispatch => {
    try {
        const res = await UsersApiClient.update_user_password(obj);
        console.log('res👍🏻👍🏻👍🏻', res);
        dispatch({
            type: GET_USER_LOGIN,
            payload: res
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }

}
export const updateUserScanType = (obj) => async dispatch => {
    console.log('updateUserScanType',obj);
    try {
        const res = await UsersApiClient.update_user_scan_type(obj);
        console.log('res updateUserScanType👍🏻👍🏻👍🏻', res);
        dispatch({
            type: UPDATE_TYPE,
            payload: res
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }

}
export const updateUserDontShowAgain = (obj) => async dispatch => {
    try {
        const res = await UsersApiClient.update_user_dont_show_again(obj);
        console.log('res updateUserScanType👍🏻👍🏻👍🏻', res);
        dispatch({
            type: UPDATE_DONT_SHOW_AGAIN,
            payload: res
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }

}

export const updateUser = (obj) => async dispatch => {
    try {
        const res = await UsersApiClient.update_user(obj);
        dispatch({
            type: GET_USER_LOGIN,
            payload: res
        })
        return res.data ? res.data : res.message;
    }
    catch (e) {
        console.log(e);
    }
}

export const deleteUser = (userId) => async dispatch => {
    try {
        const res = await UsersApiClient.delete_user({ userId: userId });
        dispatch({
            type: LOGOUT,
            payload: userId
        })
        return res;
    }
    catch (e) {
        console.log(e);
    }

}
export const logoutUser = (userId) => async dispatch => {
    try {
        dispatch({
            type: LOGOUT,
            payload: userId
        })
    }
    catch (e) {
        console.log(e);
    }

}
export const comparePassword = (obj) => async dispatch => {
    try {
        const res = await UsersApiClient.compare_password(obj);
        dispatch({
            type: COMPARE_PASSWORD,
            payload: res.data ? res.data : res.message
        })
        return res.data;
    }
    catch (e) {
        console.log(e);
    }
}

export const changeLanguage = (lenguage) => async dispatch => {
    try {
        dispatch({
            type: CHANGE_LANGUAGE,
            payload: lenguage
        })
    }
    catch (e) {
        console.log(e);
    }

}