

import Header from "../../header/header";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL, smallScreen, useLanguage } from "../../../utils/constants";
import bit from '../../../utils/assets/bit.svg'
import paypal from '../../../utils/assets/paypal.svg'
import './payment-style.scss'
import axios from "axios";

const errMessage = (msgs, c) => {
  let message = msgs?.find((x) => x === c);

  if (message) {
    return message;
  }
  return "";
};

const ErrorMsg = (props) => {
  const err = props.msg;
  const t = useLanguage();

  if (!err || err === "") {
    return null;
  }

  return (
    <div className="error-div">
      {t.paymentPageError[err]}
    </div>
  );
};

const PaymentForm = (props) => {
  const responseMsgs = props.msg;
  const sumToBalance = sessionStorage.getItem('sumToBalace')
  console.log('sumToBalance', sumToBalance);
  
  const sumInStorage = JSON.parse(sessionStorage.getItem('sum'))?.sum
  const [sum, setSum] = useState(sumInStorage || 50);
  const paypalUrl = `https://secure5.tranzila.com/cgi-bin/tranzila31p.cgi?supplier=sscannerch&currency=1&sum=${sumInStorage}&sumToBalance=${sumToBalance}&useraction=commit`;
  const language = useSelector((store) => store.userReducer.language);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const t = useLanguage();
  const isSmallScreen = smallScreen();
  const [monthYear, setMonthYear] = useState('');
  const [showCurrencyList, setShowCurrencyList] = useState(false);
  const currencyList = ['ILS']
  const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
  const currentUser = useSelector((store) => store.userReducer.currentUser);
  // const currencyList = ['ILS', 'USD', 'EUR']
  const cuponCodeUse = JSON.parse(sessionStorage.getItem('sum')).cuponCodeUse;
  console.log('cuponCodeUse', cuponCodeUse);
  const currency = sessionStorage.getItem("currency") || 'ILS';
  const [currentCurrency, setCurrentCurrency] = useState(currency);
  const [cuponCodeValue, setCuponCodeValue] = useState('');
  const [cuponCodeError, setCuponCodeError] = useState('')
  const [cuponCodeGood, setCuponCodeGood] = useState(false);
  const cuponCode10Off = process.env.REACT_APP_CUPON_CODE_10_OFF;

  useEffect(() => {
    setSum(sumInStorage)
  }, [sumInStorage])

  const autoCompleteMonthYear = (event) => {
    try {
      let inputValue = event.target.value;

      inputValue = inputValue.replace(/\D/g, '');

      if (inputValue.length >= 2) {
        inputValue = inputValue.substring(0, 2) + '/login' + inputValue.substring(2);
      }

      setMonthYear(inputValue);
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCurrentLanguage(language)
  }, [language])

  const changeCurrency = (item) => {
    setShowCurrencyList(false);
    setCurrentCurrency(item);
    sessionStorage.setItem('currency', item)
  }

  const handlecuponCodeChange = async (event) => {
    setCuponCodeError('');
    setCuponCodeGood(false);
    setCuponCodeValue(event.target.value);
    if (cuponCodeUse == true) {
      setCuponCodeGood(true);
    }
    else
      if (event.target.value == cuponCode10Off) {
        console.log('cupon code activate');

        setCuponCodeGood(true)
        const valueToCalculate = 0.9;

        sessionStorage.setItem('sum', JSON.stringify({ sum: sum * valueToCalculate, cuponCodeUse: true }));

        const obj = {
          userId: currentUserId,
          userName: currentUser.customerName,
          amount: sum,
          amountAfterCupon: sum * valueToCalculate
        }

        console.log('obj', obj);

        const response = await axios.post(`${BASE_URL}/api/cuponCode/add/`, obj);
        console.log('response', response);
      }
      else {
        setCuponCodeError(t.payment.cuponCodeError);
      }

  };


  return (
    <div className={isSmallScreen ? '' : 'container'}>
      <div className={'col'}>
        <div className={isSmallScreen ? "scrollable payment" : ''}>
          <form
            id="payment_form"
            className={`container-register  ${isSmallScreen ? 'mobile' : ''}`}
            onSubmit={props.handleSubmit}
          >
            <div className={currentLanguage == 'en-US' ? 'pr-0' : 'pl-0'}>
              <Header page={'payment'} context={[t.payment.paymentByCreditCard]}></Header>
            </div>

            <div className="inputs-login">

              {/* amount and cupon code */}
              <div className={isSmallScreen ? "container-email-password-mobile" : "container-email-password row"}>

                {/* amount */}
                <div className="form-group">
                  <label htmlFor="payment_amount" >
                    {t.payment.amount}
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 `}
                      name="amount"
                      id="payment_amount"
                      value={sum}
                      disabled
                    />

                    <div className="" onClick={() => setShowCurrencyList(true)}>
                      <div className="currency-button">
                        {currentCurrency}
                      </div>
                      {showCurrencyList && <div className='currency-list'>
                        {currencyList.map(item => item != currentCurrency && <span className='currency-button' onClick={(e) => { e.stopPropagation(); changeCurrency(item); }}>{item}</span>)}
                      </div>}
                    </div>
                  </div>
                  <ErrorMsg msg={errMessage(responseMsgs, t.payment.amountError)} />
                </div>

                {/* cupon code */}
                <div className="form-group">
                  <label htmlFor="payment_amount" >
                    {t.payment.cuponCode}
                  </label>
                  <div className="input-group cupon-code">
                    <input
                      type="text"
                      className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 `}
                      name="amount"
                      id="payment_amount"
                      onChange={(e) => handlecuponCodeChange(e)}
                      onFocus={() => setCuponCodeError('')}
                    />
                  </div>
                  {cuponCodeError != '' &&
                    <div className="cupon-code-error col-10" >{cuponCodeError}</div>
                  }
                  {cuponCodeGood &&
                    <div className="cupon-code-error black col-10" >קוד קופון הוחל</div>
                  }

                  {/* <ErrorMsg msg={errMessage(responseMsgs, t.payment.amountError)} /> */}
                </div>

              </div>

              {/* card number and id  */}
              <div className={isSmallScreen ? "container-email-password-mobile" : "container-email-password row"}>
                <div className="form-group">
                  <label htmlFor="credit_card_num" className="label">
                    {t.payment.creditCardNum}
                  </label>
                  <div className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 `} id="credit_card_num" ></div>
                  <div id="errors_for_number" className="error_message"></div>
                  <ErrorMsg msg={errMessage(responseMsgs, "credit_card_number")}
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="card_holder_id_number"
                    className="label">
                    {t.payment.cardHolderIdNumber}
                  </label>
                  <div
                    className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 `}
                    id="card_holder_id_number"></div>
                  <div id="errors_for_id" className="error_message"></div>
                  <ErrorMsg
                    msg={errMessage(responseMsgs, "card_holder_id_number")}
                  />
                </div>
              </div>

              {/* expity and CVV */}
              <div className={isSmallScreen ? "container-email-password-mobile" : "container-email-password row"}>
                <div className="form-group">
                  <label htmlFor="expiry">
                    {t.payment.expiry}
                  </label>
                  <div
                    value={monthYear}
                    onChange={autoCompleteMonthYear}
                    className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 `}
                    id="expiry"></div>
                  <div id="errors_for_expiry" className="error_message"></div>
                  <ErrorMsg msg={errMessage(responseMsgs, "expiry")} />
                </div>

                <div className="form-group">
                  <label htmlFor="cvv">
                    {t.payment.cvv}
                  </label>
                  <div
                    className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 `}
                    id="cvv"></div>
                  <div id="errors_for_cvv" className="error_message"></div>
                  <ErrorMsg msg={errMessage(responseMsgs, "cvv")} />
                </div>
              </div>

              {/* pay button */}
              <div className="form-group text-center">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={props.disabled}
                >
                  {t.payment.pay}
                </button>
              </div>

            </div>

            <div className='container-line'>
              <div className='line'></div>{t.user.or}<div className='line'></div>
            </div>

            {/* pay with bit and paypal */}
            <div className="login-ways">
              {/* bit */}
              <div className={isSmallScreen ? '' : 'login-button'} type='button' onClick={props.payWithBit}>
                <img src={bit}></img>
                {!isSmallScreen && t.payment.payWithBit}
              </div>
              {/* paypal */}
              <a href={paypalUrl} title="תשלום ב Paypal" className="no-link-style"
                onclick={`javascript:window.open(${paypalUrl},'WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;`}>
                <div className={isSmallScreen ? '' : 'login-button'} type='button'>
                  <img src={paypal}></img>
                  {!isSmallScreen && t.payment.payWithPaypal}
                </div>
              </a>

            </div>

          </form>
        </div >
      </div >
    </div >
  );

};

export default PaymentForm;
