import { useDispatch } from "react-redux";
import { createReport } from "../../report/store/action";
import { useState } from "react";
import './test.scss'
import Spinner from "../../../utils/spinner/spinner";
export default function TestCreateReport() {

    const dispatch = useDispatch();
    const [numberOfLoops, setNumberOfLoops] = useState(6);
    const [responsesStatus, setResponsesStatus] = useState([]);
    const [disabledCreateReport, setDisabledCreateReport] = useState(false);
    const [indexes, setIndexes] = useState([]);

    const runTest = async () => {
        setDisabledCreateReport(true)
        const requestBody = {
            projectName: "new",
            scanType: "מזוזה",
            userName: "user name",
            scans: [
                {
                    "userId": "6638d6ca529335435f23e53c",
                    "scanId": "6638f3f936832fddf46c9a5d",
                    "scanName": "מזוזה 1",
                    "relatedProject": "6638f38736832fddf46c9a46",
                    "image": {
                        "_id": "6638f3fc36832fddf46c9a5e",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan6638f3f936832fddf46c9a5d.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 929752,
                        "createdAt": "2024-05-06T15:12:18.828Z",
                        "__v": 0
                    },
                    "type": "mezuza"
                },
                {
                    "userId": "6638d6ca529335435f23e53c",
                    "scanId": "6638f3fc36832fddf46c9a62",
                    "scanName": "מזוזה 2",
                    "relatedProject": "6638f38736832fddf46c9a46",
                    "image": {
                        "_id": "6638f3fc36832fddf46c9a63",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan6638f3fc36832fddf46c9a62.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 285021,
                        "createdAt": "2024-05-06T15:12:18.828Z",
                        "__v": 0
                    },
                    "type": "mezuza"
                },
                {
                    "userId": "6638d6ca529335435f23e53c",
                    "scanId": "6638f3fd36832fddf46c9a67",
                    "scanName": "מזוזה 3",
                    "relatedProject": "6638f38736832fddf46c9a46",
                    "image": {
                        "_id": "6638f3fd36832fddf46c9a68",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan6638f3fd36832fddf46c9a67.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 285021,
                        "createdAt": "2024-05-06T15:12:18.828Z",
                        "__v": 0
                    },
                    "type": "mezuza"
                },
                {
                    "userId": "6638d6ca529335435f23e53c",
                    "scanId": "6638f3fe36832fddf46c9a6c",
                    "scanName": "מזוזה 4",
                    "relatedProject": "6638f38736832fddf46c9a46",
                    "image": {
                        "_id": "6638f3fe36832fddf46c9a6d",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan6638f3fe36832fddf46c9a6c.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 257570,
                        "createdAt": "2024-05-06T15:12:18.828Z",
                        "__v": 0
                    },
                    "type": "mezuza"
                },
                {
                    "userId": "6638d6ca529335435f23e53c",
                    "scanId": "6638f3fe36832fddf46c9a71",
                    "scanName": "מזוזה 5",
                    "relatedProject": "6638f38736832fddf46c9a46",
                    "image": {
                        "_id": "6638f3fe36832fddf46c9a72",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan6638f3fe36832fddf46c9a71.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 257570,
                        "createdAt": "2024-05-06T15:12:18.828Z",
                        "__v": 0
                    },
                    "type": "mezuza"
                },
                {
                    "userId": "6638d6ca529335435f23e53c",
                    "scanId": "6638f3ff36832fddf46c9a76",
                    "scanName": "מזוזה 6",
                    "relatedProject": "6638f38736832fddf46c9a46",
                    "image": {
                        "_id": "6638f3ff36832fddf46c9a77",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan6638f3ff36832fddf46c9a76.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 297310,
                        "createdAt": "2024-05-06T15:12:18.828Z",
                        "__v": 0
                    },
                    "type": "mezuza"
                }
            ]
        };

        const responsesStatus = []

        await Promise.all(Array.from({ length: numberOfLoops }, async (_, index) => {
            try {
                requestBody.projectName = index;
                setIndexes(prevIndexes => [...prevIndexes, index]);
                const report = await dispatch(createReport(requestBody))
                responsesStatus.push(index);
                console.log(index + ' ' + report.status);
            } catch (error) {
                responsesStatus.push('failed');
            }
        }));
        setResponsesStatus(responsesStatus);
        setDisabledCreateReport(false);
        console.log('responsesStatus✨✨✨',responsesStatus);
        return responsesStatus;
    }

    const handleInputChange = (event) => {
        const value = parseInt(event.target.value);
        setNumberOfLoops(value);
    };

    return (
        <div className="test">
            number of loops:
            <input type="number"
                value={numberOfLoops}
                onChange={handleInputChange}
            ></input>

            <button className="btn btn-primary" onClick={runTest}>RUN</button>
            <div className="row row-container">
                indexes:{indexes && indexes.map(index => <div>{index}</div>)}
            </div>
            <div className="row row-container">
                responsesStatus:{responsesStatus && responsesStatus.map(index => <div>{index}</div>)}
            </div>

            {/* spinner on disabled */}
            {disabledCreateReport == true && (
                <Spinner value={100}></Spinner>
            )}
        </div>
    )
}