
import Header from "../../header/header";
import { smallScreen, useLanguage } from "../../../utils/constants";
import './payment-page-style.scss'
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUserById } from "../../user/store/action";
import { useRef } from "react";
import { useEffect } from "react";
import wallet from '../../../utils/assets/wallet.svg'

export default function PaymentPage() {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isCurrentUserEffect = useRef(true)
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const isSmallScreen = smallScreen();
    const t = useLanguage();
    const paymentPackages = [
        { 'name': t.payment.goldPackage, 'nis': 200, 'numbersOfScans': 60, 'newNumbersOfScans': 200, className: 'golden' },
        { 'name': t.payment.silverPackage, 'nis': 160, 'numbersOfScans': 30, 'newNumbersOfScans': 80, className: 'silver' },
        { 'name': t.payment.basicPackage, 'nis': 75, 'numbersOfScans': 10, 'newNumbersOfScans': 30, className: 'green' },
        { 'name': t.payment.marchantPackage, 'text': t.payment.adjustPackage, className: 'red' },
        { 'name': t.payment.singlePackage, 'nis': 10, 'numbersOfScans': 3, 'newNumbersOfScans': 3, className: 'blue' },
    ]
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])

    const getIframe = async (index) => {
        try {
            sessionStorage.setItem('sum', JSON.stringify({ sum: paymentPackages[index].nis, cuponCodeUse: false }));
            sessionStorage.setItem('sumToBalace', JSON.stringify(paymentPackages[index].newNumbersOfScans));
            navigate('/payment', { state: { sum: paymentPackages[index].nis, currency: 'ILS' } })
        }
        catch (error) {
            console.log('getIframe error', error);
        }
    }

    return (
        <div>
            <div className={`${isSmallScreen ? 'pt-2 pb-1' : (currentLanguage == 'en-US' ? 'pl-3 pr-10' : 'pl-10 pr-3')}`}>
                <Header page={'payment'} context={[t.payment.creditManagment]}></Header>
            </div>
            <div className={`${isSmallScreen ? 'payments-wrapper-mobile' : 'payments-wrapper'} ${currentLanguage == 'en-US' ? 'ltr' : ''} `}>

                <div className={`payment-container  ${isSmallScreen ? '' : ''} `}>
                    <div>
                        <div className={`packages-wrapper  ${isSmallScreen ? 'mobile' : ''}`}>
                            {paymentPackages && paymentPackages.map((pack, index) => (
                                <div className={`package ${pack.className} ${isSmallScreen ? 'mobile' : ''} ${isSmallScreen ? '' : ''}`} type="button"
                                    onClick={() => {
                                        if (index !== 3) {
                                            getIframe(index)
                                        }
                                    }}
                                >
                                    <div className={`package-name ${isSmallScreen ? 'mobile' : ''}`}>{pack.name}</div>
                                    {pack.nis ?
                                        <div className={`package-a ${isSmallScreen ? 'mobile' : ''}`}>
                                            <div className="wallet-wrapper">
                                                <img src={wallet}></img>
                                            </div>
                                            <div className="">
                                                <div className="nis-to-pay">{pack.nis} {t.payment.nisIcon} = </div>
                                                {index != 4 && <div className="erased-number">
                                                    <div className="erased-number-content">{pack.numbersOfScans} {t.payment.scans}</div>
                                                </div>
                                                }
                                            </div>
                                            <div className="new-credits-wrapper">
                                                <div className="new-credits">{pack.newNumbersOfScans}</div>
                                                <div className="new-credits nis"> {t.payment.scans}</div>
                                            </div>
                                        </div>
                                        :
                                        <div className={`package-a ${isSmallScreen ? 'mobile' : ''}`}>
                                            <div className="wallet-wrapper">
                                                <img src={wallet}></img>
                                            </div>
                                            <Link to="/contact" className='text-decoration-none'>{pack.text}</Link>
                                        </div>
                                    }

                                </div>
                            ))
                            }

                            <div className={`package ${isSmallScreen ? 'mobile' : ''} `}>
                                <div className="message-row">
                                    <div className="blue-text medium">{t.payment.payAttention}</div>
                                    <div className="blue-text black medium">{t.payment.message}</div>
                                </div>
                                <div className="line-a"></div>
                                <div className="payments-scans-data">
                                    <div>
                                        <div className={"bold-text"}>{t.payment.lastUse}</div>
                                        <div className="payment-wrapper">
                                            <div className={`payment-num ${isSmallScreen ? 'mobile' : ''}`}>{currentUser?.lastPayment || 0} </div>
                                            <div className="bold-text-small">{t.payment.scans}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"bold-text"}>{t.payment.leftToUse}</div>
                                        <div className="payment-wrapper">
                                            <div className={`payment-num ${isSmallScreen ? 'mobile' : ''}`}>{currentUser?.balance || 0}</div>
                                            <div className="bold-text-small">{t.payment.scans}</div>
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div >
    )
}