// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.slider.padding {
  padding-top: 10rem;
}

.slider.padding.mobile {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-colors-primary, #536DF6);
  opacity: 0.5;
  transition: opacity 0.3s;
}

.dot.active {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/projec-page/project-page-style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AACA;EACI,iBAAA;EACA,oBAAA;AAEJ;;AACA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,wDAAA;EACA,YAAA;EACA,wBAAA;AAEJ;;AACA;EACI,UAAA;AAEJ","sourcesContent":[".slider {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n}\n\n.slider.padding {\n    padding-top: 10rem;\n}\n.slider.padding.mobile {\n    padding-top: 5rem;\n    padding-bottom: 5rem;\n}\n\n.dot {\n    width: 20px;\n    height: 20px;\n    border-radius: 50%;\n    background-color: var(--primary-colors-primary, #536DF6);\n    opacity: 0.5;\n    transition: opacity 0.3s;\n}\n\n.dot.active {\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
