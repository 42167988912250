import axios from 'axios'
import { BASE_URL } from '../../../utils/constants';
import axiosThrottle from 'axios-request-throttle';

axiosThrottle.use(axios, { requestsPerSecond: 2 });

const URL = `${BASE_URL}/api/report/`;

export const createReport1 = async (scansObjects) => {
    try {
        console.log('createReport in api');
        const response = await axios.post(`${URL}create_report`, scansObjects);
        console.log('response in createReport in api', response);
        return response;
    } catch (error) {
        console.log('Error creating report:', error);
        return null;
    }
};
export const createReport = async (scansObjects) => {
    return axios.post(`${URL}create_report`, scansObjects)
        .then((response) => {
            // return your data here...
            console.log('response in createReport in api', response);
            return response;
        })
        .catch((error) => {
            console.log('Error creating report:', error);
            return null;
        })
};
//todo
export const createReport2 = (scansObjects) => {
    return new Promise((resolve, reject) => {
        axios.post(`${URL}create_report`, scansObjects)
            .then((response) => {
                // Return data on successful response
                console.log('response in createReport in api', response);
                resolve(response);
            })
            .catch((error) => {
                // Handle errors and reject the promise
                console.log('Error creating report:', error);
                reject(error);
            });
    });
};

export const reportsFetch = (userId) => {
    return axios.get(`${URL}fetch_all_reports/${userId}`).then((response) => response)
}

export const reportFetch = (reportId) => {
    return axios.get(`${URL}fetch_report/${reportId}`).then((response) => response.data)
}

export const fetchReportByProjectId = (obj) => {
    return axios.post(`${URL}fetch_report_by_project_id/`, obj).then((response) => response.data)
}

export const update_report = (obj) => {
    console.log('update_report',obj);
    return axios.put(`${URL}update_report/`, obj).then((response) => response)
}
export const updateReportPdf = (obj) => {
    return axios.post(`${URL}update_report_pdf/`, obj).then((response) => response)
}




