// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10rem;
  padding: 3rem;
  border-radius: 0.25rem;
  background: var(--4, #EBEDFC);
}

.about-pay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  height: 3rem;
  width: 10rem;
  color: white;
  border-radius: 0.25rem;
  background: var(--2, #536DF6);
}`, "",{"version":3,"sources":["webpack://./src/pages/about/about-style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EAEA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;AAAJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,6BAAA;AACJ","sourcesContent":[".about-wrapper{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    // align-items: center;\n    margin: 10rem;\n    padding: 3rem;\n    border-radius: 0.25rem;\n    background: var(--4, #EBEDFC);\n}\n.about-pay{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 5rem;\n    height: 3rem;\n    width: 10rem;\n    color: white;\n    border-radius: 0.25rem;\n    background: var(--2, #536DF6);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
