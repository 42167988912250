// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-link {
  text-decoration-color: rgb(1, 48, 48);
  color: var(--2, #536DF6);
}

.header-bold-text {
  font-size: 1.5rem;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/data-management/data-management.scss"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,wBAAA;AACJ;;AACA;EACI,iBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".image-link {\n    text-decoration-color: rgb(1, 48, 48);\n    color: var(--2, #536DF6);\n}\n.header-bold-text{\n    font-size: 1.5rem;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
