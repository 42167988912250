// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  position: relative;
  display: inline-block;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0;
  margin: 0;
}

.image-container::-webkit-scrollbar {
  width: 0;
}`, "",{"version":3,"sources":["webpack://./src/utils/file-azure/view-image-style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,qBAAA;EACA,wBAAA;EACA,UAAA;EACA,SAAA;AACF;;AAEA;EACE,QAAA;AACF","sourcesContent":[".image-container {\n  position: relative;\n  display: inline-block;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.image-container::-webkit-scrollbar {\n  width: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
