import { useDispatch, useSelector } from "react-redux"
import { useEffect, useId, useState } from "react";
import Header from "../../header/header";
import { fetchAllReportLogs } from "../../scans/store/action";
import './data-management.scss'
import Modal from 'react-bootstrap/Modal';
import { get_current_user_by_id } from "../../user/api/user-api-client";

export default function DataManagement() {
    const dispatch = useDispatch();
    const [isShosModal, setIsShowModal] = useState('')
    const [user, setUser] = useState({})
    const [scansList, setScansList] = useState([])
    const [allScansNumber, setAllScansNumber] = useState(0)
    const scansListStore = useSelector((store) => store.scanReducer.allScans)

    const getScansList = async () => {
        const scansList = await dispatch(fetchAllReportLogs())
        const sortedScans = [...scansList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setScansList(sortedScans)
    }

    useEffect(() => {
        getScansList()
    }, [])

    useEffect(() => {
        if (scansListStore.length > 0) {
            const sortedScans = [...scansListStore].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setScansList(sortedScans)
        }
    }, [scansListStore])

    const getUser = async (userId) => {
        setIsShowModal(userId)
        const userData = await get_current_user_by_id({ 'userId': userId });
        setUser(userData.data)
        console.log('userData', userData);
    }

    useEffect(() => {
        const totalScans = scansList.reduce((acc, scan) => acc + (scan.scansList?.length || 0), 0);
        setAllScansNumber(totalScans);
    }, [scansList]); // Runs only when scansList changes
    console.log('allScansNumber', allScansNumber);

    return (
        <>
            <div>

                <Header page={'data-management'} context={['כל הסריקות']}></Header>
                <div> {scansList.length} העלאות ( {allScansNumber} סריקות)</div>
                <div></div>

                <div className={`table-row`}>
                    <div className="table-col header-bold-text  col">שם המשתמש</div>
                    <div className="table-col header-bold-text  col">פרטי הסריקה</div>
                    <div className="table-col header-bold-text  col">לינק לדוח</div>
                    <div className="table-col header-bold-text  col">תאריך הסריקה</div>
                </div>

                <div className="scrollable-div users">
                    {scansList.map((scan, index) => {
                        const dateOnly = scan.createdAt.split('T')[0];
                        return (
                            <div key={index} className={`table-row`}>
                                <div className="table-col col image-link" type='button' onClick={() => getUser(scan.userId)}>{scan.userName} (לינק)</div>
                                <div className="table-col col">
                                    {scan.scansList.map((s, index) => {
                                        return (
                                            <div>
                                                <div type='button' className='image-link' onClick={() => window.open(s.scanLink, '_blank')}>לינק לתמונה {s.scanName}</div>
                                                <div>מס' שגיאות :{s.numOfErrors}</div>
                                                <div>סטטוס: {s.statusCode}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="table-col col image-link" type='button' onClick={() => window.open(`${scan.pdfLink}`, '_blank')}>לינק לדוח</div>
                                <div className="table-col col">{dateOnly}</div>
                            </div>
                        )
                    }
                    )}
                </div>
            </div>

            <Modal
                show={isShosModal}
                onHide={() => setIsShowModal('')}
                centered
                className={`modal right-margin`}
            // className={`modal ${isSm allScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body className='pdf-modal' >
                    <Modal.Title>{user.customerName}</Modal.Title>
                    <div>טלפון: {user.phone}</div>
                    <div>אימייל: {user.email}</div>
                    <div>יתרה: {user.balance}</div>
                    <div>שימוש אחרון: {user.lastPayment}</div>
                </Modal.Body>
            </Modal>

        </>
    )
}