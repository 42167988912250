import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './crop-image-style.scss'
import { smallScreen } from '../constants';
import nullImage from './../assets/null-image.svg'

const ImageCropper = ({ coordinates, originalImageWidth, originalImageHeight, showOriginalImage }) => {
    const canvasRef = useRef(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState('');
    const imageUrl = useSelector((store) => store.reportReducer.imageUrl);
    const [widthThreshold, setWidthThreshold] = useState(1);
    const [heightThreshold, setHeightThreshold] = useState(1);
    const [error, setError] = useState(false);

    const [enlargCoordinates, setEnlargeCordinates] = useState({
        x: (coordinates.x - 50) > 0 ? (coordinates.x - 50) : 1,
        y: (coordinates.y - 30) > 0 ? (coordinates.y - 30) : 1,
        width: (coordinates.x + coordinates.width + 200) > originalImageWidth ? (originalImageWidth - coordinates.x) : (coordinates.width + 200),
        height: coordinates.height + 60
    })


    useEffect(() => {
        setEnlargeCordinates({
            x: (coordinates.x - 50) > 0 ? (coordinates.x - 50) : 1,
            y: (coordinates.y - 30) > 0 ? (coordinates.y - 30) : 1,
            width: (coordinates.x + coordinates.width + 200) > originalImageWidth ? (originalImageWidth - coordinates.x) : (coordinates.width + 200),
            height: coordinates.height + 60
        })
    }, [coordinates])

    const image = new Image();
    image.crossOrigin = 'Anonymous';

    image.onload = function () {
        const width = this.width;
        const height = this.height;
        const widthThreshold = width / originalImageWidth;
        const heightThreshold = height / originalImageHeight;

        const canvas = canvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');

            // Set the canvas size to match the cropped area
            canvas.width = enlargCoordinates.width * widthThreshold;
            canvas.height = enlargCoordinates.height * heightThreshold;

            // Draw the cropped portion of the image onto the canvas
            context.drawImage(
                image,
                enlargCoordinates.x * widthThreshold,
                enlargCoordinates.y * heightThreshold,
                enlargCoordinates.width * widthThreshold,
                enlargCoordinates.height * heightThreshold,
                0,
                0,
                enlargCoordinates.width * widthThreshold,
                enlargCoordinates.height * heightThreshold
            );

            context.strokeStyle = 'red';
            context.lineWidth = 2;
            const redSquareX = (coordinates.x - enlargCoordinates.x) * widthThreshold - 5;
            const redSquareY = (coordinates.y - enlargCoordinates.y) * heightThreshold - 5;
            const redSquareWidth = coordinates.width * widthThreshold + 10;
            const redSquareHeight = coordinates.height * heightThreshold + 10;

            // Draw the red square
            context.strokeRect(redSquareX, redSquareY, redSquareWidth, redSquareHeight);

            const croppedUrl = canvas.toDataURL('image/jpeg');

            setCroppedImageUrl(croppedUrl);
        }
        else {
            console.error("Canvas element not found");
        }
    };
    image.src = imageUrl;

    return (
        <div>
            <img
                src={showOriginalImage ? croppedImageUrl : nullImage}
                style={{ width: 'auto', height: '4rem' }}
            />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>



    );
};

export default ImageCropper;
