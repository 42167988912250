import he from '../locale/he'
import en from '../locale/en'
import { useSelector } from 'react-redux';

export const BASE_URL = process.env.REACT_APP_ENVIRONMENT == 'prod' ? "https://stamscanner.co.il:3001" :
  process.env.REACT_APP_ENVIRONMENT == 'dev' ? "https://stamscannerdev.co.il:3001" :
    "https://localhost:3001"
console.log('BASE_URL', BASE_URL);

export const useLanguage = () => {
  const language = useSelector((store) => store.userReducer.language);
  return language == 'en-US' ? en : he;
};

export const smallScreen = () => {
  return window.matchMedia('(max-width: 768px)').matches;
};


export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  if (!email) return false;
  const trimmedEmail = email.trim();
  return emailRegex.test(trimmedEmail) || 'אימייל שגוי';
};

export const emailPatternConst = () => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
}

export const passwordPatternConst = () => {
  // return /^\s*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}\s*$/
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
}

export const dataURLtoBlob = (dataURL) => {
  const parts = dataURL.split(";base64,");
  const contentType = parts[0].split(":")[1];
  const byteCharacters = atob(parts[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const errorTypesArray = {
  TOUCHING_LETTER_SELF: [],
  TOUCHING_LETTER_H: [],
  TOUCHING_LETTER_V: [],
  MISSING_LETTER_OR_TOUCHING_LETTER_H: [],
  MISSING_SPACE_BETWEEN_WORDS: [],
  EXTRA_SPACE_BETWEEN_WORDS: [],
  LETTER_SHAPE_CHANGED: [],
  BROKEN_LETTER: [],
  MISSING_WORD: [],
  ADDED_WORD: [],
  ADDED_LETTER: [],
  ALTERED_WORD: [],
  TOUCHING_LETTERS_READ_AS_NEW: [],
  TOUCHING_LETTERS_READ_AS_ERROR: [],
  STAIN: [],
  EXTRA_SPACE_BETWEEN_LETTERS: [],
}


export const nisPerMezuza = () => {
  return 10
}

export const nisPerAffairInTeffilin = () => {
  return 10
}

export const nisPerSheetOfSefetTora = () => {
  return 4
}

export const getVideoLink = () => {
  return 'https://youtu.be/dIMt1uXHrjw'
}
export const getEmail = () => {
  return 'stamscanner.co.il@gmail.com'
}

export const getPhone = () => {
  return '058-4239503'
}
export const getPhoneNumber = () => {
  return '0584239503'
}
export const getMezuzaText = () => {
  return 'שמע ישראל יהוה אלהינו יהוה אחד ואהבת את יהוה אלהיך בכל לבבך ובכל נפשך ובכל מאדך והיו הדברים האלה אשר אנכי מצוך היום על לבבך ושננתם לבניך ודברת בם בשבתך בביתך ובלכתך בדרך ובשכבך ובקומך וקשרתם לאות על ידך והיו לטטפת בין עיניך וכתבתם על מזזות ביתך ובשעריך והיה אם שמע תשמעו אל מצותי אשר אנכי מצוה אתכם היום לאהבה את יהוה אלהיכם ולעבדו בכל לבבכם ובכל נפשכם ונתתי מטר ארצכם בעתו יורה ומלקוש ואספת דגנך ותירשך ויצהרך ונתתי עשב בשדך לבהמתך ואכלת ושבעת השמרו לכם פן יפתה לבבכם וסרתם ועבדתם אלהים אחרים והשתחויתם להם וחרה אף יהוה בכם ועצר את השמים ולא יהיה מטר והאדמה לא תתן את יבולה ואבדתם מהרה מעל הארץ הטבה אשר יהוה נתן לכם ושמתם את דברי אלה על לבבכם ועל נפשכם וקשרתם אתם לאות על ידכם והיו לטוטפת בין עיניכם ולמדתם אתם את בניכם לדבר בם בשבתך בביתך ובלכתך בדרך ובשכבך ובקומך וכתבתם על מזוזות ביתך ובשעריך למען ירבו ימיכם וימי בניכם על האדמה אשר נשבע יהוה לאבתיכם לתת להם כימי השמים על הארץ'
}
export const getMezuzaTextByLines = () => {
  return [
    'שמע ישראל יהוה אלהינו יהוה אחד ואהבת את',
    'יהוה אלהיך בכל לבבך ובכל נפשך ובכל מאדך והיו',
    'הדברים האלה אשר אנכי מצוך היום על לבבך ושננתם',
    'לבניך ודברת בם בשבתך בביתך ובלכתך בדרך',
    'ובשכבך ובקומך וקשרתם לאות על ידך והיו לטטפת',
    'בין עיניך וכתבתם על מזזות ביתך ובשעריך',
    'והיה אם שמע תשמעו אל מצותי אשר אנכי',
    'מצוה אתכם היום לאהבה את יהוה אלהיכם ולעבדו',
    'בכל לבבכם ובכל נפשכם ונתתי מטר ארצכם בעתו',
    'יורה ומלקוש ואספת דגנך ותירשך ויצהרך ונתתי',
    'עשב בשדך לבהמתך ואכלת ושבעת השמרו לכם',
    'פן יפתה לבבכם וסרתם ועבדתם אלהים אחרים',
    'והשתחויתם להם וחרה אף יהוה בכם ועצר את',
    'השמים ולא יהיה מטר והאדמה לא תתן את יבולה',
    'ואבדתם מהרה מעל הארץ הטבה אשר יהוה נתן לכם',
    'ושמתם את דברי אלה על לבבכם ועל נפשכם וקשרתם',
    'אתם לאות על ידכם והיו לטוטפת בין עיניכם ולמדתם',
    'אתם את בניכם לדבר בם בשבתך בביתך ובלכתך',
    'בדרך ובשכבך ובקומך וכתבתם על מזוזות ביתך',
    'ובשעריך למען ירבו ימיכם וימי בניכם על האדמה',
    'אשר נשבע יהוה לאבתיכם לתת להם כימי השמים',
    'על הארץ'
  ]
}
export const getMezuzaTextByLinesWithThreshold = () => {
  const threshold = 100;
  return [
    { 'שמע ישראל יהוה אלהינו יהוה אחד ואהבת את': threshold },
    { 'יהוה אלהיך בכל לבבך ובכל נפשך ובכל מאדך והיו': threshold },
    { 'הדברים האלה אשר אנכי מצוך היום על לבבך ושננתם': threshold },
    { 'לבניך ודברת בם בשבתך בביתך ובלכתך בדרך': threshold },
    { 'ובשכבך ובקומך וקשרתם לאות על ידך והיו לטטפת': threshold },
    { 'בין עיניך וכתבתם על מזזות ביתך ובשעריך': threshold },
    { 'והיה אם שמע תשמעו אל מצותי אשר אנכי': threshold },
    { 'מצוה אתכם היום לאהבה את יהוה אלהיכם ולעבדו': threshold },
    { 'בכל לבבכם ובכל נפשכם ונתתי מטר ארצכם בעתו': threshold },
    { 'יורה ומלקוש ואספת דגנך ותירשך ויצהרך ונתתי': threshold },
    { 'עשב בשדך לבהמתך ואכלת ושבעת השמרו לכם': threshold },
    { 'פן יפתה לבבכם וסרתם ועבדתם אלהים אחרים': threshold },
    { 'והשתחויתם להם וחרה אף יהוה בכם ועצר את': threshold },
    { 'השמים ולא יהיה מטר והאדמה לא תתן את יבולה': threshold },
    { 'ואבדתם מהרה מעל הארץ הטבה אשר יהוה נתן לכם': threshold },
    { 'ושמתם את דברי אלה על לבבכם ועל נפשכם וקשרתם': threshold },
    { 'אתם לאות על ידכם והיו לטוטפת בין עיניכם ולמדתם': threshold },
    { 'אתם את בניכם לדבר בם בשבתך בביתך ובלכתך': threshold },
    { 'בדרך ובשכבך ובקומך וכתבתם על מזוזות ביתך': threshold },
    { 'ובשעריך למען ירבו ימיכם וימי בניכם על האדמה': threshold },
    { 'אשר נשבע יהוה לאבתיכם לתת להם כימי השמים': threshold },
    { 'על הארץ': threshold },
  ]
}