import * as ProjectsApiClient from '../api/projects-api-client'
import { ADD_PROJECT, FETCH_PROJECTS, SET_LANGUAGE, UPDATE_PROJECT, SET_PROJECT_ID, DELETE_PROJECT, SET_PROJECTS_OF_TYPE } from "./types";



export const fetchProjects = (userId) => async dispatch => {
    try {
        const res = await ProjectsApiClient.projectsFetch(userId)
        dispatch({
            type: FETCH_PROJECTS,
            payload: res
        })
        return res;
    }
    catch (error) {
        console.log(error);
    }
}

export const setProjectsOfType = (projects) => async dispatch => {
    try {
        dispatch({
            type: SET_PROJECTS_OF_TYPE,
            payload: projects
        })
    }
    catch (error) {
        console.log(error);
    }
}

export const addProject = (project) => async dispatch => {
    try {
        const res = await ProjectsApiClient.addProject(project);
        dispatch({
            type: ADD_PROJECT,
            payload: res
        })
        return res.id;
    }
    catch (error) {
        console.log('error in addProject', error);
    }
}

export const setLanguage = (language) => (dispatch) => {
    dispatch({
        type: SET_LANGUAGE,
        payload: language
    })
}

export const updateProject = (project) => async dispatch => {
    try {
        const res = await ProjectsApiClient.updateProject(project);
        dispatch({
            type: UPDATE_PROJECT,
            payload: res.data
        })
    }
    catch (error) {
        console.log('error in updateProject', error);
    }

}

export const deleteProject = (projectId, userId) => async dispatch => {
    console.log('deleteProject', projectId, userId);
    try {
        const res = await ProjectsApiClient.deleteProject(projectId, userId);
        console.log('res in delete project', res);
        dispatch({
            type: DELETE_PROJECT,
            payload: res.data
        })
        return res.data;
    }
    catch (error) {
        console.log('error in deleteProject', error);
    }

}


export const setProjectId = (projectId) => (dispatch) => {
    dispatch({
        type: SET_PROJECT_ID,
        payload: projectId
    })
}