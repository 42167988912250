import axios from 'axios'
import { BASE_URL } from '../../../utils/constants'

const URL = `${BASE_URL}/api/scan/`

export const fetchScans = (userId) => {
    return axios.get(`${URL}fetch_all/${userId}`).then((response) => response.data)
}

export const fetchScansByProjectId = (projectId) => {
    return axios.get(`${URL}fetch_scans_by_projectId/${projectId}`,).then((response) => response.data)
}

export const createScan = (scan) => {
    console.log('createScan');
    return axios.post(`${URL}create_scan`, scan, {
        headers: {
            'Content-Type': "multipart/form-data"
        },
    }).then((response) => response.data)
}

export const updateScan = (scan) => {
    return axios.put(`${URL}update_scan`, scan).then((response) => response.data)
}

export const deleteScan = (scanId, userId) => {
    return axios.delete(`${URL}delete_scan/${scanId}/${userId}`).then((response) => response.data)
}

export const deleteScans = (obj) => {
    console.log('deleteScans', obj);
    return axios.post(`${URL}delete_scans_by_id`, obj).then((response) => response.data)
}



