// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  align-items: center;
  border: 1px black solid;
  gap: 1rem;
}

.table-row.no-border {
  border: unset;
}

.table-col {
  flex-grow: 1;
  text-align: center;
  font-size: 0.95rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/user-management/user-management.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACJ;;AACA;EACI,aAAA;AAEJ;;AACA;EAEI,YAAA;EACA,kBAAA;EACA,kBAAA;AACJ","sourcesContent":[".table-row {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n    height: auto;\n    align-items: center;\n    border: 1px black solid;\n    gap: 1rem;\n}\n.table-row.no-border{\n    border:unset;\n    \n}\n.table-col {\n    // padding-right: 0.5rem;\n    flex-grow: 1;\n    text-align: center;\n    font-size: 0.95rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
