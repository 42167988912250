
import { Route, Routes, useNavigate } from 'react-router-dom';
import { NavBar } from '../nav-bar/nav-bar.js';
import ProjectPage from '../projects/projec-page/project-page';
import menuMobile from '../../utils/assets/menu-mobile.svg';
import './layout-style.scss'
import { smallScreen } from '../../../src/utils/constants';
import NewScan from '../scans/new-scan/new-scan';
import ScansPage from '../scans/scans-page/scans-page.js';
import UploadScan from '../scans/upload-scan/upload-scan.js';//tochange
import LoginForm from '../user/login/login.js';
import Register from '../user/register/register.js';
import Contact from '../contact/contact.js';
import SendEmailResetPassword from '../user/forgot-password/forgot-password.js';
import ReportPage from '../report/report-page/report-page.js';
import EditProfile from '../user/edit-profile/edit-profile.js';
import PaymentPage from '../payments/payment-page/payment-page.js';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, getCurrentUserById, logoutUser } from '../user/store/action.js';
import Payment from '../payments/tranzila/Payment.js';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Dashboard from '../dashboard/dashboard.js';
import DataDeletion from '../DataDeletion/DataDeletion.js';
import LogoutInactiveUser from '../user/inactive-user.js';
import About from '../about/about.js';
import Modal from 'react-bootstrap/Modal';
import TestCreateReport from '../scans/test/test-create-report.js';
import ReactGA from 'react-ga4';
import ImageCropper from '../scans/upload-scan/cropper.js';
ReactGA.initialize('G-VLLNDJJMGD');

export default function Layout() {
    const isSmallScreen = smallScreen();
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [showRenderPage, setShowRenderPage] = useState(false);
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isCurrentUserEffect = useRef(true)
    const isReactGaEffect = useRef(true)

    useEffect(() => {
        if (isReactGaEffect.current == true && currentUser?.Role == 'user') {
            console.log('pageview in layout', window.location.pathname);
            ReactGA.send({ hitType: "pageview", page: '/dashboard', title: 'dashboard' });
            ReactGA.send({ hitType: "pageview", page: '/contact', title: 'contact' });
            ReactGA.send({ hitType: "pageview", page: '/upload-scan', title: 'upload-scan' });
            ReactGA.send({ hitType: "pageview", page: '/scans', title: 'scans' });
            ReactGA.send({ hitType: "pageview", page: '/payment-page', title: 'payment-page' });
            ReactGA.send({ hitType: "pageview", page: '/payment', title: 'payment' });
            isReactGaEffect.current = false;
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    const changeLanguageFunc = async (newLanguage) => {
        try {
            setCurrentLanguage(newLanguage);
            await dispatch(changeLanguage(newLanguage));
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // console.log('currentUser',currentUser);
        // console.log('sssssssss',currentUserId && currentUser=={ });
        //if no user- navigate to login page
        if ((!currentUserId
            // || (currentUserId && currentUser && !Object.keys(currentUser).length)
        )
            && !['/login', '/register'].includes(window.location.pathname)) {
            console.log('❌❌❌');
            navigate('/login');
        } else if (currentUserId && !currentUser) {
            setShowRenderPage(true);
        }

    }, [currentUser, currentUserId])

    useEffect(() => {
        if ((currentUserId) && window.location.pathname === '/') {
            navigate('/login');
        }
    }, [])


    useEffect(() => {
        const handleVisibilityChange = () => {
            try {
                if (document.visibilityState === 'hidden') {
                    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
                    localStorage.setItem('currentUser', JSON.stringify({ id: currentUser?.id, time: new Date(), language: currentUser?.language || 'he' }));
                }
            }
            catch (error) {
                console.log(error);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    });

    useEffect(() => {
        try {
            const currentUserString = localStorage.getItem('currentUser');
            if (currentUserString) {
                const currentUser = JSON.parse(currentUserString);
                const userLastLogoutTimeString = currentUser?.time;
                if (userLastLogoutTimeString) {
                    func(userLastLogoutTimeString)
                }
            }
        } catch (error) {
            console.log('Error retrieving or parsing currentUser:', error);
        }
    }, [])

    const func = async (userLastLogoutTimeString) => {
        try {
            const userLastLogoutTime = new Date(userLastLogoutTimeString);
            const oneHourLater = new Date(userLastLogoutTime.getTime() + 3600000);
            const newTime = new Date();

            if (newTime >= oneHourLater) {
                await dispatch(logoutUser(currentUserId));
            }
        } catch (error) {
            console.log('Error processing userLastLogoutTimeString:', error);
        }
    }

    const onLoaded = (cv) => {
        console.log('opencv loaded', cv)
    }


    return (
        <div className="container-fluid" dir='rtl'>
            <div className={`row ${isSmallScreen ? 'navbar-row-mobile' : 'navbar-row'}`}>

                {/* <LogoutInactiveUser></LogoutInactiveUser> */}

                <div className={!isSmallScreen ? ` ${currentLanguage == 'en-US' ? 'navbar-col-ltr' : 'navbar-col'}` : ''} >
                    {(isSmallScreen) ?
                        <>
                            <div
                                type="button"
                                className={`container menu ${currentLanguage == 'en-US' ? 'ltr' : ''}  pt-2`}
                                onClick={() => setShowMenu(true)}>
                                <img src={menuMobile}></img>
                                {<div className='lenguage-wrapper mobile'
                                    onClick={(e) => { e.stopPropagation(); setShowMenu(false) }}>
                                    <div type={language == 'en-US' ? '' : 'button'} className={`col-5 lenguage ${language == 'en-US' ? 'lenguage-active mobile' : ''}`} onClick={() => changeLanguageFunc('en-US')}>EN</div>
                                    <div type={language == 'he' ? '' : 'button'} className={`col-7 lenguage ${language == 'he' ? 'lenguage-active mobile' : ''}`} onClick={() => changeLanguageFunc('he')}>עברית</div>
                                </div>}
                            </div>

                            <Offcanvas
                                placement={currentLanguage == 'en-US' ? 'start' : 'end'}
                                show={showMenu}
                                onHide={() => setShowMenu(false)}
                                id="OffcanvasMenu">
                                <NavBar setShowMenu={setShowMenu}></NavBar>
                            </Offcanvas>

                        </>
                        :
                        <div className={`${currentLanguage == 'en-US' ? '' : 'navbar-data'}`} >
                            <NavBar></NavBar>
                        </div>
                    }
                </div>

                <div className={`col ${isSmallScreen ? '' : (currentLanguage == 'en-US' ? 'body-col-ltr' : 'body-col')} `}>
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard />}></Route>
                        <Route path="/folders" element={<ProjectPage />}></Route>
                        <Route path="/newScan" element={<NewScan />}></Route>
                        <Route path="/scans" element={<ScansPage />}></Route>
                        <Route path="/scans-page" element={<ScansPage />}></Route>
                        <Route path="/upload-scan" element={<UploadScan />}></Route>
                        <Route path="/login" element={<LoginForm />}></Route>
                        <Route path="/register" element={<Register />}></Route>
                        <Route path="/contact" element={<Contact />}></Route>
                        <Route path="/forgot-password" element={<SendEmailResetPassword />}></Route>
                        <Route path="/report-page" element={<ReportPage />}></Route>
                        <Route path="/edit-profile" element={<EditProfile />}></Route>
                        <Route path="/payment-page" element={<PaymentPage />}></Route>
                        <Route path="/payment" element={<Payment />}></Route>
                        <Route path="/about" element={<About />}></Route>
                        <Route path="/test" element={<TestCreateReport />}></Route>
                        <Route path="/data-deletion" element={<DataDeletion />}></Route>
                        <Route path="/cropper" element={<ImageCropper />}></Route>
                    </Routes>
                </div>

                <Modal
                    show={showRenderPage}
                    onHide={() => setShowRenderPage(false)}
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>

                    <Modal.Body className='pdf-modal' >
                        <Modal.Title>{'ארעה שגיאה בטעינת הדף'}</Modal.Title>
                        <button className='btn btn-primary' onClick={() => window.location.reload()}>נסה שוב</button>
                    </Modal.Body>
                </Modal >

            </div>
        </div>
    )
}

