import React, { useEffect, useReducer, useRef, useState } from 'react';
import { BASE_URL, smallScreen, useLanguage } from '../../../utils/constants';
import Header from '../../header/header';
import './report-page-style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { createReportSortByType, fetchReportByProjectId, setConfirmedScansIds, setCoords, setImageUrl, setSortedReport, setSortReportOcroutput, updateReport } from '../store/action';
import ViewImage from '../../../utils/file-azure/view-image';
import ImageCropper from '../../../utils/crop-image/crop-image';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ShowImage from '../../../utils/show-image/show-image';
import ImageCanvas from '../../../utils/show-image/show-image';
// import ShowImage from '../../../utils/show-image/show-image';
import ViewPdf from '../../../utils/pdf/pdf-modal';
import ReactGA from 'react-ga4';
import Spinner from '../../../utils/spinner/spinner';
import { getCurrentUserById } from '../../user/store/action';

const ReportPage = () => {
    const isSmallScreen = smallScreen();
    const dispatch = useDispatch();
    const t = useLanguage();
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const currentProjectId = JSON.parse(sessionStorage.getItem('currentProjectId'));
    const reportStore = useSelector((store) => store.reportReducer);
    const reportFullObject = reportStore.report || {};
    const sortedReport = reportStore.reportOcroutputSort || {};
    const [report, setReport] = useState(sortedReport)
    const [reportId, setReportId] = useState('')
    let keyValueArray = JSON.parse(sessionStorage.getItem('keyValueReportArray')) || [];
    const language = useSelector((store) => store.userReducer.language);
    const currentProjectName = JSON.parse(sessionStorage.getItem('currentProjectName'));
    const typeScan = JSON.parse(sessionStorage.getItem('typeScan'));
    const isEffect = useRef(true);
    const index = useRef(-1);
    const [disabledCreateReport, setDisabledCreateReport] = useState(false);
    const scanId = useRef('');
    const imageId = useRef('');
    const coordinates = useRef({});
    const indexesToUpdate = useRef({});
    const originalImageWidth = useRef(0);
    const originalImageHeight = useRef(0);
    let confirmedScansIds = reportStore.confirmedScansIds;
    const [sortedReportByTypeValues, setSortedReportByTypeValues] = useState({});
    const [sortedReportByTypeKeys, setSortedReportByTypeKeys] = useState([]);
    const [lineErrorClasses, setLineErrorClasses] = useState({});
    const defaultCoords = { x: 0, y: 0, width: 0, height: 0 };
    const errorTypesTranslate = t.errorsType;
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [chekcboxPress, setChekcboxPress] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const [isShowPdf, setIsShowPdf] = useState('');
    const [spinner, setSpinner] = useState(0)
    const [showOriginalImage, setShowOriginalImage] = useState(false)
    const imageUrl = useRef('');
    const URL = BASE_URL;
    const [checkboxStates, setCheckboxStates] = useState({});
    const [checkAllCheckboxStates, setCheckAllCheckboxStates] = useState(false);
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const buttonRef = useRef(null);
    const buttonRef2 = useRef(null);
    const isCurrentUserEffect = useRef(true);
    const isReportEffect = useRef(true)
    const isLoadPageEffect = useRef(true)
    const [isShowList, setIsShowList] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])

    useEffect(() => {
        async function fetchReportsFunc() {
            isLoadPageEffect.current = false;
            const resReport = await dispatch(fetchReportByProjectId({ userId: currentUserId, projectId: currentProjectId }))
            const scansIdsToReport = JSON.parse(sessionStorage.getItem('scansIdsToReport'));
            const updatedReport = {
                ...resReport,
                ocrOutput: resReport.ocrOutput?.filter((scan) => scansIdsToReport.includes(scan.scanId))
            };
            setReportId(resReport._id)
            await dispatch(createReportSortByType(updatedReport));
            await dispatch(setSortReportOcroutput(updatedReport))
            changeIndex();
            setReport(updatedReport)
        }

        if (isLoadPageEffect.current == true) {
            fetchReportsFunc();
        }

    }, [report]);


    useEffect(() => {
        const fetchData = async () => {
            if (currentProjectId && currentUserId && isReportEffect.current) {
                await dispatch(fetchReportByProjectId({ userId: currentUserId, projectId: currentProjectId }))
                isReportEffect.current = false;
            }
        }
        fetchData();
    }, [currentProjectId])

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    const changeIndex = (ind) => {
        console.log('😉😉😉😉😉😉😉');

        const indexToChange = ind || 1;
        keyValueArray = JSON.parse(sessionStorage.getItem('keyValueReportArray')) || [];

        if (keyValueArray.length > 0 && index.current + indexToChange < keyValueArray.length && index.current + indexToChange >= 0) {
            index.current = index.current + indexToChange;
            const currentKeyValueArray = keyValueArray[index.current];
            setSortedReportByTypeValues(currentKeyValueArray[1]);
            if (currentKeyValueArray[1]) {
                setSortedReportByTypeKeys(Object.keys(currentKeyValueArray[1]));
            }
            else {
                console.log("null value")
            }
            scanId.current = currentKeyValueArray[0];

            sortedReportByTypeKeys.map(key =>
                // areAllIndexesUpdated(key)
                setCheckboxStates((prevStates) => ({
                    ...prevStates,
                    [key]: false,
                }))
            )
            setCheckAllCheckboxStates(false)
        }
    }

    const areAllIndexesUpdated = (errorKey) => {
        const expectedIndexes = sortedReportByTypeValues[errorKey]?.map((item, ind) =>
            item.indexesInOriginalArray
        );
        const areAllInIndexesToUpdate = expectedIndexes.every((expectedIndex) => {
            return indexesToUpdate?.current[scanId.current] != undefined && indexesToUpdate?.current[scanId.current]?.indexOf(expectedIndex) != -1
        }
        );
        setCheckboxStates((prevStates) => ({
            ...prevStates,
            [errorKey]: areAllInIndexesToUpdate,
        }))
    };

    const handleErrorCheckboxChange = async (event, errorKey) => {
        const checkbox = event.target;

        const updatedClasses = { ...lineErrorClasses };

        if (checkbox.checked) {
            if (!indexesToUpdate.current[scanId.current])
                indexesToUpdate.current[scanId.current] = [];

            sortedReportByTypeValues[errorKey].map((item, ind) => {
                const errorIndexesToCalss = `${index.current}-${errorKey}-${ind}`;
                updatedClasses[errorIndexesToCalss] = 'line-error-checked';
                delete updatedClasses[errorIndexesToCalss + '-unchecked'];

                const errorIndexes = item.indexesInOriginalArray;
                indexesToUpdate.current[scanId.current] = [
                    ...indexesToUpdate.current[scanId.current],
                    errorIndexes,
                ];
            });

            setCheckboxStates((prevStates) => ({
                ...prevStates,
                [errorKey]: true,
            }))
        }
        else {
            sortedReportByTypeValues[errorKey].map((item, ind) => {
                const errorIndexesToCalss = `${index.current}-${errorKey}-${ind}`;
                updatedClasses[errorIndexesToCalss] = 'line-error';
                delete updatedClasses[errorIndexesToCalss + '-checked'];

                const errorIndexes = item.indexesInOriginalArray;
                const arr = indexesToUpdate.current[scanId.current]?.filter(
                    (subArray) => !arraysAreEqual(subArray, errorIndexes)
                );
                indexesToUpdate.current[scanId.current] = arr;
            })

            setCheckboxStates((prevStates) => ({
                ...prevStates,
                [errorKey]: false,
            }))

        }
        setLineErrorClasses(updatedClasses);
    }

    const handleCheckboxChange = (event, errorIndexes, key, errorKey) => {
        const checkbox = event.target;
        const updatedClasses = { ...lineErrorClasses };

        if (checkbox.checked) {
            updatedClasses[key] = 'line-error-checked';
            delete updatedClasses[key + '-unchecked'];
            if (!indexesToUpdate.current[scanId.current])
                indexesToUpdate.current[scanId.current] = [];
            indexesToUpdate.current[scanId.current] = [
                ...indexesToUpdate.current[scanId.current],
                errorIndexes,
            ];

            areAllIndexesUpdated(errorKey);
        }
        else {
            updatedClasses[key] = 'line-error';
            delete updatedClasses[key + '-checked'];
            const arr = indexesToUpdate.current[scanId.current]?.filter(
                (subArray) => !arraysAreEqual(subArray, errorIndexes)
            );
            indexesToUpdate.current[scanId.current] = arr;

            setCheckboxStates((prevStates) => ({
                ...prevStates,
                [errorKey]: false,
            }))
        }
        setLineErrorClasses(updatedClasses);
    };

    const arraysAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    }

    const confirmScan = (e) => {
        const checkbox = e.target;
        if (checkbox.checked) {
            dispatch(setConfirmedScansIds([...confirmedScansIds, scanId.current]))
        }
        else {
            const filteredArray = confirmedScansIds?.filter(id => id !== scanId.current);
            dispatch(setConfirmedScansIds(filteredArray));
        }
    }

    const confirmScanMobile = () => {
        const isScanId = confirmedScansIds.find(id => id === scanId.current)
        if (isScanId) {
            const filteredArray = confirmedScansIds?.filter(id => id !== scanId.current);
            dispatch(setConfirmedScansIds(filteredArray));
        }
        else {
            dispatch(setConfirmedScansIds([...confirmedScansIds, scanId.current]))
        }
    }

    useEffect(() => {
        confirmedScansIds = reportStore.confirmedScansIds;
    }, [reportStore.confirmedScansIds])


    const showErrorOnImage = async (indexesInOriginalArray) => {
        const line = indexesInOriginalArray[0];
        const word = indexesInOriginalArray[1];
        const letter = indexesInOriginalArray[2] >= 0 ? indexesInOriginalArray[2] : null;
        let error;

        if (letter != null) {
            report.ocrOutput.map((item) => {
                if (item.scanId == scanId.current) {
                    error = item.lines[line]?.words[word]?.letters[letter];
                    originalImageWidth.current = item.width;
                    originalImageHeight.current = item.height;
                }
            })
        }
        else {
            report.ocrOutput.map((item) => {
                if (item.scanId == scanId.current) {
                    error = item.lines[line]?.words[word];
                    originalImageWidth.current = item.width;
                    originalImageHeight.current = item.height;
                }
            })
        }

        const coords = error.coords || defaultCoords;

        coordinates.current = {
            x: (coords.x),
            y: (coords.y),
            width: (coords.width),
            height: (coords.height)
        }

        await dispatch(setCoords(coordinates.current))
        if (imageId.current && coordinates.current) {
            if (!chekcboxPress)
                setIsImageModalVisible(true);
        }

    }

    const chooseAll = async (event) => {
        const checkbox = event.target;

        const updatedClasses = { ...lineErrorClasses };

        if (checkbox.checked) {
            setCheckAllCheckboxStates(true)
            if (!indexesToUpdate.current[scanId.current])
                indexesToUpdate.current[scanId.current] = [];

            sortedReportByTypeKeys.map((errorKey) => {
                sortedReportByTypeValues[errorKey].map((item, ind) => {
                    const errorIndexesToCalss = `${index.current}-${errorKey}-${ind}`;
                    updatedClasses[errorIndexesToCalss] = 'line-error-checked';
                    delete updatedClasses[errorIndexesToCalss + '-unchecked'];

                    const errorIndexes = item.indexesInOriginalArray;
                    indexesToUpdate.current[scanId.current] = [
                        ...indexesToUpdate.current[scanId.current],
                        errorIndexes,
                    ];
                });

                setCheckboxStates((prevStates) => ({
                    ...prevStates,
                    [errorKey]: true,
                }))

            })

        }
        else {
            setCheckAllCheckboxStates(false)
            sortedReportByTypeKeys.map((errorKey) => {
                sortedReportByTypeValues[errorKey].map((item, ind) => {
                    const errorIndexesToCalss = `${index.current}-${errorKey}-${ind}`;
                    updatedClasses[errorIndexesToCalss] = 'line-error';
                    delete updatedClasses[errorIndexesToCalss + '-checked'];

                    const errorIndexes = item.indexesInOriginalArray;
                    const arr = indexesToUpdate.current[scanId.current]?.filter(
                        (subArray) => !arraysAreEqual(subArray, errorIndexes)
                    );
                    indexesToUpdate.current[scanId.current] = arr;
                })
                setCheckboxStates((prevStates) => ({
                    ...prevStates,
                    [errorKey]: false,
                }))
            })



        }
        setLineErrorClasses(updatedClasses);

    }

    const createReport = async () => {
        console.log('createReport',);
        setIsShowConfirmModal(false);
        setDisabledCreateReport(true);
        setSpinner(100)
        const filteredArray = {};
        if (report.ocrOutput.length > 1) {
            console.log('report.ocrOutput.length > 1');
            confirmedScansIds.forEach(scanId => {
                filteredArray[scanId] = indexesToUpdate.current[scanId] || [];
            });
        }
        else {
            const firstScanId = report.ocrOutput[0].scanId;
            confirmedScansIds = [firstScanId]
            filteredArray[firstScanId] = indexesToUpdate.current[firstScanId] || [];
        }
        console.log('filteredArray', filteredArray);
        console.log('confirmedScansIds', confirmedScansIds);
        const pdfReport = await dispatch(updateReport({ reportId: report._id, indexesToUpdate: filteredArray, scansIdsToPdf: confirmedScansIds, locale: language, scanType: t.scanTypesSingle[typeScan] }))
        console.log('pdfReport', pdfReport);

        setDisabledCreateReport(false);
        if (pdfReport) {
            setIsShowPdf(pdfReport);
            dispatch(setConfirmedScansIds([]));
            setLineErrorClasses([]);
            setCheckboxStates({});
            setCheckAllCheckboxStates(false)
            const scansIdsToReport = JSON.parse(sessionStorage.getItem('scansIdsToReport'));
            const updatedReport = {
                ...report,
                ocrOutput: report.ocrOutput?.filter((scan) => scansIdsToReport.includes(scan.scanId))
            };
            const allIndexes = await dispatch(createReportSortByType(updatedReport));
            // indexesToUpdate.current = allIndexes;
        }
        else {
            alert('תקלה')
        }
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'אישור הפקת דוח סיכום בעמוד סינון שגיאות',
                label: 'עמוד סינון שגיאות',
                value: 1
            });

    }

    useEffect(() => {
        setShowOriginalImage(false);
        const foundImage = report?.images?.find(item => item.scanId === scanId.current);
        if (foundImage) {
            imageId.current = foundImage._id || '';
        }
        const currentImageUrl = `https://stamimages.blob.core.windows.net/scans-images/scan${scanId.current}.jpg`;
        if (imageId.current != '') {
            axios
                // .get(currentImageUrl, { responseType: 'arraybuffer' })
                .get(`${BASE_URL}/api/file/download/${imageId.current}`, { responseType: 'arraybuffer' })
                .then((response) => {
                    const url = (window.webkitURL || URL).createObjectURL(new Blob([response.data]));
                    imageUrl.current = url;
                    setShowOriginalImage(true);
                    // dispatch(setImageUrl(currentImageUrl))
                    dispatch(setImageUrl(imageUrl.current))
                })
                .catch((error) => console.log('error in view image😭😭'))
        }

    }, [scanId.current]);

    useEffect(() => {
        if (index.current === -1 && !report) {
            window.location.reload();
        }
    }, [index])

    useEffect(() => {
        buttonRef?.current?.focus();

        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                if (confirmedScansIds.length > 0) {
                    openConfirmModal()
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };

    }, [confirmedScansIds]);

    useEffect(() => {
        if (isShowConfirmModal) {

            buttonRef2.current.focus();

            const handleKeyPress = (event) => {
                if (event.key === 'Enter') {
                    createReport()
                }
            };

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }

    }, [isShowConfirmModal]);

    const openConfirmModal = () => {
        setIsShowConfirmModal(true)
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'לחיצה על הפק דוח סיכום בעמוד סינון שגיאות',
                label: 'עמוד סינון שגיאות',
                value: 1
            });

    }

    const activateCancelPress = () => {
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'ביטול הפקת דוח סיכום בעמוד סינון שגיאות',
                label: 'עמוד סינון שגיאות',
                value: 1
            });
    }

    useEffect(() => {
        setIsShowList(true);
        timerRef.current = setTimeout(() => {
            setIsShowList(false);
        }, 10000);
        return () => clearTimeout(timerRef.current);
    }, []);

    useEffect(() => {
        if (sortedReportByTypeKeys?.length >= 1) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            setIsShowList(false);
        }
    }, [sortedReportByTypeKeys]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % 3);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={isSmallScreen ? '' : 'container'}>
            <div className='col'>

                <Header page={'upload-scan'} context={[t.report.report, ">", t.scanTypes[typeScan], ">", currentProjectName, '']}></Header>

                {/* header */}
                <div className='change-index'>
                    <div type='button' className='arrow-color left' onClick={() => changeIndex(-1)}>{`<`}</div>
                    <div className='text-center'>{`${t.scanTypes[typeScan]}  ${report?.ocrOutput?.length || 0} / ${index.current + 1}`}</div>
                    <div type='button' className='arrow-color ' onClick={() => changeIndex(1)}>{`>`}</div>
                </div>
                <div>{t.report.reportMessage}</div>

                <div className={isSmallScreen ? '' : 'report-footer'}>

                    <div className={isSmallScreen ? 'footer-container' : 'footer-container'}>
                        {report?.ocrOutput?.length > 1 &&
                            (!isSmallScreen ?
                                <div className={'confirm-scan'} type='button' onClick={confirmScanMobile}>
                                    {`${t.report.confirmScanEdit} ${t.scanTypesSingle[typeScan]} ${index.current + 1}`}
                                    <input
                                        type="checkbox"
                                        className='select-scan-checkbox'
                                        onChange={(e) => confirmScan(e)}
                                        checked={confirmedScansIds.includes(scanId.current)}
                                    />
                                </div>
                                :
                                <div type='button' className={confirmedScansIds.includes(scanId.current) ? 'confirm-scan mobile checked ' : 'confirm-scan mobile '} onClick={confirmScanMobile}>
                                    {t.report.confirmScanEdit + ' ' + t.scanTypesSingle[typeScan] + ' ' + String(index.current + 1)}
                                </div>)
                        }
                        <div type='button' ref={buttonRef} className={`edit-report-btn ${report?.ocrOutput?.length > 1 && confirmedScansIds.length == 0 ? ' disabled' : ''} ${isSmallScreen ? '' : ''} `} disabled={report?.ocrOutput?.length > 1 && confirmedScansIds.length === 0} onClick={report?.ocrOutput?.length == 1 || confirmedScansIds.length > 0 ? openConfirmModal : ''} >
                            <div className={`confirm-scan-text ${isSmallScreen ? 'mobile' : ''} ${report?.ocrOutput?.length > 1 && confirmedScansIds.length == 0 ? 'disabled' : ''}`}>{t.scans.createReport}</div>
                        </div>
                    </div>

                    <div className={`choose-all-wrapper report-choose-all ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>
                        <input
                            type="checkbox"
                            className='scan-checkbox'
                            onChange={(e) => { chooseAll(e) }}
                            checked={checkAllCheckboxStates}
                        />
                        <div className={`choose-all`}>{t.scan.chooseAll}</div>
                    </div>

                </div>

                {/* errors maping */}
                <div className={``}>
                    {isShowList ?
                        <div className={`slider padding ${isSmallScreen ? 'mobile' : ''}`}>
                            <div className={`dot ${activeIndex === 0 ? 'active' : ''}`}></div>
                            <div className={`dot ${activeIndex === 1 ? 'active' : ''}`}></div>
                            <div className={`dot ${activeIndex === 2 ? 'active' : ''}`}></div>
                        </div>
                        :
                        sortedReportByTypeKeys?.length >= 1 ?
                            (sortedReportByTypeKeys.map((key) => (
                                <div key={key}>
                                    {sortedReportByTypeValues[key].length > 0 &&
                                        <div className={`card-custom-report  ${isSmallScreen ? 'cardMobile' : 'card report'}`}
                                            // data-toggle="collapse"
                                            // data-target={`#collapse-${key}`}
                                            alt="Errow Up"
                                            type='button'
                                        >
                                            <div onClick={(e) => e.stopPropagation()}>
                                                <input
                                                    id={`error-checkbox-${key}`}
                                                    type="checkbox"
                                                    className='scan-checkbox'
                                                    onChange={(e) => { e.stopPropagation(); handleErrorCheckboxChange(e, key) }}
                                                    checked={checkboxStates[key]}
                                                />
                                            </div>
                                            <div style={{ color: 'black' }}>{errorTypesTranslate[key]} {'(' + sortedReportByTypeValues[key].length + ')'}</div>
                                        </div>
                                    }
                                    <div
                                        //  id={`collapse-${key}`} 
                                        className={` ${isSmallScreen ? 'container-error-mobile' : ` container-error`}`}>
                                        {/* className={`collapse ${isSmallScreen ? 'container-error-mobile' : ` container-error`}`}> */}
                                        {sortedReportByTypeValues[key].map((item, ind) => (
                                            <div key={key + ' ' + ind}>
                                                <div
                                                    id={`${index.current}-${key}-${ind}`}
                                                    type='button'
                                                    className={lineErrorClasses[`${index.current}-${key}-${ind}`] || 'line-error'}
                                                    onClick={(e) => {
                                                        if (e.target.type !== 'checkbox') {
                                                            e.stopPropagation();
                                                            showErrorOnImage(item.indexesInOriginalArray);
                                                        }
                                                    }}
                                                >
                                                    {/* {['MISSING_WORD', 'MISSING_LETTER', 'ALTERED_WORD', 'LETTER_SHAPE_CHANGED'].includes(key) ? item.value.orig_text : item.value.ocr_text} */}
                                                    <ImageCropper coordinates={item.coords} originalImageWidth={item.width} originalImageHeight={item.height} showOriginalImage={showOriginalImage}></ImageCropper>
                                                    {/* todo uncheck them defualtly */}
                                                    <input
                                                        type="checkbox"
                                                        className='checkbox'
                                                        checked={lineErrorClasses[`${index.current}-${key}-${ind}`] == 'line-error-checked' ? true : false}
                                                        onChange={(e) => { e.stopPropagation(); handleCheckboxChange(e, item.indexesInOriginalArray, `${index.current}-${key}-${ind}`, key); }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div >
                            )))
                            :
                            <p>{t.report.noErrors}</p>
                    }
                </div>

                {/* footer */}
                <div className='next-scan' type='button' onClick={() => changeIndex(1)}>
                    <u>{index.current + 1 < keyValueArray.length && (`${t.report.nextMezuza} >`)}</u>
                </div>

                {/* view image modal */}
                <Modal
                    show={isImageModalVisible}
                    onHide={() => setIsImageModalVisible(false)}
                    centered
                    dialogClassName={`${(typeScan == 'tefillin') ? isSmallScreen ? 'my-modal-tefillin-mobile' : 'my-modal-tefillin' : `modal right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
                >
                    <ImageCanvas width={originalImageWidth.current} height={originalImageHeight.current} />
                </Modal>

            </div >

            {/*confirm modal */}
            <Modal
                show={isShowConfirmModal}
                onHide={() => { setIsShowConfirmModal(false); activateCancelPress() }}
                centered
                className={`modal ${isSmallScreen ? 'buttom-modal' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Body>
                    <div type='button' className={`are-you-sure`} >
                        <div className={`back-text ${isSmallScreen ? 'mobile' : ''}`}>
                            {t.uploadScans.areYouSure}
                        </div>
                        {report?.ocrOutput?.length > 1 && <div>
                            {`${t.report.fyi} ${confirmedScansIds.length < report?.ocrOutput?.length ? 'רק' : ''} ${confirmedScansIds.length}/${report?.ocrOutput?.length} ${t.scanTypes[typeScan]}`}
                        </div>}
                    </div>
                    <div className={`frame-b ${isSmallScreen ? 'mobile' : ''} ${currentLanguage == 'en-US' ? '' : 'reverse'}`}>
                        <div type="button" ref={buttonRef2} className={`btn-ok ${isSmallScreen ? 'mobile' : ''} col-6 `} onClick={createReport}>
                            <div className={`btn-ok-text ${isSmallScreen ? 'mobile' : ''}`} >
                                {t.uploadScans.ok}
                            </div>
                        </div>
                        <div type="button" className={`btn-cancel ${isSmallScreen ? 'mobile' : ''} col-6`} onClick={() => { setIsShowConfirmModal(false); activateCancelPress() }}>
                            <div className={`btn-ok-text cancel ${isSmallScreen ? 'mobile' : ''} `} >{t.uploadScans.cancel}</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            {/* view pdf modal */}
            <ViewPdf isShowPdf={isShowPdf} setIsShowPdf={setIsShowPdf} reportId={reportId}></ViewPdf>


            {
                disabledCreateReport == true &&
                <Spinner value={spinner}></Spinner>
            }


        </div >
    )
}
export default ReportPage;
