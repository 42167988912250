
import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage, Rect, Transformer } from 'react-konva';
import Tesseract from 'tesseract.js';
import { getMezuzaText, getMezuzaTextArray, getMezuzaTextByLines, getMezuzaTextWithoutSpaces, tefillinKadesh, tefillinKadeshArray, tefillinShamoa, tefillinShamoaArray, tefillinShma, tefillinShmaArray, tefillinVehaya, tefillinVehayaArray, tefillinVehayaWithLines } from '../../../utils/constants';
import levenshtein from 'js-levenshtein';

const ImageCropper = () => {
    const [image, setImage] = useState(null);
    const [selectedShape, setSelectedShape] = useState(null);
    const [documentPoints, setDocumentPoints] = useState(null);
    const [textPoints, setTextPoints] = useState(null);
    const [finalPoints, setFinallPoints] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedCanvasImage, setCroppedCanvasImage] = useState(null); // For cropped canvas image
    const [loadedImage, setLoadedImage] = useState(null); // Track Konva image load state
    // const mezuzaText = getMezuzaTextWithoutSpaces();
    const mezuzaText = getMezuzaText();
    const mezuzaTextLines = getMezuzaTextByLines();
    const mezuzaTextArray = getMezuzaTextArray();

    const tefillinShmaTextArray = tefillinShmaArray();
    const tefillinKadeshTextArray = tefillinKadeshArray();
    const tefillinVehayaTextArray = tefillinVehayaArray();
    const tefillinShamoaTextArray = tefillinShamoaArray();
    const combinedTefillinArray = tefillinShmaTextArray.concat(tefillinKadeshTextArray, tefillinVehayaTextArray, tefillinShamoaTextArray);

    const teffilinVehayaText = tefillinVehaya();
    const teffilinKadeshText = tefillinKadesh();
    const tefillinShmaText = tefillinShma();
    const tefillinShamoaText = tefillinShamoa();
    const combinedTefillinText = teffilinVehayaText + teffilinKadeshText + tefillinShmaText + tefillinShamoaText;

    const imageRef = useRef(null);
    const cropRef = useRef(null);
    const croptrRef = useRef(null);
    const canvasRef = useRef(null); // Ref for the HTML canvas
    const cv = window.cv;
    const [scanType, setScanType] = useState('tefillin')
    // const scanType = 'tefillin';
    // const scanType = 'mezuza';
    // const MAX_IMAGE_WIDTH = 600//1536;
    // const MAX_IMAGE_HEIGHT = 500//695;
    const MAX_IMAGE_WIDTH = scanType == 'tefillin' ? 1536 : 600;
    // const MAX_IMAGE_HEIGHT = 695;
    // const MAX_IMAGE_WIDTH = 695;
    const MAX_IMAGE_HEIGHT = 695;
    const MIN_RECT_SIZE = 20;
    const processedCanvasRef = useRef(null); // Ref for the processed image canvas (new)

    const [grayCanvasImage, setGrayCanvasImage] = useState(null); // Canvas for gray image
    const [blurredCanvasImage, setBlurredCanvasImage] = useState(null); // Canvas for blurred image
    const [edgesCanvasImage, setEdgesCanvasImage] = useState(null); // Canvas for edges image
    const [dilatedCanvasImage, setDilatedCanvasImage] = useState(null); // Canvas for dilated image

    const handleImageUpload = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new window.Image();
            img.src = event.target.result;

            img.onload = () => {
                const maxWidth = MAX_IMAGE_WIDTH;
                const maxHeight = MAX_IMAGE_HEIGHT;

                let width = img.width;
                let height = img.height;
                console.log(`🏁🏁🏁🤩Image width: ${width}, height: ${height}`);

                if (width > maxWidth || height > maxHeight) {
                    const aspectRatio = width / height;
                    if (width > height) {
                        width = maxWidth;
                        height = maxWidth / aspectRatio;
                    } else {
                        height = maxHeight;
                        width = maxHeight * aspectRatio;
                    }
                }

                setImage({ img, width, height });

                // Draw the image on the plain canvas
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');
                context.imageSmoothingEnabled = true;
                context.imageSmoothingQuality = 'high';
                canvas.width = width;
                canvas.height = height;
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(img, 0, 0, width, height);

                const src = cv.imread(canvas);
                if (!src || src.empty()) {
                    console.error('OpenCV: Failed to read image from canvas');
                    return;
                }

                const gray = new cv.Mat();
                cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0);
                cv.threshold(gray, src, 0, 255, cv.THRESH_TOZERO); // Keeps values above threshold, sets others to 0
                drawCanvas(gray, setGrayCanvasImage); // Update the gray canvas

                const blurred = new cv.Mat();
                cv.GaussianBlur(gray, blurred, new cv.Size(7, 7), 0);
                drawCanvas(blurred, setBlurredCanvasImage); // Update the blurred canvas

                const edges = new cv.Mat();
                cv.Canny(blurred, edges, 90, 205, 3, false);
                drawCanvas(edges, setEdgesCanvasImage); // Update the edges canvas

                let kernel;
                if (scanType === 'tefillin') {
                    kernel = cv.Mat.ones(11, 11, cv.CV_8U);
                } else {
                    kernel = cv.Mat.ones(9, 9, cv.CV_8U);
                }

                const dilated = new cv.Mat();
                cv.dilate(edges, dilated, kernel);
                drawCanvas(dilated, setDilatedCanvasImage); // Update the dilated canvas

                const contours = new cv.MatVector();
                const hierarchy = new cv.Mat();
                cv.findContours(dilated, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
                console.log('aaa');

                let maxContour = null;
                let maxArea = 0;
                for (let i = 0; i < contours.size(); ++i) {
                    const contour = contours.get(i);
                    const area = cv.contourArea(contour);
                    if (area > maxArea) {
                        maxArea = area;
                        maxContour = contour;
                    }
                }
                if (maxContour == null) {
                    console.error('OpenCV: maxContour is null');
                    return;
                }
                let points = [];
                let angle;
                if (scanType !== 'tefillin' && maxArea < 100000) {
                    console.log('scanType !== tefillin && maxArea < 100000', scanType !== 'tefillin' && maxArea < 100000);

                    // Case of cropped images of mezuza
                    setSelectedShape({
                        id: 'cropperRect',
                        attributes: {
                            x: 0,
                            y: 0,
                            width: width,
                            height: height,
                            rotation: 0,
                        },
                    });
                    setIsCropping(true);
                }
                else {
                    const rotatedRect = cv.minAreaRect(maxContour);
                    console.log('rotatedRect', rotatedRect);

                    angle = rotatedRect.angle;
                    points = cv.RotatedRect.points(rotatedRect);

                    context.strokeStyle = 'red';
                    context.lineWidth = 2;
                    context.beginPath();
                    context.moveTo(points[0].x, points[0].y);
                    for (let i = 1; i < 4; i++) {
                        context.lineTo(points[i].x, points[i].y);
                    }
                    context.closePath();
                    context.stroke();
                    const sortedPoints = sortPointsFunc(points)
                    console.log('document points 😉😉', sortedPoints);

                    setDocumentPoints(sortedPoints);
                }

                src.delete();
                gray.delete();
                blurred.delete();
                edges.delete();
                dilated.delete();
                contours.delete();
                hierarchy.delete();
                if (maxContour) maxContour.delete();
                console.log('points', points)

                const sortedPoints = sortPointsFunc(points)

                recognizeTextWithTesseract(event.target.result, width, height, sortedPoints, angle);
                // recognizeTextWithTesseract(canvas, sortedPoints, angle);
            };
        };
        reader.readAsDataURL(file);
    };

    const drawCanvas = (mat, setCanvasImage) => {
        const canvas = document.createElement('canvas');
        canvas.width = mat.cols;
        canvas.height = mat.rows;
        cv.imshow(canvas, mat);
        setCanvasImage(canvas.toDataURL());
    };

    // Tesseract.js Text Recognition
    const recognizeTextWithTesseract1 = async (imageSrc, width, height, documentPoints, angle) => {
        const image = new Image();
        image.src = imageSrc;  // imageSrc should be the valid URL of the image
        image.onload = () => {

            const canvas = processedCanvasRef.current;
            const context = canvas.getContext('2d');
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = 'high';
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0, width, height);
            const src = cv.imread(canvas);
            let gray = new cv.Mat();
            let dst = new cv.Mat();

            // Convert the image to grayscale
            cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);
            // Apply binarization with a threshold of 128 and a max value of 255
            // cv.threshold(gray, dst, 105, 255, cv.THRESH_BINARY);
            cv.threshold(gray, dst, 0, 255, cv.THRESH_TOZERO); // Keeps values above threshold, sets others to 0

            cv.imshow(canvas, dst);
            // Tesseract.create({
            //     corePath: '/tesseract-core.wasm.js',
            //     workerPath: '/tesseract-worker.js',
            //     langPath: '/tessdata',  // Folder containing your custom model
            // }).then(tesseract => {
            //     return tesseract.loadLanguage('heb');
            // }).then(tesseract => {
            //     return tesseract.initialize('heb');
            // }).then(tesseract => {
            //     return tesseract.recognize(canvas, 'heb', {
            //         tessedit_pageseg_mode: 6,
            //         logger: m => console.log(m),
            //     });
            // }).then(result => {
            //     console.log(result.data.text);
            // }).catch(error => {
            //     console.error('Error:', error);
            // });

            Tesseract.recognize(
                canvas,
                'heb', // Hebrew language
                {
                    // langPath: '/tessdata/heb.traineddata',
                    langPath: '/tessdata',
                    // tessedit_debug_fonts: 1,  
                    // tessedit_pageseg_mode: 6,  
                    // tessedit_char_whitelist: 'אבגדהוזחטיכלמנסעפצקרשת',
                    logger: (m) => console.log(m),
                }
            ).then(({ data }) => {
                console.log('Full recognition data:', data); // Logs the full data object

                console.log('Lines recognized:', data.lines);
                console.log('Words recognized:', data.words);
            }).catch((err) => {
                console.error('Error in Tesseract:', err);
            });
        }

    };
    const recognizeTextWithTesseract = async (imageSrc, width, height, documentPoints, angle) => {
        const image = new Image();
        image.src = imageSrc;  // imageSrc should be the valid URL of the image
        image.onload = () => {

            const canvas = processedCanvasRef.current;
            const context = canvas.getContext('2d');
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = 'high';
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0, width, height);
            const src = cv.imread(canvas);
            let gray = new cv.Mat();
            let dst = new cv.Mat();

            // Convert the image to grayscale
            cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);
            // Apply binarization with a threshold of 128 and a max value of 255
            // cv.threshold(gray, dst, 100, 255, cv.THRESH_BINARY);
            cv.threshold(gray, dst, 0, 255, cv.THRESH_TOZERO); // Keeps values above threshold, sets others to 0

            cv.imshow(canvas, dst);

            Tesseract.recognize(
                canvas,
                'heb', // Hebrew language
                {
                    // tessedit_char_whitelist: 'אבגדהוזחטיכלמנסעפצקרשת',
                    // logger: (m) => console.log(m),
                }
            ).then(({ data: { lines, words } }) => {
                console.log('Lines recognized:', lines);
                console.log('Words recognized:', words);
                let minX = Infinity;
                let minY = Infinity;
                let maxX = -Infinity;
                let maxY = -Infinity;

                const canvas = canvasRef.current;
                const ctx = canvas.getContext('2d');
                // const colors = ['red', 'black', 'orange', 'cyan', 'magenta'];

                // lines.forEach((line, index) => {
                //     const { bbox } = line;

                //     // Select a color based on the index
                //     ctx.strokeStyle = colors[index % colors.length]; // Cycle through colors
                //     ctx.lineWidth = 6;

                //     // Draw the rectangle for each line
                //     ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);
                // });

                ctx.lineWidth = 2;
                const pointsArray = [];
                const notHebrew = '0123456789/*-!@#$%^&&()-=';
                const hebrewLetters = 'אבגדהוזחטיכלמנסעפצקרשתףךןםץ'

                const textArrayToLoop = scanType == 'tefillin' ? combinedTefillinArray : mezuzaTextArray;
                console.log('textArrayToLoop', textArrayToLoop);

                const tefillinArray = combinedTefillinText?.split(/\s+/);
                const mezuzaArray = mezuzaText?.split(/\s+/)
                const wordsArray = scanType == 'tefillin' ? tefillinArray : mezuzaArray;

                lines.forEach((line, lineIndex) => {
                    // ctx.strokeStyle = 'yellow';
                    // ctx.strokeRect(line.bbox.x0, line.bbox.y0, line.bbox.x1 - line.bbox.x0, line.bbox.y1 - line.bbox.y0);

                    let isGoodWordInLine = false;
                    const notRealWords = []
                    line.words.forEach((word, wordIndex) => {
                        const confidence = word.choices[0].confidence;
                        const specialCharacters = /[0-9/*\-!@#$%^&()=.,\[\]]/g;
                        const wordLength = word.text.match(specialCharacters)?.length > 1 ? 0 : word.text.replace(specialCharacters, '').length;
                        const clereWord = word.text.replace(/[0-9/*\-!@#$%^&()=.,\[\]]/g, '');
                        // const clereWord = word.text.replace(/[\u0591-\u05C7]/g, '');
                        const isCalculateSimilarity = textArrayToLoop.some((item) => {
                            const parameter = calculateSimilarity(item.word, word.text)
                            if (parameter >= item.percent) {
                                // console.log('item.word, word.text', item.word, word.text, item.percent, parameter);

                                return true;
                            }
                            return false;
                        })

                        const isWordPresent = wordsArray.includes(clereWord);

                        const isRealWord = wordLength > 1 && (isWordPresent || confidence > 70 || isCalculateSimilarity);
                        // console.log('isRealWord🤩🤩', isRealWord, word.text, isWordPresent, confidence > 70, isCalculateSimilarity);

                        const { bbox } = word;
                        if (isRealWord) {
                            ctx.fillStyle = 'blue';
                            ctx.font = '18px Arial';
                            ctx.fillText(lineIndex + word.text, bbox.x0 + 20, bbox.y0); // Draw text slightly above the bounding box
                            // ctx.fillText(word.text, bbox.x0 + 20, bbox.y0); // Draw text slightly above the bounding box

                            isGoodWordInLine = true;
                            ctx.strokeStyle = 'blue';

                            ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);

                            // Find the overall bounding box
                            minX = Math.min(minX, bbox.x0);
                            minY = Math.min(minY, bbox.y0);
                            maxX = Math.max(maxX, bbox.x1);
                            maxY = Math.max(maxY, bbox.y1);

                            pointsArray.push([bbox.x0, bbox.y0]); // Top-left
                            pointsArray.push([bbox.x1, bbox.y0]); // Top-right
                            pointsArray.push([bbox.x1, bbox.y1]); // Bottom-right
                            pointsArray.push([bbox.x0, bbox.y1]); // Bottom-left
                        }
                        else {
                            ctx.fillStyle = 'red';
                            ctx.font = '18px Arial';
                            ctx.fillText(lineIndex + word.text, bbox.x0 + 20, bbox.y0); // Draw text slightly above the bounding box
                            // ctx.fillText(word.text, bbox.x0 + 20, bbox.y0); // Draw text slightly above the bounding box

                            notRealWords.push(wordIndex)
                            ctx.strokeStyle = 'pink';
                            ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);
                        }
                    });

                    if (isGoodWordInLine) {//add the word in the line that not recognized
                        //todo instead of loop on notRealWords, take the line bbox
                        //chaeck if the result is the same

                        //todo instead of loop on notRealWords, take the line bbox
                        //chaeck if the result is the same
                        // isGoodWordInLine = true;
                        // ctx.strokeStyle = 'purple';
                        // ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);

                        // Find the overall bounding box
                        // const { bbox } = line;
                        // minX = Math.min(minX, bbox.x0);
                        // minY = Math.min(minY, bbox.y0);
                        // maxX = Math.max(maxX, bbox.x1);
                        // maxY = Math.max(maxY, bbox.y1);

                        // pointsArray.push([bbox.x0, bbox.y0]);
                        // pointsArray.push([bbox.x1, bbox.y0]);
                        // pointsArray.push([bbox.x1, bbox.y1]);
                        // pointsArray.push([bbox.x0, bbox.y1]);
                        notRealWords.forEach((index) => {
                            const word = line.words[index]
                            const specialCharacters = /[0-9/*\-!@#$%^&()=.,]/g;
                            const wordLength = word.text.match(specialCharacters)?.length > 1
                                ? 0
                                : word.text.replace(specialCharacters, '').length;

                            if (wordLength > 1) {

                                const { bbox } = word;
                                isGoodWordInLine = true;
                                ctx.strokeStyle = 'purple';
                                ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);

                                // Find the overall bounding box
                                minX = Math.min(minX, bbox.x0);
                                minY = Math.min(minY, bbox.y0);
                                maxX = Math.max(maxX, bbox.x1);
                                maxY = Math.max(maxY, bbox.y1);

                                pointsArray.push([bbox.x0, bbox.y0]);
                                pointsArray.push([bbox.x1, bbox.y0]);
                                pointsArray.push([bbox.x1, bbox.y1]);
                                pointsArray.push([bbox.x0, bbox.y1]);
                            }
                        })

                    }
                })

                let finnalPointsToCropper = []
                if (pointsArray.length === 0) {
                    finnalPointsToCropper = documentPoints;
                }
                else {
                    const pointsMat = cv.matFromArray(pointsArray.length, 1, cv.CV_32FC2, pointsArray.flat());
                    const rotatedRect = cv.minAreaRect(pointsMat);
                    const textPoints = cv.RotatedRect.points(rotatedRect);
                    console.log('textPoints', textPoints);

                    const sortedTextPoints = sortPointsFunc(textPoints)
                    console.log('sortedTextPoints♥️♥️', sortedTextPoints);
                    setTextPoints(textPoints)
                    ctx.strokeStyle = 'green';
                    ctx.lineWidth = 4;
                    ctx.beginPath();
                    ctx.moveTo(textPoints[0].x, textPoints[0].y);

                    for (let i = 1; i < 4; i++) {
                        ctx.lineTo(textPoints[i].x, textPoints[i].y);
                    }
                    ctx.closePath();
                    ctx.stroke();
                    pointsMat?.delete();
                    const overlappingPoints = [
                        { x: Math.max(documentPoints[0].x, sortedTextPoints[0].x), y: Math.max(documentPoints[0].y, sortedTextPoints[0].y) },
                        { x: Math.min(documentPoints[1].x, sortedTextPoints[1].x), y: Math.max(documentPoints[1].y, sortedTextPoints[1].y) },
                        { x: Math.min(documentPoints[2].x, sortedTextPoints[2].x), y: Math.min(documentPoints[2].y, sortedTextPoints[2].y) },
                        { x: Math.max(documentPoints[3].x, sortedTextPoints[3].x), y: Math.min(documentPoints[3].y, sortedTextPoints[3].y) },
                    ]

                    console.log('documentPoints', documentPoints);
                    const docWidth = documentPoints[1].x - documentPoints[0].x;
                    const docHeight = documentPoints[3].y - documentPoints[0].y;
                    const textWidth = textPoints[1].x - textPoints[0].x;
                    const textHeight = textPoints[3].y - textPoints[0].y;
                    console.log('docWidth', docWidth, 'docHeight', docHeight, 'textWidth', textWidth, 'textHeight', textHeight);

                    if (textWidth < 70 || textHeight < 40) {//todo maybe calculate for verticle and for horizinal in separate numbers
                        console.log('text points too small ❌❌❌');
                        finnalPointsToCropper = documentPoints;
                    }
                    else {
                        const widthDiff = docWidth - textWidth;
                        const heightDiff = docHeight - textHeight;
                        console.log('widthDiff', widthDiff, 'heightDiff', heightDiff);

                        const isTefillinValid = docWidth < textWidth && heightDiff > 0 && heightDiff < 20
                        const isccc = docWidth - textWidth > 2 && heightDiff < 0;
                        // const isccc = docWidth > textWidth && heightDiff < 0;
                        const is = docWidth < textWidth && heightDiff < 0;//todo 
                        const isMezuzaValid = widthDiff < 15 && docHeight > textHeight;
                        console.log('isTefillinValid', isTefillinValid, 'isccc', isccc, 'is', is, 'isMezuzaValid', isMezuzaValid);

                        if (scanType === 'tefillin' ? (isTefillinValid || is || isccc) : isMezuzaValid) {
                            console.log('yesssssssssssssssssssssssssssssssssssssssss');
                            if (scanType == 'tefillin') {
                                console.log('aaaaaaaaaaaaaaaaaa');
                                if (isTefillinValid || is)
                                    finnalPointsToCropper = textPoints;
                                else
                                    finnalPointsToCropper = documentPoints
                            }
                            else {
                                console.log('bbbbbbbbbbbbbbbbbbbbbbbb');
                                finnalPointsToCropper = documentPoints;
                            }
                        }
                        else {
                            finnalPointsToCropper = overlappingPoints;
                            console.log('noooooooooooooooooooooooooooooooooooooooooo');
                        }
                    }
                }

                const arrayOfArrays = finnalPointsToCropper.map(obj => Object.values(obj));

                const newPointsMat = cv.matFromArray(arrayOfArrays.length, 1, cv.CV_32FC2, arrayOfArrays.flat());
                const newRotatedRect = cv.minAreaRect(newPointsMat);
                const finalPoints = cv.RotatedRect.points(newRotatedRect);
                const angle = newRotatedRect.angle
                console.log('newRotatedRect', angle);
                const sortedPoints = sortPointsFunc(finalPoints)
                setFinallPoints(sortedPoints)

                setSelectedShape({
                    id: 'cropperRect',
                    attributes: angle > 45 ?
                        {
                            x: sortedPoints[0].x,
                            y: sortedPoints[0].y,
                            width: newRotatedRect.size.height,
                            height: newRotatedRect.size.width,
                            rotation: angle - 90,
                        }
                        :
                        {
                            x: sortedPoints[0].x,
                            y: sortedPoints[0].y,
                            width: newRotatedRect.size.width,
                            height: newRotatedRect.size.height,
                            rotation: angle,
                        }
                });

                setIsCropping(true);

                ctx.strokeStyle = 'purple';
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.moveTo(finalPoints[0].x, finalPoints[0].y);
                for (let i = 1; i < 4; i++) {
                    ctx.lineTo(finalPoints[i].x, finalPoints[i].y);
                }
                ctx.closePath();
                ctx.stroke();

                ctx.strokeStyle = 'pink';
                ctx.lineWidth = 3;
                finnalPointsToCropper.map((point, i) => {
                    ctx.beginPath();
                    ctx.moveTo(point.x, point.y);
                    ctx.lineTo(point.x + 3, point.y + 3);
                    ctx.stroke();
                })

                ctx.lineWidth = 1;
                ctx.strokeStyle = 'yellow';
                ctx.beginPath();
                ctx.moveTo(finnalPointsToCropper[0].x, finnalPointsToCropper[0].y);

                for (let i = 1; i < 4; i++) {
                    ctx.lineTo(finnalPointsToCropper[i].x, finnalPointsToCropper[i].y);
                }
                ctx.closePath();
                ctx.stroke();


            }).catch((err) => {
                console.error('Error in Tesseract:', err);
            });
        }

    };

    const calculateSimilarity = (textPart1, textPart2) => {
        if (textPart1 && textPart2) {
            const distance = levenshtein(textPart1, textPart2);
            const maxLength = Math.max(textPart1.length, textPart2.length);
            const similarity = ((maxLength - distance) / maxLength) * 100;
            return similarity;
        }
        else return 0;
    };

    useEffect(() => {
        if (image && image.img) {
            const konvaImage = new window.Image();
            konvaImage.src = image.img.src;
            konvaImage.onload = () => {
                setLoadedImage(konvaImage);
            };
        }
    }, [image]);

    useEffect(() => {
        if (cropRef.current && croptrRef.current) {
            croptrRef.current.nodes([cropRef.current]);
            croptrRef.current.getLayer().batchDraw();
        }
    }, [isCropping, selectedShape]);

    const handleShapeChange = (updatedShape) => {
        setSelectedShape(updatedShape.newItem);
    };

    const handleCrop1 = () => {
        const cropper = cropRef.current;
        const originalImage = image.img; // Original image element

        // Set up a temporary canvas at the full resolution of the original image
        const canvas = document.createElement('canvas');
        canvas.width = originalImage.naturalWidth; // Full original resolution
        canvas.height = originalImage.naturalHeight;

        const context = canvas.getContext('2d');
        context.drawImage(originalImage, 0, 0, canvas.width, canvas.height); // Draw the original image at full size

        let { x, y, width, height, rotation } = selectedShape.attributes;
        console.log('rotation', rotation);

        // Scale factor based on the difference between the displayed image size and the original image size
        const scaleX = originalImage.naturalWidth / image.width;
        const scaleY = originalImage.naturalHeight / image.height;

        // Calculate the cropped region in the original image coordinates
        const scaledX = x * scaleX;
        const scaledY = y * scaleY;
        const scaledWidth = width * scaleX;
        const scaledHeight = height * scaleY;

        // Create a new canvas to hold the cropped area
        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = scaledWidth;
        croppedCanvas.height = scaledHeight;

        const croppedContext = croppedCanvas.getContext('2d');

        // Translate and rotate the context for proper cropping
        croppedContext.translate(scaledWidth / 2, scaledHeight / 2);
        croppedContext.rotate((rotation * Math.PI) / 180);
        croppedContext.translate(-scaledWidth / 2, -scaledHeight / 2);

        // Draw the cropped part of the full-resolution image onto the new canvas
        croppedContext.drawImage(
            canvas,
            scaledX,
            scaledY,
            scaledWidth,
            scaledHeight,
            0,
            0,
            scaledWidth,
            scaledHeight
        );

        // Convert the cropped area to a data URL for high-quality output
        const croppedDataUrl = croppedCanvas.toDataURL('image/jpeg', 1.0); // High-quality JPEG

        setCroppedCanvasImage(croppedDataUrl); // Save the cropped image for display

        cropper.visible(false); // Hide the cropper after cropping
    };


    const handleCrop = () => {
        const cropper = cropRef.current;
        const originalImage = image.img; // Original image element

        // Set up a temporary canvas at the full resolution of the original image
        const canvas = document.createElement('canvas');
        canvas.width = originalImage.naturalWidth; // Full original resolution
        canvas.height = originalImage.naturalHeight;

        const context = canvas.getContext('2d');
        context.drawImage(originalImage, 0, 0, canvas.width, canvas.height); // Draw the original image at full size

        let { x, y, width, height, rotation } = selectedShape.attributes;
        console.log('rotation', rotation);

        // Scale factor based on the difference between the displayed image size and the original image size
        const scaleX = originalImage.naturalWidth / image.width;
        const scaleY = originalImage.naturalHeight / image.height;

        // Convert the coordinates to match the original image size
        const scaledX = x * scaleX;
        const scaledY = y * scaleY;
        const scaledWidth = width * scaleX;
        const scaledHeight = height * scaleY;

        // Create a new canvas for the cropped area
        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = scaledWidth;
        croppedCanvas.height = scaledHeight;

        const croppedContext = croppedCanvas.getContext('2d');
        croppedContext.save();

        // Translate the context to the center of the crop area to handle rotation
        // croppedContext.translate(scaledWidth / 2, scaledHeight / 2);
        // croppedContext.rotate(0);
        // croppedContext.rotate((rotation * Math.PI) / 180);
        // console.log('(rotation * Math.PI) / 180', (rotation * Math.PI) / 180);

        // croppedContext.translate(-scaledWidth / 2, -scaledHeight / 2);


        // Draw the rotated image based on the selected crop area
        croppedContext.drawImage(
            canvas,
            scaledX, scaledY, scaledWidth, scaledHeight, // Source x, y, width, height
            0, 0, scaledWidth, scaledHeight // Destination x, y, width, height
        );
        croppedContext.restore()
        const croppedDataUrl = croppedCanvas.toDataURL('image/jpeg', 1.0);

        setCroppedCanvasImage(croppedDataUrl);

        cropper.visible(false);
    };


    const sortPointsFunc = (pointsToSort) => {
        let centroid = { x: 0, y: 0 };
        for (let i = 0; i < pointsToSort.length; i++) {
            centroid.x += pointsToSort[i].x;
            centroid.y += pointsToSort[i].y;
        }
        centroid.x /= pointsToSort.length;
        centroid.y /= pointsToSort.length;
        function angleFromCentroid(point) {
            return Math.atan2(point.y - centroid.y, point.x - centroid.x);
        }
        const sortedPoints = pointsToSort.sort((a, b) => {
            return angleFromCentroid(a) - angleFromCentroid(b);
        });
        return sortedPoints;
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = croppedCanvasImage;
        link.download = 'cropped-image.png';
        link.click();
    };

    return (
        <div>
            <div className='btn btn-danger'>סוג הסריקה:{scanType}</div>
            <button className='btn btn-primary' onClick={() => setScanType(scanType == 'tefillin' ? 'mezuza' : 'tefillin')}> שנה סוג סריקה ל{scanType == 'tefillin' ? 'mezuza' : 'tefillin'}</button>
            <input type="file" onChange={handleImageUpload} />
            <div>rotation: {selectedShape?.attributes?.rotation}</div>
            <div style={{ marginTop: '20px' }}>
                {image && (
                    <>
                        <Stage width={image.width} height={image.height}>
                            <Layer>
                                {loadedImage && (
                                    <KonvaImage
                                        image={loadedImage}
                                        ref={imageRef}
                                        x={0}
                                        y={0}
                                        width={image.width}
                                        height={image.height}
                                    />
                                )}
                                {isCropping && selectedShape && (
                                    <>
                                        <Rect
                                            ref={cropRef}
                                            x={selectedShape.attributes.x}
                                            y={selectedShape.attributes.y}
                                            width={selectedShape.attributes.width}
                                            height={selectedShape.attributes.height}
                                            rotation={selectedShape.attributes.rotation}
                                            stroke="red"
                                            strokeWidth={2}
                                            draggable
                                            onTransformEnd={(e) => {
                                                const node = cropRef.current;
                                                const scaleX = node.scaleX();
                                                const scaleY = node.scaleY();
                                                node.scaleX(1);
                                                node.scaleY(1);

                                                const newWidth = Math.max(MIN_RECT_SIZE, node.width() * scaleX);
                                                const newHeight = Math.max(MIN_RECT_SIZE, node.height() * scaleY);

                                                const newShape = {
                                                    ...selectedShape,
                                                    attributes: {
                                                        ...selectedShape.attributes,
                                                        x: node.x(),
                                                        y: node.y(),
                                                        width: newWidth,
                                                        height: newHeight,
                                                        rotation: node.rotation(),
                                                    },
                                                };

                                                handleShapeChange({
                                                    id: selectedShape.id,
                                                    newItem: newShape,
                                                });
                                            }}
                                            onDragEnd={(e) => {
                                                const node = cropRef.current;
                                                const x = Math.max(0, Math.min(node.x(), image.width - node.width()));
                                                const y = Math.max(0, Math.min(node.y(), image.height - node.height()));

                                                const newShape = {
                                                    ...selectedShape,
                                                    attributes: {
                                                        ...selectedShape.attributes,
                                                        x,
                                                        y,
                                                    },
                                                };

                                                handleShapeChange({
                                                    id: selectedShape.id,
                                                    newItem: newShape,
                                                });
                                            }}
                                        />

                                        <Transformer
                                            ref={croptrRef}
                                            rotateEnabled={true}
                                            flipEnabled={false}
                                            boundBoxFunc={(oldBox, newBox) => {
                                                if (newBox.width < MIN_RECT_SIZE || newBox.height < MIN_RECT_SIZE) {
                                                    return oldBox;
                                                }
                                                return newBox;
                                            }}
                                        />
                                    </>
                                )}
                                {/* Draw blue rectangles around each recognized word */}
                            </Layer>
                        </Stage>
                        <button onClick={handleCrop} style={{ marginTop: '20px' }}>
                            Crop
                        </button>
                    </>
                )}
            </div>


            {croppedCanvasImage && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Cropped Canvas Image:</h3>
                    <img src={croppedCanvasImage} alt="Cropped Canvas" style={{ width: '30vw' }} />
                    <button onClick={handleDownload} style={{ marginTop: '10px' }}>
                        Download Cropped Image
                    </button>
                </div>
            )}
            {/* Plain canvas to display the uploaded image */}
            <div style={{ marginTop: '20px' }}>
                <h3>Image on HTML Canvas:</h3>
                <canvas ref={canvasRef} />
            </div>

            {croppedImage && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Cropped Konva Image:</h3>
                    <img src={croppedImage} alt="Cropped Konva" />
                </div>
            )}

            {grayCanvasImage && (
                <>
                    <h3>Gray Image:</h3>
                    <img src={grayCanvasImage} alt="Gray" />
                </>
            )}
            {blurredCanvasImage && (
                <>
                    <h3>Blurred Image:</h3>
                    <img src={blurredCanvasImage} alt="Blurred" />
                </>
            )}
            {edgesCanvasImage && (
                <>
                    <h3>Edges Image:</h3>
                    <img src={edgesCanvasImage} alt="Edges" />
                </>
            )}
            {dilatedCanvasImage && (
                <>
                    <h3>Dilated Image:</h3>
                    <img src={dilatedCanvasImage} alt="Dilated" />
                </>
            )}

            <div style={{ marginTop: '20px' }}>
                <h3>Processed Image on New Canvas:</h3>
                <canvas ref={processedCanvasRef} />
            </div>

        </div>
    );
};

export default ImageCropper;










////////////find text just into the document borders

// import React, { useState, useRef, useEffect } from 'react';
// import { Stage, Layer, Image as KonvaImage, Rect, Transformer } from 'react-konva';
// import Tesseract from 'tesseract.js';

// const ImageCropper = () => {
//     const [image, setImage] = useState(null);
//     const [selectedShape, setSelectedShape] = useState(null);
//     const [documentPoints, setDocumentPoints] = useState(null);
//     const [textPoints, setTextPoints] = useState(null);
//     const [finalPoints, setFinalPoints] = useState(null);
//     const [isCropping, setIsCropping] = useState(false);
//     const [croppedImage, setCroppedImage] = useState(null);
//     const [croppedCanvasImage, setCroppedCanvasImage] = useState(null);
//     const [loadedImage, setLoadedImage] = useState(null);

//     const imageRef = useRef(null);
//     const cropRef = useRef(null);
//     const croptrRef = useRef(null);
//     const canvasRef = useRef(null);
//     const cv = window.cv;
//     const MAX_IMAGE_WIDTH = 695;
//     const MAX_IMAGE_HEIGHT = 695;
//     const MIN_RECT_SIZE = 20;

//     const scanType = 'tefillin';

//     const handleImageUpload = (e) => {
//         const file = e.target.files[0];
//         const reader = new FileReader();
//         reader.onload = (event) => {
//             const img = new window.Image();
//             img.src = event.target.result;
//             img.onload = () => {
//                 const maxWidth = MAX_IMAGE_WIDTH;
//                 const maxHeight = MAX_IMAGE_HEIGHT;

//                 let width = img.width;
//                 let height = img.height;

//                 if (width > maxWidth || height > maxHeight) {
//                     const aspectRatio = width / height;
//                     if (width > height) {
//                         width = maxWidth;
//                         height = maxWidth / aspectRatio;
//                     } else {
//                         height = maxHeight;
//                         width = maxHeight * aspectRatio;
//                     }
//                 }

//                 setImage({ img, width, height });

//                 const canvas = canvasRef.current;
//                 const context = canvas.getContext('2d');
//                 canvas.width = width;
//                 canvas.height = height;
//                 context.clearRect(0, 0, canvas.width, canvas.height);
//                 context.drawImage(img, 0, 0, width, height);

//                 const src = cv.imread(canvas);
//                 if (!src || src.empty()) {
//                     console.error('OpenCV: Failed to read image from canvas');
//                     return;
//                 }

//                 const gray = new cv.Mat();
//                 cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0);
//                 const blurred = new cv.Mat();
//                 cv.GaussianBlur(gray, blurred, new cv.Size(7, 7), 0);
//                 const edges = new cv.Mat();
//                 cv.Canny(blurred, edges, 90, 205, 3, false);
//                 let kernel;
//                 if (scanType === 'tefillin') {
//                     kernel = cv.Mat.ones(11, 11, cv.CV_8U);
//                 } else {
//                     kernel = cv.Mat.ones(9, 9, cv.CV_8U);
//                 }

//                 const dilated = new cv.Mat();
//                 cv.dilate(edges, dilated, kernel);
//                 const contours = new cv.MatVector();
//                 const hierarchy = new cv.Mat();
//                 cv.findContours(dilated, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

//                 let maxContour = null;
//                 let maxArea = 0;
//                 for (let i = 0; i < contours.size(); ++i) {
//                     const contour = contours.get(i);
//                     const area = cv.contourArea(contour);
//                     if (area > maxArea) {
//                         maxArea = area;
//                         maxContour = contour;
//                     }
//                 }
//                 if (maxContour == null) {
//                     console.error('OpenCV: maxContour is null');
//                     return;
//                 }
//                 let points;
//                 let angle;
//                 if (scanType !== 'tefillin' && maxArea < 100000) {
//                     setSelectedShape({
//                         id: 'cropperRect',
//                         attributes: {
//                             x: 0,
//                             y: 0,
//                             width: width,
//                             height: height,
//                             rotation: 0,
//                         },
//                     });
//                     setIsCropping(true);
//                 }
//                 else {
//                     const rotatedRect = cv.minAreaRect(maxContour);
//                     angle = rotatedRect.angle;
//                     points = cv.RotatedRect.points(rotatedRect);

//                     context.strokeStyle = 'red';
//                     context.lineWidth = 2;
//                     context.beginPath();
//                     context.moveTo(points[0].x, points[0].y);
//                     for (let i = 1; i < 4; i++) {
//                         context.lineTo(points[i].x, points[i].y);
//                     }
//                     context.closePath();
//                     context.stroke();

//                     setDocumentPoints(points);
//                 }

//                 src.delete();
//                 gray.delete();
//                 blurred.delete();
//                 edges.delete();
//                 dilated.delete();
//                 contours.delete();
//                 hierarchy.delete();
//                 if (maxContour) maxContour.delete();

//                 let centroid = { x: 0, y: 0 };
//                 for (let i = 0; i < points.length; i++) {
//                     centroid.x += points[i].x;
//                     centroid.y += points[i].y;
//                 }
//                 centroid.x /= points.length;
//                 centroid.y /= points.length;

//                 function angleFromCentroid(point) {
//                     return Math.atan2(point.y - centroid.y, point.x - centroid.x);
//                 }

//                 const sortedPoints = points.sort((a, b) => {
//                     return angleFromCentroid(a) - angleFromCentroid(b);
//                 });

//                 recognizeTextWithTesseract(canvas, sortedPoints, angle);
//             };
//         };
//         reader.readAsDataURL(file);
//     };

//     const recognizeTextWithTesseract = (canvas, documentPoints, angle) => {
//         const minX = Math.min(...documentPoints.map(p => p.x));
//         const minY = Math.min(...documentPoints.map(p => p.y));
//         const maxX = Math.max(...documentPoints.map(p => p.x));
//         const maxY = Math.max(...documentPoints.map(p => p.y));

//         const width = maxX - minX;
//         const height = maxY - minY;

//         const tempCanvas = document.createElement('canvas');
//         tempCanvas.width = width;
//         tempCanvas.height = height;
//         const tempCtx = tempCanvas.getContext('2d');

//         tempCtx.drawImage(canvas, minX, minY, width, height, 0, 0, width, height);

//         Tesseract.recognize(
//             tempCanvas,
//             'heb',
//             {}
//         ).then(({ data: { words } }) => {
//             const ctx = canvas.getContext('2d');
//             ctx.lineWidth = 2;
//             const pointsArray = [];
//             words.forEach((word) => {
//                 const { bbox, text } = word;
//                 if (bbox.x1 - bbox.x0 > 10 && bbox.y1 - bbox.y0 > 10 && text.length>2) {
//                     const adjustedX0 = bbox.x0 + minX;
//                     const adjustedY0 = bbox.y0 + minY;
//                     const adjustedX1 = bbox.x1 + minX;
//                     const adjustedY1 = bbox.y1 + minY;

//                     // Draw bounding box
//                     ctx.strokeStyle = 'blue';
//                     ctx.strokeRect(adjustedX0, adjustedY0, adjustedX1 - adjustedX0, adjustedY1 - adjustedY0);

//                     // Draw the recognized word on the canvas
//                     ctx.fillStyle = 'green';
//                     ctx.font = '14px Arial';
//                     ctx.fillText(text, adjustedX0, adjustedY0 - 5); // Draw text slightly above the bounding box

//                     pointsArray.push([adjustedX0, adjustedY0]); // Top-left
//                     pointsArray.push([adjustedX1, adjustedY0]); // Top-right
//                     pointsArray.push([adjustedX1, adjustedY1]); // Bottom-right
//                     pointsArray.push([adjustedX0, adjustedY1]); // Bottom-left
//                 }
//             });

//             if (pointsArray.length === 0) return;

//             const pointsMat = cv.matFromArray(pointsArray.length, 1, cv.CV_32FC2, pointsArray.flat());
//             const rotatedRect = cv.minAreaRect(pointsMat);
//             const textPoints = cv.RotatedRect.points(rotatedRect);
//             setTextPoints(textPoints);
//             const overlappingPoints = [
//                 { x: Math.max(documentPoints[0].x, textPoints[0].x), y: Math.max(documentPoints[0].y, textPoints[0].y) },
//                 { x: Math.min(documentPoints[1].x, textPoints[1].x), y: Math.max(documentPoints[1].y, textPoints[1].y) },
//                 { x: Math.min(documentPoints[2].x, textPoints[2].x), y: Math.min(documentPoints[2].y, textPoints[2].y) },
//                 { x: Math.max(documentPoints[3].x, textPoints[3].x), y: Math.min(documentPoints[3].y, textPoints[3].y) },
//             ];
//             console.log('overlappingPoints', overlappingPoints);

//             setFinalPoints(overlappingPoints);
//             const angle = rotatedRect.angle;

//             setSelectedShape({
//                 id: 'cropperRect',
//                 attributes: {
//                     x: overlappingPoints[1].x,
//                     y: overlappingPoints[1].y,
//                     width: rotatedRect.size.width,
//                     height: rotatedRect.size.height,
//                     rotation: scanType === 'mezuza' ? 0 : angle,
//                 },
//             });
//             setIsCropping(true);

//             ctx.strokeStyle = 'yellow';
//             ctx.lineWidth = 2;
//             ctx.beginPath();
//             overlappingPoints.forEach((point, i) => {
//                 if (i === 0) {
//                     ctx.moveTo(point.x, point.y);
//                 } else {
//                     ctx.lineTo(point.x, point.y);
//                 }
//             });
//             ctx.closePath();
//             ctx.stroke();

//             pointsMat.delete();
//         }).catch((err) => {
//             console.error('Error in Tesseract:', err);
//         });
//     };

//     useEffect(() => {
//         if (image && image.img) {
//             const konvaImage = new window.Image();
//             konvaImage.src = image.img.src;
//             konvaImage.onload = () => {
//                 setLoadedImage(konvaImage);
//             };
//         }
//     }, [image]);

//     useEffect(() => {
//         if (cropRef.current && croptrRef.current) {
//             croptrRef.current.nodes([cropRef.current]);
//             croptrRef.current.getLayer().batchDraw();
//         }
//     }, [isCropping, selectedShape]);

//     const handleShapeChange = (updatedShape) => {
//         setSelectedShape(updatedShape.newItem);
//     };

//     return (
//         <div>
//             <input type="file" onChange={handleImageUpload} />
//             <div style={{ marginTop: '20px' }}>
//                 {image && (
//                     <>
//                         <Stage width={image.width} height={image.height}>
//                             <Layer>
//                                 {loadedImage && (
//                                     <KonvaImage
//                                         image={loadedImage}
//                                         ref={imageRef}
//                                         x={0}
//                                         y={0}
//                                         width={image.width}
//                                         height={image.height}
//                                     />
//                                 )}
//                                 {isCropping && selectedShape && (
//                                     <>
//                                         <Rect
//                                             ref={cropRef}
//                                             x={selectedShape.attributes.x}
//                                             y={selectedShape.attributes.y}
//                                             width={selectedShape.attributes.width}
//                                             height={selectedShape.attributes.height}
//                                             rotation={selectedShape.attributes.rotation}
//                                             stroke="red"
//                                             strokeWidth={2}
//                                             draggable
//                                             onTransformEnd={(e) => {
//                                                 const node = cropRef.current;
//                                                 const scaleX = node.scaleX();
//                                                 const scaleY = node.scaleY();
//                                                 node.scaleX(1);
//                                                 node.scaleY(1);

//                                                 const newWidth = Math.max(MIN_RECT_SIZE, node.width() * scaleX);
//                                                 const newHeight = Math.max(MIN_RECT_SIZE, node.height() * scaleY);

//                                                 const newShape = {
//                                                     ...selectedShape,
//                                                     attributes: {
//                                                         ...selectedShape.attributes,
//                                                         x: node.x(),
//                                                         y: node.y(),
//                                                         width: newWidth,
//                                                         height: newHeight,
//                                                         rotation: node.rotation(),
//                                                     },
//                                                 };

//                                                 handleShapeChange({
//                                                     id: selectedShape.id,
//                                                     newItem: newShape,
//                                                 });
//                                             }}
//                                             onDragEnd={(e) => {
//                                                 const node = cropRef.current;
//                                                 const x = Math.max(0, Math.min(node.x(), image.width - node.width()));
//                                                 const y = Math.max(0, Math.min(node.y(), image.height - node.height()));

//                                                 const newShape = {
//                                                     ...selectedShape,
//                                                     attributes: {
//                                                         ...selectedShape.attributes,
//                                                         x,
//                                                         y,
//                                                     },
//                                                 };

//                                                 handleShapeChange({
//                                                     id: selectedShape.id,
//                                                     newItem: newShape,
//                                                 });
//                                             }}
//                                         />

//                                         <Transformer
//                                             ref={croptrRef}
//                                             rotateEnabled={true}
//                                             flipEnabled={false}
//                                             boundBoxFunc={(oldBox, newBox) => {
//                                                 if (newBox.width < MIN_RECT_SIZE || newBox.height < MIN_RECT_SIZE) {
//                                                     return oldBox;
//                                                 }
//                                                 return newBox;
//                                             }}
//                                         />
//                                     </>
//                                 )}
//                             </Layer>
//                         </Stage>
//                     </>
//                 )}
//             </div>

//             <div style={{ marginTop: '20px' }}>
//                 <h3>Image on HTML Canvas:</h3>
//                 <canvas ref={canvasRef} />
//             </div>

//             {croppedCanvasImage && (
//                 <div style={{ marginTop: '20px' }}>
//                     <h3>Cropped Canvas Image:</h3>
//                     <img src={croppedCanvasImage} alt="Cropped Canvas" />
//                 </div>
//             )}
//         </div>
//     );
// };

// export default ImageCropper;




