import produce from "immer";
import { PDF, CREATE_REPORT, FETCH_REPORT, FETCH_REPORTS, UPDATE_REPORT, REPORT_SORT_BY_TYPE, FETCH_REPORT_BY_PROJECT_ID, SET_CONFIRMED_SCANS_IDS, SET_INDEXES_TO_UPDATE, SET_IMAGE_ID, SET_COORDS, SET_IMAGE_URL, SET_SORTED_REPORT } from "./types";

const initialState = {
    reports: [],
    report: {},
    reportOcroutputSort:{},
    reportSortByType: [],
    confirmedScansIds: [],
    indexesToUpdate: [],
    size: 1,
    reportId: "",
    pdf: "",
    imageId: '',
    imageUrl: '',
    coordinates: {}
};

export const reportReducer = produce((initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_REPORTS:
            initialState.reports = payload;
            return initialState;
        case CREATE_REPORT:
            sessionStorage.setItem('reportId', JSON.stringify(payload?._id))
            initialState.report = payload;
            return initialState;
        case SET_CONFIRMED_SCANS_IDS:
            initialState.confirmedScansIds = payload;
            return initialState;
        case SET_IMAGE_ID:
            initialState.imageId = payload;
            return initialState;
        case SET_IMAGE_URL:
            initialState.imageUrl = payload;
            return initialState;
        case SET_COORDS:
            initialState.coordinates = payload;
            return initialState;
        case SET_INDEXES_TO_UPDATE:
            if (payload.scanId)
                initialState.indexesToUpdate[payload?.scanId] = payload?.indexesList;
            else initialState.indexesToUpdate = [];
            return initialState;
        case PDF:
            initialState.pdf = payload;
            return initialState;
        case FETCH_REPORT_BY_PROJECT_ID:
            initialState.report = payload
            return initialState;
        case REPORT_SORT_BY_TYPE:
            sessionStorage.setItem('keyValueReportArray', JSON.stringify(Object.entries(payload)));
            initialState.reportSortByType = payload;
            return initialState;
        case SET_SORTED_REPORT:
            initialState.reportOcroutputSort = payload
            return initialState;
        case UPDATE_REPORT:
            initialState.pdf = payload
            initialState.reportSortByType = []
            return initialState;
        default:
            return initialState;
    }
}, initialState)



