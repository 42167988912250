// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-register {
  display: flex;
  justify-content: space-around;
  padding: 1.25rem;
  flex-direction: column;
  height: 100vh;
}

.container-register.mobile {
  padding: 0;
  height: auto;
}

.label-row {
  display: flex;
  align-items: center;
}

.register-header {
  display: flex;
  position: fixed;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: 1rem;
  justify-content: center;
  background: var(--1, #0E0D1F);
  box-shadow: 1.21327px 4.85308px 4.85308px 0px rgba(41, 61, 225, 0.13);
}

.scrollable {
  height: calc(100vh - 10rem);
  margin-top: 7rem;
  overflow: auto;
  bottom: 1;
  margin-bottom: 1rem;
}

.scrollable.payment {
  height: auto;
  margin-top: 1rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.lines {
  margin-top: -2vw;
}

.ways {
  margin-top: 1vw;
}

.container-line {
  margin-top: 1vw;
  margin-bottom: 0vw;
}

.login-ways {
  margin-top: 1vw;
}

.ml-2 {
  margin-left: 1rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.very-small-text {
  font-size: 0.75rem;
  padding-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/register/register-style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,6BAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AAEA;EACI,UAAA;EACA,YAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,eAAA;EACA,WAAA;EACA,SAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,6BAAA;EACA,qEAAA;AAGJ;;AAAA;EACI,2BAAA;EACA,gBAAA;EACA,cAAA;EACA,SAAA;EACA,mBAAA;AAGJ;;AAAA;EACI,YAAA;EACA,gBAAA;AAGJ;;AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AAGJ;;AAAA;EACI,gBAAA;AAGJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI,eAAA;EACA,kBAAA;AAGJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI,iBAAA;AAGJ;;AAAA;EACI,oBAAA;AAGJ;;AAAA;EACI,kBAAA;EACA,qBAAA;AAGJ","sourcesContent":[".container-register {\n    display: flex;\n    justify-content: space-around;\n    padding: 1.25rem;\n    flex-direction: column;\n    height: 100vh;\n}\n\n.container-register.mobile {\n    padding: 0;\n    height: auto;\n}\n.label-row{\n    display: flex;\n    align-items: center;\n}\n.register-header {\n    display: flex;\n    position: fixed;\n    width: 100%;\n    margin: 0;\n    box-sizing: border-box;\n    padding: 1rem;\n    justify-content: center;\n    background: var(--1, #0E0D1F);\n    box-shadow: 1.21327px 4.85308px 4.85308px 0px rgba(41, 61, 225, 0.13);\n}\n\n.scrollable {\n    height: calc(100vh - 10rem);\n    margin-top: 7rem;\n    overflow: auto;\n    bottom: 1;\n    margin-bottom: 1rem;\n}\n\n.scrollable.payment {\n    height: auto;\n    margin-top: 1rem;\n}\n\n.inputs {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2.5rem;\n}\n\n.lines {\n    margin-top: -2vw;\n}\n\n.ways {\n    margin-top: 1vw;\n}\n\n.container-line {\n    margin-top: 1vw;\n    margin-bottom: 0vw;\n}\n\n.login-ways {\n    margin-top: 1vw;\n}\n\n.ml-2 {\n    margin-left: 1rem;\n}\n\n.mr-3 {\n    margin-right: 1.5rem;\n}\n\n.very-small-text{\n    font-size: 0.75rem;\n    padding-right: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
