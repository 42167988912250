import { useDispatch, useSelector } from "react-redux";
import Header from "../../header/header";
import { useEffect, useState } from "react";
import { getAllPayments } from "../../payments/store/action";

export default function PaymentManagement() {
    const dispatch = useDispatch();
    const [paymentsList, setPaymentsList] = useState([])
    const paymentsListStore = useSelector((store) => store.paymentReducer?.payments)
    const getPaymentsList = async () => {
        const paymentsList = await dispatch(getAllPayments())
        const sortedPayments = [...paymentsList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPaymentsList(sortedPayments)
    }

    useEffect(() => {
        getPaymentsList()
    }, [])

    useEffect(() => {
        if (paymentsListStore?.length > 0){
            const sortedPayments = [...paymentsListStore].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setPaymentsList(sortedPayments)
        }
    }, [paymentsListStore])

    return (
        <>
            <Header page={'user-management'} context={['ניהול תשלומים']}></Header>

            <div> {paymentsList && paymentsList.length} תשלומים</div>

            <div className={`table-row`}>
                <div className="header-bold-text table-col col">מזהה משתמש</div>
                <div className="header-bold-text table-col col">דרך תשלום</div>
                <div className="header-bold-text table-col col">תאריך רכישה</div>
                <div className="header-bold-text table-col col">סטטוס</div>
                <div className="header-bold-text table-col col">סכום</div>
            </div>

            {paymentsList && paymentsList.map((payment, index) => {
                const dateOnly = payment.createdAt.split('T')[0];
                return (

                    <div key={index} className={`table-row`}>
                        <div className="table-col col">{payment.userId}</div>
                        <div className="table-col col">{payment.paymentOption}</div>
                        <div className="table-col col">{dateOnly}</div>
                        <div className="table-col col">{payment.status}</div>
                        <div className="table-col col">{payment.amount}</div>
                    </div>
                )
            }
            )}
        </>
    )
}