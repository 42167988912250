import React from 'react';

const OSInfo = () => {
    const getOperatingSystem = () => {
        const platform = navigator.platform.toLowerCase();

        if (platform.includes('win')) {
            return 'Windows';
        } else if (platform.includes('mac')) {
            return 'Mac';
        } else if (platform.includes('iphone')) {
            return 'iphone';
        } else if (platform.includes('linux')) {
            return 'Linux';
        } else {
            return platform;
        }
    };

    return (
        <div>
            <p>Operating System: {getOperatingSystem()}</p>
        </div>
    );
};

export default OSInfo;
