
import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage, Rect, Transformer } from 'react-konva';
import Tesseract from 'tesseract.js';

const ImageCropper = () => {
    const [image, setImage] = useState(null);
    const [selectedShape, setSelectedShape] = useState(null);
    const [documentPoints, setDocumentPoints] = useState(null);
    const [textPoints, setTextPoints] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedCanvasImage, setCroppedCanvasImage] = useState(null); // For cropped canvas image
    const [loadedImage, setLoadedImage] = useState(null); // Track Konva image load state

    const imageRef = useRef(null);
    const cropRef = useRef(null);
    const croptrRef = useRef(null);
    const canvasRef = useRef(null); // Ref for the HTML canvas
    const cv = window.cv;
    const MAX_IMAGE_WIDTH = 1536;
    const MAX_IMAGE_HEIGHT = 695;
    const MIN_RECT_SIZE = 20;

    const typeScan = 'tefillin';

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new window.Image();
            img.src = event.target.result;
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                const width = Math.min(MAX_IMAGE_WIDTH, img.width);
                const height = width / aspectRatio;

                setImage({ img, width, height });

                // Draw the image on the plain canvas
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(img, 0, 0, width, height);

                const src = cv.imread(canvas);
                const gray = new cv.Mat();
                cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0);
                const blurred = new cv.Mat();
                cv.GaussianBlur(gray, blurred, new cv.Size(7, 7), 0);
                const edges = new cv.Mat();
                cv.Canny(blurred, edges, 90, 205, 3, false);
                let kernel;
                if (typeScan === 'tefillin') {
                    kernel = cv.Mat.ones(11, 11, cv.CV_8U);
                } else {
                    kernel = cv.Mat.ones(9, 9, cv.CV_8U);
                }

                const dilated = new cv.Mat();
                cv.dilate(edges, dilated, kernel);
                const contours = new cv.MatVector();
                const hierarchy = new cv.Mat();
                cv.findContours(dilated, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

                let maxContour = null;
                let maxArea = 0;
                for (let i = 0; i < contours.size(); ++i) {
                    const contour = contours.get(i);
                    const area = cv.contourArea(contour);
                    if (area > maxArea) {
                        maxArea = area;
                        maxContour = contour;
                    }
                }
                if (maxContour == null) {
                    console.error('OpenCV: maxContour is null');
                    return;
                }
                let points;
                let angle;
                if (typeScan !== 'tefillin' && maxArea < 100000) {
                    // Case of cropped images of mezuza
                    setSelectedShape({
                        id: 'cropperRect',
                        attributes: {
                            x: 0,
                            y: 0,
                            width: width,
                            height: height,
                            rotation: 0,
                        },
                    });
                    setIsCropping(true);
                }
                else {
                    const rotatedRect = cv.minAreaRect(maxContour);
                    angle = rotatedRect.angle;
                    points = cv.RotatedRect.points(rotatedRect);

                    console.log('red points😡😡😡', points);

                    context.strokeStyle = 'red';
                    context.lineWidth = 2;
                    context.beginPath();
                    context.moveTo(points[0].x, points[0].y);
                    for (let i = 1; i < 4; i++) {
                        context.lineTo(points[i].x, points[i].y);
                    }
                    context.closePath();
                    context.stroke();

                    setDocumentPoints(points);

                    // setSelectedShape({
                    //     id: 'cropperRect',
                    //     attributes: {
                    //         x: points[1].x,
                    //         y: points[1].y,
                    //         width: rotatedRect.size.width,
                    //         height: rotatedRect.size.height,
                    //         rotation: typeScan === 'mezuza' ? 0 : angle,
                    //     },
                    // });
                    // setIsCropping(true);
                }

                src.delete();
                gray.delete();
                blurred.delete();
                edges.delete();
                dilated.delete();
                contours.delete();
                hierarchy.delete();
                if (maxContour) maxContour.delete();

                // Call the function to recognize text
                let centroid = { x: 0, y: 0 };
                for (let i = 0; i < points.length; i++) {
                    centroid.x += points[i].x;
                    centroid.y += points[i].y;
                }
                centroid.x /= points.length;
                centroid.y /= points.length;

                // Function to calculate the angle of a point relative to the centroid
                function angleFromCentroid(point) {
                    return Math.atan2(point.y - centroid.y, point.x - centroid.x);
                }

                // Sort the points based on their angle relative to the centroid
                const sortedPoints = points.sort((a, b) => {
                    return angleFromCentroid(a) - angleFromCentroid(b);
                });

                recognizeTextWithTesseract(canvas, sortedPoints, angle);
            };
        };
        reader.readAsDataURL(file);
    };

    // Tesseract.js Text Recognition
    const recognizeTextWithTesseract = (canvas, documentPoints,angle) => {
        Tesseract.recognize(
            canvas,
            'heb', // Hebrew language
            {
                // logger: (m) => console.log(m),
            }
        ).then(({ data: { words } }) => {
            console.log('Words recognized:', words);
            let minX = Infinity;
            let minY = Infinity;
            let maxX = -Infinity;
            let maxY = -Infinity;

            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.lineWidth = 2;
            const pointsArray = [];

            // Loop through each word and draw a bounding box
            words.forEach((word) => {
                console.log('word', word.text);

                const { bbox } = word;
                // if (bbox.x1 - bbox.x0 > 20 || word.text.length > 2) {
                    ctx.strokeStyle = 'blue';
                    ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);

                    // Find the overall bounding box
                    minX = Math.min(minX, bbox.x0);
                    minY = Math.min(minY, bbox.y0);
                    maxX = Math.max(maxX, bbox.x1);
                    maxY = Math.max(maxY, bbox.y1);

                    pointsArray.push([bbox.x0, bbox.y0]); // Top-left
                    pointsArray.push([bbox.x1, bbox.y0]); // Top-right
                    pointsArray.push([bbox.x1, bbox.y1]); // Bottom-right
                    pointsArray.push([bbox.x0, bbox.y1]); // Bottom-left
                // }
                // else {
                //     ctx.strokeStyle = 'pink';
                //     ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);
                //     console.log('filtered');
                // }
            });

            if (pointsArray.length === 0) return;

            const pointsMat = cv.matFromArray(pointsArray.length, 1, cv.CV_32FC2, pointsArray.flat());
            const rotatedRect = cv.minAreaRect(pointsMat);
            const textPoints = cv.RotatedRect.points(rotatedRect);

            let centroid = { x: 0, y: 0 };
            for (let i = 0; i < textPoints.length; i++) {
                centroid.x += textPoints[i].x;
                centroid.y += textPoints[i].y;
            }
            centroid.x /= textPoints.length;
            centroid.y /= textPoints.length;

            // Function to calculate the angle of a point relative to the centroid
            function angleFromCentroid(point) {
                return Math.atan2(point.y - centroid.y, point.x - centroid.x);
            }

            // Sort the points based on their angle relative to the centroid
            const sortedTextPoints = textPoints.sort((a, b) => {
                return angleFromCentroid(a) - angleFromCentroid(b);
            });
            console.log('grenn points🟩🟩🟩 ', textPoints);
            setTextPoints(textPoints)
            ctx.strokeStyle = 'green';
            ctx.lineWidth = 4;
            ctx.beginPath();
            ctx.moveTo(textPoints[0].x, textPoints[0].y);

            for (let i = 1; i < 4; i++) {
                ctx.lineTo(textPoints[i].x, textPoints[i].y);
            }
            ctx.closePath();
            ctx.stroke();
            pointsMat?.delete();

            console.log('😡fffff😡', 'documentPoints', documentPoints, 'textPoints', textPoints);

            const overlappingPoints = [
                { x: Math.max(documentPoints[0].x, sortedTextPoints[0].x), y: Math.max(documentPoints[0].y, sortedTextPoints[0].y) },
                { x: Math.min(documentPoints[1].x, sortedTextPoints[1].x), y: Math.max(documentPoints[1].y, sortedTextPoints[1].y) },
                { x: Math.min(documentPoints[2].x, sortedTextPoints[2].x), y: Math.min(documentPoints[2].y, sortedTextPoints[2].y) },
                { x: Math.max(documentPoints[3].x, sortedTextPoints[3].x), y: Math.min(documentPoints[3].y, sortedTextPoints[3].y) },
            ]
            console.log('overlappingPoints', overlappingPoints);
            const arrayOfArrays = overlappingPoints.map(obj => Object.values(obj));

            console.log('arrayOfArrays', arrayOfArrays);

            const newPointsMat = cv.matFromArray(arrayOfArrays.length, 1, cv.CV_32FC2, arrayOfArrays.flat());
            const newRotatedRect = cv.minAreaRect(newPointsMat);
            const finalPoints = cv.RotatedRect.points(newRotatedRect);
            console.log('finalPoints', finalPoints);
            const angle = newRotatedRect.angle;

            setSelectedShape({
                id: 'cropperRect',
                attributes: {
                    x: finalPoints[1].x,
                    y: finalPoints[1].y,
                    width: newRotatedRect.size.width,
                    height: newRotatedRect.size.height,
                    rotation: typeScan === 'mezuza' ? 0 : angle,
                },
            });
            setIsCropping(true);

            ctx.strokeStyle = 'purple';
            ctx.lineWidth = 10;
            ctx.beginPath();
            ctx.moveTo(finalPoints[0].x, finalPoints[0].y);
            for (let i = 1; i < 4; i++) {
                ctx.lineTo(finalPoints[i].x, finalPoints[i].y);
            }
            ctx.closePath();
            ctx.stroke();

            ctx.strokeStyle = 'pink';
            ctx.lineWidth = 20;
            overlappingPoints.map((point, i) => {
                ctx.beginPath(); // Start a new path
                ctx.moveTo(overlappingPoints[i].x, overlappingPoints[i].y); // Move to the point
                ctx.lineTo(overlappingPoints[i].x + 3, overlappingPoints[i].y + 3); // Draw a very short line
                ctx.stroke(); // Draw the point
            })

            ctx.lineWidth = 4;
            ctx.strokeStyle = 'yellow';
            ctx.beginPath();
            ctx.moveTo(overlappingPoints[0].x, overlappingPoints[0].y);

            for (let i = 1; i < 4; i++) {
                ctx.lineTo(overlappingPoints[i].x, overlappingPoints[i].y);
            }
            ctx.closePath();
            ctx.stroke();


        }).catch((err) => {
            console.error('Error in Tesseract:', err);
        });
    };

    useEffect(() => {
        if (image && image.img) {
            const konvaImage = new window.Image();
            konvaImage.src = image.img.src;
            konvaImage.onload = () => {
                setLoadedImage(konvaImage);
            };
        }
    }, [image]);

    useEffect(() => {
        if (cropRef.current && croptrRef.current) {
            croptrRef.current.nodes([cropRef.current]);
            croptrRef.current.getLayer().batchDraw();
        }
    }, [isCropping, selectedShape]);

    const handleShapeChange = (updatedShape) => {
        setSelectedShape(updatedShape.newItem);
    };

    const handleCrop = () => {
        const cropper = cropRef.current;
        cropper.visible(false);

        // Other crop code (unchanged)
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = croppedCanvasImage;
        link.download = 'cropped-image.png';
        link.click();
    };

    return (
        <div>
            <input type="file" onChange={handleImageUpload} />
            <div style={{ marginTop: '20px' }}>
                {image && (
                    <>
                        <Stage width={image.width} height={image.height}>
                            <Layer>
                                {loadedImage && (
                                    <KonvaImage
                                        image={loadedImage}
                                        ref={imageRef}
                                        x={0}
                                        y={0}
                                        width={image.width}
                                        height={image.height}
                                    />
                                )}
                                {isCropping && selectedShape && (
                                    <>
                                        <Rect
                                            ref={cropRef}
                                            x={selectedShape.attributes.x}
                                            y={selectedShape.attributes.y}
                                            width={selectedShape.attributes.width}
                                            height={selectedShape.attributes.height}
                                            rotation={selectedShape.attributes.rotation}
                                            stroke="red"
                                            strokeWidth={2}
                                            draggable
                                            onTransformEnd={(e) => {
                                                const node = cropRef.current;
                                                const scaleX = node.scaleX();
                                                const scaleY = node.scaleY();
                                                node.scaleX(1);
                                                node.scaleY(1);

                                                const newWidth = Math.max(MIN_RECT_SIZE, node.width() * scaleX);
                                                const newHeight = Math.max(MIN_RECT_SIZE, node.height() * scaleY);

                                                const newShape = {
                                                    ...selectedShape,
                                                    attributes: {
                                                        ...selectedShape.attributes,
                                                        x: node.x(),
                                                        y: node.y(),
                                                        width: newWidth,
                                                        height: newHeight,
                                                        rotation: node.rotation(),
                                                    },
                                                };

                                                handleShapeChange({
                                                    id: selectedShape.id,
                                                    newItem: newShape,
                                                });
                                            }}
                                            onDragEnd={(e) => {
                                                const node = cropRef.current;
                                                const x = Math.max(0, Math.min(node.x(), image.width - node.width()));
                                                const y = Math.max(0, Math.min(node.y(), image.height - node.height()));

                                                const newShape = {
                                                    ...selectedShape,
                                                    attributes: {
                                                        ...selectedShape.attributes,
                                                        x,
                                                        y,
                                                    },
                                                };

                                                handleShapeChange({
                                                    id: selectedShape.id,
                                                    newItem: newShape,
                                                });
                                            }}
                                        />

                                        <Transformer
                                            ref={croptrRef}
                                            rotateEnabled={true}
                                            flipEnabled={false}
                                            boundBoxFunc={(oldBox, newBox) => {
                                                if (newBox.width < MIN_RECT_SIZE || newBox.height < MIN_RECT_SIZE) {
                                                    return oldBox;
                                                }
                                                return newBox;
                                            }}
                                        />
                                    </>
                                )}
                                {/* Draw blue rectangles around each recognized word */}
                            </Layer>
                        </Stage>
                        <button onClick={handleCrop} style={{ marginTop: '20px' }}>
                            Crop
                        </button>
                    </>
                )}
            </div>

            {/* Plain canvas to display the uploaded image */}
            <div style={{ marginTop: '20px' }}>
                <h3>Image on HTML Canvas:</h3>
                <canvas ref={canvasRef} />
            </div>

            {croppedImage && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Cropped Konva Image:</h3>
                    <img src={croppedImage} alt="Cropped Konva" />
                </div>
            )}

            {croppedCanvasImage && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Cropped Canvas Image:</h3>
                    <img src={croppedCanvasImage} alt="Cropped Canvas" />
                    <button onClick={handleDownload} style={{ marginTop: '10px' }}>
                        Download Cropped Image
                    </button>
                </div>
            )}
        </div>
    );
};

export default ImageCropper;
