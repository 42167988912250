export const FETCH_REPORTS = 'FETCH_REPORTS'
export const FETCH_REPORT = 'FETCH_REPORT'
export const FETCH_REPORT_BY_PROJECT_ID = 'FETCH_REPORT_BY_PROJECT_ID'
export const CREATE_REPORT = 'CREATE_REPORT'
export const REPORT_SORT_BY_TYPE = 'REPORT_SORT_BY_TYPE'
export const PDF  = 'PDF'
export const UPDATE_REPORT = 'UPDATE_REPORT' 
export const SET_CONFIRMED_SCANS_IDS = 'SET_CONFIRMED_SCANS_IDS' 
export const SET_INDEXES_TO_UPDATE = 'SET_INDEXES_TO_UPDATE' 
export const SET_IMAGE_ID = 'SET_IMAGE_ID' 
export const SET_IMAGE_URL = 'SET_IMAGE_URL' 
export const SET_COORDS = 'SET_COORDS' 
export const SET_SORTED_REPORT = 'SET_SORTED_REPORT' 





