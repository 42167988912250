import he from '../locale/he'
import en from '../locale/en'
import { useSelector } from 'react-redux';

export const BASE_URL = process.env.REACT_APP_ENVIRONMENT == 'prod' ? "https://stamscanner.co.il:3001" :
  process.env.REACT_APP_ENVIRONMENT == 'dev' ? "https://stamscannerdev.co.il:3001" :
    "https://localhost:3001"
console.log('BASE_URL', BASE_URL);

export const useLanguage = () => {
  const language = useSelector((store) => store.userReducer.language);
  return language == 'en-US' ? en : he;
};

export const smallScreen = () => {
  return window.matchMedia('(max-width: 768px)').matches;
};

export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  if (!email) return false;
  const trimmedEmail = email.trim();
  return emailRegex.test(trimmedEmail) || 'אימייל שגוי';
};

export const validateName = (name) => {
  // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  if (!name) return false;
  const trimmedEmail = name.trim();
  if (trimmedEmail.length > 2)
    return '';
  else
    return 'שם לא יכול להכיל רווחים בלבד';
};

export const emailPatternConst = () => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
}

export const passwordPatternConst = () => {
  // return /^\s*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}\s*$/
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
}

export const dataURLtoBlob = (dataURL) => {
  const parts = dataURL.split(";base64,");
  const contentType = parts[0].split(":")[1];
  const byteCharacters = atob(parts[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const errorTypesArray = {
  TOUCHING_LETTER_SELF: [],
  TOUCHING_LETTER_H: [],
  TOUCHING_LETTER_V: [],
  MISSING_LETTER_OR_TOUCHING_LETTER_H: [],
  MISSING_SPACE_BETWEEN_WORDS: [],
  EXTRA_SPACE_BETWEEN_WORDS: [],
  LETTER_SHAPE_CHANGED: [],
  BROKEN_LETTER: [],
  MISSING_WORD: [],
  ADDED_WORD: [],
  ADDED_LETTER: [],
  ALTERED_WORD: [],
  TOUCHING_LETTERS_READ_AS_NEW: [],
  TOUCHING_LETTERS_READ_AS_ERROR: [],
  STAIN: [],
  EXTRA_SPACE_BETWEEN_LETTERS: [],
}


export const nisPerMezuza = () => {
  return 10
}

export const nisPerAffairInTeffilin = () => {
  return 10
}

export const nisPerSheetOfSefetTora = () => {
  return 4
}

export const getVideoLink = () => {
  return 'https://youtu.be/dIMt1uXHrjw'
}
export const getEmail = () => {
  return 'stamscanner.co.il@gmail.com'
}

export const getPhone = () => {
  return '058-4239503'
}
export const getPhoneNumber = () => {
  return '0584239503'
}
export const getMezuzaText = () => {
  return 'שמע ישראל יהוה אלהינו יהוה אחד ואהבת את יהוה אלהיך בכל לבבך ובכל נפשך ובכל מאדך והיו הדברים האלה אשר אנכי מצוך היום על לבבך ושננתם לבניך ודברת בם בשבתך בביתך ובלכתך בדרך ובשכבך ובקומך וקשרתם לאות על ידך והיו לטטפת בין עיניך וכתבתם על מזזות ביתך ובשעריך והיה אם שמע תשמעו אל מצותי אשר אנכי מצוה אתכם היום לאהבה את יהוה אלהיכם ולעבדו בכל לבבכם ובכל נפשכם ונתתי מטר ארצכם בעתו יורה ומלקוש ואספת דגנך ותירשך ויצהרך ונתתי עשב בשדך לבהמתך ואכלת ושבעת השמרו לכם פן יפתה לבבכם וסרתם ועבדתם אלהים אחרים והשתחויתם להם וחרה אף יהוה בכם ועצר את השמים ולא יהיה מטר והאדמה לא תתן את יבולה ואבדתם מהרה מעל הארץ הטבה אשר יהוה נתן לכם ושמתם את דברי אלה על לבבכם ועל נפשכם וקשרתם אתם לאות על ידכם והיו לטוטפת בין עיניכם ולמדתם אתם את בניכם לדבר בם בשבתך בביתך ובלכתך בדרך ובשכבך ובקומך וכתבתם על מזוזות ביתך ובשעריך למען ירבו ימיכם וימי בניכם על האדמה אשר נשבע יהוה לאבתיכם לתת להם כימי השמים על עכ הארץ'
}

export const getMezuzaTextArray = () => {
  return [
    { "word": "שמע", "percent": 70 },
    { "word": "ישראל", "percent": 70 },
    { "word": "יהוה", "percent": 70 },
    { "word": "אלהינו", "percent": 60 },
    { "word": "יהוה", "percent": 70 },
    { "word": "אחד", "percent": 70 },
    { "word": "ואהבת", "percent": 60 },
    { "word": "את", "percent": 70 },
    { "word": "יהוה", "percent": 70 },
    { "word": "אלהיך", "percent": 60 },
    { "word": "בכל", "percent": 70 },
    { "word": "לבבך", "percent": 70 },
    { "word": "ובכל", "percent": 70 },
    { "word": "נפשך", "percent": 70 },
    { "word": "ובכל", "percent": 70 },
    { "word": "מאדך", "percent": 70 },
    { "word": "והיו", "percent": 70 },
    { "word": "הדברים", "percent": 60 },
    { "word": "האלה", "percent": 80 },
    { "word": "אשר", "percent": 70 },
    { "word": "אנכי", "percent": 70 },
    { "word": "מצוך", "percent": 70 },
    { "word": "היום", "percent": 70 },
    { "word": "על", "percent": 70 },
    { "word": "לבבך", "percent": 70 },
    { "word": "ושננתם", "percent": 50 },
    { "word": "לבניך", "percent": 70 },
    { "word": "ודברת", "percent": 60 },
    { "word": "בם", "percent": 70 },
    { "word": "בשבתך", "percent": 50 },
    { "word": "בביתך", "percent": 50 },
    { "word": "ובלכתך", "percent": 50 },
    { "word": "בדרך", "percent": 70 },
    { "word": "ובשכבך", "percent": 50 },
    { "word": "ובקומך", "percent": 50 },
    { "word": "וקשרתם", "percent": 50 },
    { "word": "לאות", "percent": 70 },
    { "word": "על", "percent": 70 },
    { "word": "ידך", "percent": 70 },
    { "word": "והיו", "percent": 70 },
    { "word": "לטטפת", "percent": 50 },
    { "word": "בין", "percent": 70 },
    { "word": "עיניך", "percent": 70 },
    { "word": "וכתבתם", "percent": 50 },
    { "word": "על", "percent": 70 },
    { "word": "מזזות", "percent": 70 },
    { "word": "ביתך", "percent": 70 },
    { "word": "ובשעריך", "percent": 50 },
    { "word": "והיה", "percent": 70 },
    { "word": "אם", "percent": 70 },
    { "word": "שמע", "percent": 70 },
    { "word": "תשמעו", "percent": 60 },
    { "word": "אל", "percent": 70 },
    { "word": "מצותי", "percent": 50 },
    { "word": "אשר", "percent": 70 },
    { "word": "אנכי", "percent": 70 },
    { "word": "מצוה", "percent": 70 },
    { "word": "אתכם", "percent": 70 },
    { "word": "היום", "percent": 70 },
    { "word": "לאהבה", "percent": 70 },
    { "word": "את", "percent": 70 },
    { "word": "יהוה", "percent": 70 },
    { "word": "אלהיכם", "percent": 60 },
    { "word": "ולעבדו", "percent": 70 },
    { "word": "בכל", "percent": 70 },
    { "word": "לבבכם", "percent": 70 },
    { "word": "ובכל", "percent": 70 },
    { "word": "נפשכם", "percent": 70 },
    { "word": "ונתתי", "percent": 70 },
    { "word": "מטר", "percent": 70 },
    { "word": "ארצכם", "percent": 60 },
    { "word": "בעתו", "percent": 70 },
    { "word": "יורה", "percent": 70 },
    { "word": "ומלקוש", "percent": 50 },
    { "word": "ואספת", "percent": 70 },
    { "word": "דגנך", "percent": 70 },
    { "word": "ותירשך", "percent": 50 },
    { "word": "ויצהרך", "percent": 50 },
    { "word": "ונתתי", "percent": 60 },
    { "word": "עשב", "percent": 70 },
    { "word": "בשדך", "percent": 70 },
    { "word": "לבהמתך", "percent": 50 },
    { "word": "ואכלת", "percent": 70 },
    { "word": "ושבעת", "percent": 60 },
    { "word": "השמרו", "percent": 70 },
    { "word": "לכם", "percent": 70 },
    { "word": "פן", "percent": 70 },
    { "word": "יפתה", "percent": 70 },
    { "word": "לבבכם", "percent": 70 },
    { "word": "וסרתם", "percent": 70 },
    { "word": "ועבדתם", "percent": 70 },
    { "word": "אלהים", "percent": 70 },
    { "word": "אחרים", "percent": 70 },
    { "word": "והשתחויתם", "percent": 70 },
    { "word": "להם", "percent": 70 },
    { "word": "וחרה", "percent": 70 },
    { "word": "אף", "percent": 70 },
    { "word": "יהוה", "percent": 70 },
    { "word": "בכם", "percent": 70 },
    { "word": "ועצר", "percent": 70 },
    { "word": "את", "percent": 70 },
    { "word": "השמים", "percent": 70 },
    { "word": "ולא", "percent": 70 },
    { "word": "יהיה", "percent": 70 },
    { "word": "מטר", "percent": 70 },
    { "word": "והאדמה", "percent": 70 },
    { "word": "לא", "percent": 70 },
    { "word": "תתן", "percent": 70 },
    { "word": "את", "percent": 70 },
    { "word": "יבולה", "percent": 70 },
    { "word": "ואבדתם", "percent": 70 },
    { "word": "מהרה", "percent": 70 },
    { "word": "מעל", "percent": 70 },
    { "word": "הארץ", "percent": 70 },
    { "word": "הטבה", "percent": 70 },
    { "word": "אשר", "percent": 70 },
    { "word": "יהוה", "percent": 70 },
    { "word": "נתן", "percent": 70 },
    { "word": "לכם", "percent": 70 },
    { "word": "ושמתם", "percent": 70 },
    { "word": "את", "percent": 70 },
    { "word": "דברי", "percent": 70 },
    { "word": "אלה", "percent": 70 },
    { "word": "על", "percent": 70 },
    { "word": "לבבכם", "percent": 70 },
    { "word": "ועל", "percent": 70 },
    { "word": "נפשכם", "percent": 70 },
    { "word": "וקשרתם", "percent": 70 },
    { "word": "אתם", "percent": 70 },
    { "word": "לאות", "percent": 70 },
    { "word": "על", "percent": 70 },
    { "word": "ידכם", "percent": 70 },
    { "word": "והיו", "percent": 70 },
    { "word": "לטוטפת", "percent": 60 },
    { "word": "בין", "percent": 70 },
    { "word": "עיניכם", "percent": 50 },
    { "word": "ולמדתם", "percent": 50 },
    { "word": "אתם", "percent": 70 },
    { "word": "את", "percent": 70 },
    { "word": "בניכם", "percent": 60 },
    { "word": "לדבר", "percent": 70 },
    { "word": "בם", "percent": 70 },
    { "word": "בשבתך", "percent": 70 },
    { "word": "בביתך", "percent": 70 },
    { "word": "ובלכתך", "percent": 50 },
    { "word": "בדרך", "percent": 70 },
    { "word": "ובשכבך", "percent": 50 },
    { "word": "ובקומך", "percent": 50 },
    { "word": "וכתבתם", "percent": 50 },
    { "word": "על", "percent": 70 },
    { "word": "מזוזות", "percent": 60 },
    { "word": "ביתך", "percent": 70 },
    { "word": "ובשעריך", "percent": 50 },
    { "word": "למען", "percent": 70 },
    { "word": "ירבו", "percent": 70 },
    { "word": "ימיכם", "percent": 70 },
    { "word": "וימי", "percent": 70 },
    { "word": "בניכם", "percent": 70 },
    { "word": "על", "percent": 70 },
    { "word": "האדמה", "percent": 70 },
    { "word": "אשר", "percent": 70 },
    { "word": "נשבע", "percent": 70 },
    { "word": "יהוה", "percent": 70 },
    { "word": "לאבתיכם", "percent": 70 },
    { "word": "לתת", "percent": 70 },
    { "word": "להם", "percent": 70 },
    { "word": "כימי", "percent": 70 },
    { "word": "השמים", "percent": 70 },
    { "word": "על", "percent": 70 },
    { "word": "הארץ", "percent": 55 },

    { "word": "הא", "percent": 70 },
    { "word": "עלהארץ", "percent": 60 },

  ]
}

export const getMezuzaTextWithoutSpaces = () => {
  return 'שמעישראליהוהאלהינויהוהאחדואהבתאתיהוהאלהיךבכללבבךובכלנפשךובכלמאדךוהיוהדבריםהאלהאשראנכימצוךהיוםעללבבךושננתםלבניךודברתבםבשבתךבביתךובלכתךבדרךובשכבךובקומךוקשרתםלאותעלידךוהיולטטפתביןעיניךוכתבתםעלמזזותביתךובשעריךוהיהאםשמעתשמעואלמצותיאשראנכימצוהאתכםהיוםלאהבהאתיהוהאלהיכםולעבדובכללבבכםובכלנפשכםונתתימטרארצכםבעתויורהומלקושואספתדגנךותירשךויצהרךונתתיעשבבשדךלבהמתךואכלתושבעתהשמרולכםפןיפתהלבבכםוסרתםועבדתםאלהיםאחריםוהשתחויתםלהםוחרהאףיהוהבכםועצראתהשמיםולאיהיהמטרוהאדמהלאתתןאתיבולהואבדתםמהרהמעלהארץהטבהאשריהוהנתןלכםושמתםאתדבריאלהעללבבכםועלנפשכםוקשרתםאתםלאותעלידכםוהיולטוטפתביןעיניכםולמדתםאתםאתבניכםלדברבםבשבתךבביתךובלכתךבדרךובשכבךובקומךוכתבתםעלמזוזותביתךובשעריךלמעןירבוימיכםוימיבניכםעלהאדמהאשרנשבעיהוהלאבתיכםלתתלהםכימיהשמיםעלהארץ'
}

export const getMezuzaTextByLines = () => {
  return [
    'שמע ישראל יהוה אלהינו יהוה אחד ואהבת את',
    'יהוה אלהיך בכל לבבך ובכל נפשך ובכל מאדך והיו',
    'הדברים האלה אשר אנכי מצוך היום על לבבך ושננתם',
    'לבניך ודברת בם בשבתך בביתך ובלכתך בדרך',
    'ובשכבך ובקומך וקשרתם לאות על ידך והיו לטטפת',
    'בין עיניך וכתבתם על מזזות ביתך ובשעריך',
    'והיה אם שמע תשמעו אל מצותי אשר אנכי',
    'מצוה אתכם היום לאהבה את יהוה אלהיכם ולעבדו',
    'בכל לבבכם ובכל נפשכם ונתתי מטר ארצכם בעתו',
    'יורה ומלקוש ואספת דגנך ותירשך ויצהרך ונתתי',
    'עשב בשדך לבהמתך ואכלת ושבעת השמרו לכם',
    'פן יפתה לבבכם וסרתם ועבדתם אלהים אחרים',
    'והשתחויתם להם וחרה אף יהוה בכם ועצר את',
    'השמים ולא יהיה מטר והאדמה לא תתן את יבולה',
    'ואבדתם מהרה מעל הארץ הטבה אשר יהוה נתן לכם',
    'ושמתם את דברי אלה על לבבכם ועל נפשכם וקשרתם',
    'אתם לאות על ידכם והיו לטוטפת בין עיניכם ולמדתם',
    'אתם את בניכם לדבר בם בשבתך בביתך ובלכתך',
    'בדרך ובשכבך ובקומך וכתבתם על מזוזות ביתך',
    'ובשעריך למען ירבו ימיכם וימי בניכם על האדמה',
    'אשר נשבע יהוה לאבתיכם לתת להם כימי השמים',
    'על הארץ'
  ]
}

export const tefillinKadesh = () => {
  return 'וידבר יהוה אל משה לאמר קדש לי כל בכור פטר כל רחם בבני ישראל באדם ובבהמה לי הוא ויאמר משה אל העם זכור את היום הזה אשר יצאתם ממצרים מבית עבדים כי בחזק יד הוציא יהוה אתכם מזה ולא יאכל חמץ היום אתם יצאים בחדש האביב והיה כי יביאך יהוה אל ארץ הכנעני והחתי והאמרי והחוי והיבוסי אשר נשבע לאבתיך לתת לך ארץ זבת חלב ודבש ועבדת את העבדה הזאת בחדש הזה שבעת ימים תאכל מצת וביום השביעי חג ליהוה מצות יאכל את שבעת הימים ולא יראה לך חמץ ולא יראה לך שאר בכל גבלך והגדת לבנך ביום ההוא לאמר בעבור זה עשה יהוה לי בצאתי ממצרים והיה לך לאות על ידך ולזכרון בין עיניך למען תהיה תורת יהוה בפיך כי ביד חזקה הוצאך יהוה ממצרים ושמרת את החקה הזאת למועדה מימים ימימה'
}

export const tefillinKadeshArray = () => {
  return [
    { "word": "וידבר", "percent": 75 },
    { "word": "יהוה", "percent": 90 },
    { "word": "אל", "percent": 85 },
    { "word": "משה", "percent": 80 },
    { "word": "לאמר", "percent": 70 },
    { "word": "קדש", "percent": 75 },
    { "word": "לי", "percent": 95 },
    { "word": "כל", "percent": 60 },
    { "word": "בכור", "percent": 80 },
    { "word": "פטר", "percent": 90 },
    { "word": "כל", "percent": 60 },
    { "word": "רחם", "percent": 80 },
    { "word": "בבני", "percent": 75 },
    { "word": "ישראל", "percent": 60 },
    { "word": "באדם", "percent": 80 },
    { "word": "ובבהמה", "percent": 75 },
    { "word": "לי", "percent": 90 },
    { "word": "הוא", "percent": 70 },
    { "word": "ויאמר", "percent": 80 },
    { "word": "משה", "percent": 75 },
    { "word": "אל", "percent": 85 },
    { "word": "העם", "percent": 60 },
    { "word": "זכור", "percent": 80 },
    { "word": "את", "percent": 95 },
    { "word": "היום", "percent": 70 },
    { "word": "הזה", "percent": 75 },
    { "word": "אשר", "percent": 60 },
    { "word": "יצאתם", "percent": 80 },
    { "word": "ממצרים", "percent": 90 },
    { "word": "מבית", "percent": 75 },
    { "word": "עבדים", "percent": 60 },
    { "word": "כי", "percent": 80 },
    { "word": "בחזק", "percent": 90 },
    { "word": "יד", "percent": 75 },
    { "word": "הוציא", "percent": 60 },
    { "word": "יהוה", "percent": 80 },
    { "word": "אתכם", "percent": 90 },
    { "word": "מזה", "percent": 75 },
    { "word": "ולא", "percent": 60 },
    { "word": "יאכל", "percent": 80 },
    { "word": "חמץ", "percent": 90 },
    { "word": "היום", "percent": 75 },
    { "word": "אתם", "percent": 60 },
    { "word": "יצאים", "percent": 80 },
    { "word": "בחדש", "percent": 90 },
    { "word": "האביב", "percent": 75 },
    { "word": "והיה", "percent": 65 },
    { "word": "כי", "percent": 80 },
    { "word": "יביאך", "percent": 90 },
    { "word": "יהוה", "percent": 75 },
    { "word": "אל", "percent": 60 },
    { "word": "ארץ", "percent": 80 },
    { "word": "הכנעני", "percent": 90 },
    { "word": "והחתי", "percent": 75 },
    { "word": "והאמרי", "percent": 60 },
    { "word": "והחוי", "percent": 70 },
    { "word": "והיבוסי", "percent": 80 },
    { "word": "אשר", "percent": 75 },
    { "word": "נשבע", "percent": 60 },
    { "word": "לאבתיך", "percent": 80 },
    { "word": "לתת", "percent": 90 },
    { "word": "לך", "percent": 75 },
    { "word": "ארץ", "percent": 60 },
    { "word": "זבת", "percent": 80 },
    { "word": "חלב", "percent": 90 },
    { "word": "ודבש", "percent": 75 },
    { "word": "ועבדת", "percent": 60 },
    { "word": "את", "percent": 95 },
    { "word": "העבדה", "percent": 70 },
    { "word": "הזאת", "percent": 80 },
    { "word": "בחדש", "percent": 75 },
    { "word": "הזה", "percent": 75 },
    { "word": "שבעת", "percent": 80 },
    { "word": "ימים", "percent": 90 },
    { "word": "תאכל", "percent": 75 },
    { "word": "מצת", "percent": 60 },
    { "word": "וביום", "percent": 80 },
    { "word": "השביעי", "percent": 90 },
    { "word": "חג", "percent": 75 },
    { "word": "ליהוה", "percent": 60 },
    { "word": "מצות", "percent": 80 },
    { "word": "יאכל", "percent": 90 },
    { "word": "את", "percent": 75 },
    { "word": "שבעת", "percent": 60 },
    { "word": "הימים", "percent": 80 },
    { "word": "ולא", "percent": 90 },
    { "word": "יראה", "percent": 75 },
    { "word": "לך", "percent": 60 },
    { "word": "חמץ", "percent": 80 },
    { "word": "ולא", "percent": 90 },
    { "word": "יראה", "percent": 75 },
    { "word": "לך", "percent": 60 },
    { "word": "שאר", "percent": 80 },
    { "word": "בכל", "percent": 90 },
    { "word": "גבלך", "percent": 75 },
    { "word": "והגדת", "percent": 60 },
    { "word": "לבנך", "percent": 80 },
    { "word": "ביום", "percent": 90 },
    { "word": "ההוא", "percent": 80 },
    { "word": "לאמר", "percent": 60 },
    { "word": "בעבור", "percent": 80 },
    { "word": "זה", "percent": 90 },
    { "word": "עשה", "percent": 75 },
    { "word": "יהוה", "percent": 60 },
    { "word": "לי", "percent": 80 },
    { "word": "בצאתי", "percent": 90 },
    { "word": "ממצרים", "percent": 75 },
    { "word": "והיה", "percent": 65 },
    { "word": "לך", "percent": 80 },
    { "word": "לאות", "percent": 90 },
    { "word": "על", "percent": 75 },
    { "word": "ידך", "percent": 80 },
    { "word": "ולזכרון", "percent": 80 },
    { "word": "בין", "percent": 90 },
    { "word": "עיניך", "percent": 75 },
    { "word": "למען", "percent": 60 },
    { "word": "תהיה", "percent": 80 },
    { "word": "תורת", "percent": 90 },
    { "word": "יהוה", "percent": 75 },
    { "word": "בפיך", "percent": 60 },
    { "word": "כי", "percent": 80 },
    { "word": "ביד", "percent": 90 },
    { "word": "חזקה", "percent": 75 },
    { "word": "הוצאך", "percent": 60 },
    { "word": "יהוה", "percent": 80 },
    { "word": "ממצרים", "percent": 90 },
    { "word": "ושמרת", "percent": 75 },
    { "word": "את", "percent": 95 },
    { "word": "החקה", "percent": 70 },
    { "word": "הזאת", "percent": 80 },
    { "word": "למועדה", "percent": 90 },
    { "word": "מימים", "percent": 75 },
    { "word": "ימימה", "percent": 60 },
  ];

}

export const tefillinShma = () => {
  // return 'שמע ישראל יהוה אלהינו יהוה אחד ואהבת את יהוה אלהיך בכל לבבך ובכל נפשך ובכל מאדך והיו הדברים האלה אשר אנכי מצוך היום על לבבך ושננתם לבניך ודברת בם בשבתך בביתך ובלכתך בדרך ובשכבך ובקומך וקשרתם לאות על ידך והיו לטטפת בין עיניך וכתבתם על מזזות ביתך ובשעריך'
  return ' שמע ישראל יהוה אלהינו יהוה אחד ואהבת את יהוה אלהיך בכל לבבך ובכל נפשך ובכל מאדך והיו הדברים האלה אשר אנכי מצוך היום על לבבך ושננתם לבניך ודברת בם בשבתך בביתך ובלכתך בדרך ובשכבך ובקומך וקשרתם לאות על ידך והיו לטטפת בין עיניך וכתבתם על עכ מזזות ביתך  שעריההח מווהיחחע ובשעריך'
}

export const tefillinShmaArray = () => {
  return [
    { "word": "שמע", "percent": 75 },
    { "word": "ישראל", "percent": 60 },
    { "word": "יהוה", "percent": 90 },
    { "word": "אלהינו", "percent": 85 },
    { "word": "יהוה", "percent": 90 },
    { "word": "אחד", "percent": 80 },
    { "word": "ואהבת", "percent": 75 },
    { "word": "את", "percent": 95 },
    { "word": "יהוה", "percent": 90 },
    { "word": "אלהיך", "percent": 80 },
    { "word": "בכל", "percent": 70 },
    { "word": "לבבך", "percent": 60 },
    { "word": "ובכל", "percent": 75 },
    { "word": "נפשך", "percent": 80 },
    { "word": "ובכל", "percent": 60 },
    { "word": "מאדך", "percent": 75 },
    { "word": "והיו", "percent": 80 },
    { "word": "הדברים", "percent": 90 },
    { "word": "האלה", "percent": 80 },
    { "word": "אשר", "percent": 60 },
    { "word": "אנכי", "percent": 80 },
    { "word": "מצוך", "percent": 90 },
    { "word": "היום", "percent": 75 },
    { "word": "על", "percent": 95 },
    { "word": "לבבך", "percent": 70 },
    { "word": "ושננתם", "percent": 80 },
    { "word": "לבניך", "percent": 75 },
    { "word": "ודברת", "percent": 60 },
    { "word": "בם", "percent": 70 },
    { "word": "בשבתך", "percent": 80 },
    { "word": "בביתך", "percent": 75 },
    { "word": "ובלכתך", "percent": 60 },
    { "word": "בדרך", "percent": 70 },
    { "word": "ובשכבך", "percent": 80 },
    { "word": "ובקומך", "percent": 75 },
    { "word": "וקשרתם", "percent": 60 },
    { "word": "לאות", "percent": 70 },
    { "word": "על", "percent": 80 },
    { "word": "ידך", "percent": 90 },
    { "word": "והיו", "percent": 75 },
    { "word": "לטטפת", "percent": 60 },
    { "word": "בין", "percent": 70 },
    { "word": "עיניך", "percent": 80 },
    { "word": "וכתבתם", "percent": 75 },
    { "word": "על", "percent": 95 },
    { "word": "מזוזות", "percent": 70 },
    { "word": "ביתך", "percent": 80 },
    { "word": "ובשעריך", "percent": 75 },

    { "word": "עכ", "percent": 70 },
    // { "word": "הא", "percent": 100 },
    { "word": "עלהארץ", "percent": 60 },

  ];

}

export const tefillinVehaya = () => {
  return 'והיה כי יבאך יהוה אל ארץ הכנעני כאשר נשבע לך ולאבתיך ונתנה לך והעברת כל פטר רחם ליהוה וכל פטר שגר בהמה אשר יהיה לך הזכרים ליהוה וכל פטר חמר תפדה בשה ואם לא תפדה וערפתו וכל בכור אדם בבניך תפדה והיה כי ישאלך בנך מחר לאמר מה זאת ואמרת אליו בחזק יד הוציאנו יהוה ממצרים מבית עבדים ויהי כי הקשה פרעה לשלחנו ויהרג יהוה כל בכור בארץ מצרים מבכר אדם ועד בכור בהמה על כן אני זבח ליהוה כל פטר רחם הזכרים וכל בכור בני אפדה והיה לאות על ידכה ולטוטפת בין עיניך כי בחזק יד הוציאנו יהוה ממצרים'
}

export const tefillinVehayaArray = () => {
  return [
    { "word": "והיה", "percent": 75 },
    { "word": "כי", "percent": 60 },
    { "word": "יבאך", "percent": 80 },
    { "word": "יהוה", "percent": 90 },
    { "word": "אל", "percent": 85 },
    { "word": "ארץ", "percent": 70 },
    { "word": "הכנעני", "percent": 60 },
    { "word": "כאשר", "percent": 75 },
    { "word": "נשבע", "percent": 80 },
    { "word": "לך", "percent": 90 },
    { "word": "ולאבתיך", "percent": 85 },
    { "word": "ונתנה", "percent": 70 },
    { "word": "לך", "percent": 90 },
    { "word": "והעברת", "percent": 75 },
    { "word": "כל", "percent": 60 },
    { "word": "פטר", "percent": 80 },
    { "word": "רחם", "percent": 90 },
    { "word": "יהוה", "percent": 85 },
    { "word": "וכל", "percent": 70 },
    { "word": "פטר", "percent": 60 },
    { "word": "שגר", "percent": 80 },
    { "word": "בהמה", "percent": 90 },
    { "word": "אשר", "percent": 75 },
    { "word": "יהיה", "percent": 80 },
    { "word": "לך", "percent": 90 },
    { "word": "הזכרים", "percent": 70 },
    { "word": "יהוה", "percent": 85 },
    { "word": "וכל", "percent": 60 },
    { "word": "פטר", "percent": 80 },
    { "word": "חמר", "percent": 90 },
    { "word": "תפדה", "percent": 75 },
    { "word": "בשה", "percent": 60 },
    { "word": "ואם", "percent": 80 },
    { "word": "לא", "percent": 90 },
    { "word": "תפדה", "percent": 75 },
    { "word": "וערפתו", "percent": 60 },
    { "word": "וכל", "percent": 70 },
    { "word": "בכור", "percent": 80 },
    { "word": "אדם", "percent": 90 },
    { "word": "בבניך", "percent": 75 },
    { "word": "תפדה", "percent": 60 },
    { "word": "והיה", "percent": 80 },
    { "word": "כי", "percent": 90 },
    { "word": "ישאלך", "percent": 75 },
    { "word": "בנך", "percent": 60 },
    { "word": "מחר", "percent": 80 },
    { "word": "לאמר", "percent": 90 },
    { "word": "מה", "percent": 75 },
    { "word": "זאת", "percent": 60 },
    { "word": "ואמרת", "percent": 80 },
    { "word": "אליו", "percent": 90 },
    { "word": "בחזק", "percent": 75 },
    { "word": "יד", "percent": 70 },
    { "word": "הוציאנו", "percent": 80 },
    { "word": "יהוה", "percent": 90 },
    { "word": "ממצרים", "percent": 75 },
    { "word": "מבית", "percent": 60 },
    { "word": "עבדים", "percent": 80 },
    { "word": "ויהי", "percent": 90 },
    { "word": "כי", "percent": 75 },
    { "word": "הקשה", "percent": 60 },
    { "word": "פרעה", "percent": 80 },
    { "word": "לשלחנו", "percent": 90 },
    { "word": "ויהרג", "percent": 75 },
    { "word": "יהוה", "percent": 60 },
    { "word": "כל", "percent": 80 },
    { "word": "בכור", "percent": 90 },
    { "word": "בארץ", "percent": 75 },
    { "word": "מצרים", "percent": 60 },
    { "word": "מבכור", "percent": 80 },
    { "word": "אדם", "percent": 90 },
    { "word": "עד", "percent": 75 },
    { "word": "בכור", "percent": 60 },
    { "word": "בהמה", "percent": 80 },
    { "word": "על", "percent": 90 },
    { "word": "כן", "percent": 75 },
    { "word": "אני", "percent": 60 },
    { "word": "זבח", "percent": 80 },
    { "word": "ליהוה", "percent": 90 },
    { "word": "כל", "percent": 75 },
    { "word": "פטר", "percent": 60 },
    { "word": "רחם", "percent": 80 },
    { "word": "הזכרים", "percent": 90 },
    { "word": "וכל", "percent": 75 },
    { "word": "בכור", "percent": 60 },
    { "word": "בני", "percent": 80 },
    { "word": "אפדה", "percent": 90 },
    { "word": "והיה", "percent": 75 },
    { "word": "לאות", "percent": 60 },
    { "word": "על", "percent": 80 },
    { "word": "ידכה", "percent": 90 },
    { "word": "ולטוטפת", "percent": 75 },
    { "word": "בין", "percent": 60 },
    { "word": "עיניך", "percent": 80 },
    { "word": "כי", "percent": 90 },
    { "word": "בחזק", "percent": 75 },
    { "word": "יד", "percent": 70 },
    { "word": "הוציאנו", "percent": 80 },
    { "word": "יהוה", "percent": 90 },
    { "word": "ממצרים", "percent": 75 },
  ];
}

export const tefillinShamoa = () => {
  return 'והיה אם שמוע תשמעו אל מצוותי אשר אנכי מצווה אתכם היום לאהבה את ה אלוהיכם ולעבדו בכל לבבכם ובכל נפשכם ונתתי מטר ארצכם בעתו יורה ומלקוש ואספת דגנך ותירשך ויצהרך ונתתי עשב בשדך לבהמתך ואכלת ושבעת הישמרו לכם פן יפתה לבבכם וסרתם ועבדתם אלהים אחרים והשתחוויתם להם וחרה אף ה בכם ועצר את השמים ולא יהיה מטר והאדמה לא תיתן את יבולה ואבדתם מהרה מעל הארץ הטובה אשר יהוה נתן לכם ושמתם את דברי אלה על לבבכם ועל נפשכם וקשרתם אותם לאות על ידיכם והיו לטוטפות בין עיניכם ולמדתם אותם את בניכם לדבר בם בשבתך בביתך ובלכתך בדרך ובשכבך ובקומך וכתבתם על מזוזות ביתך ובשעריך למען ירבו ימיכם וימי בניכם על האדמה אשר נשבע יהוה לאבתיכם לתת להם כימי השמים על הארץ'
}
//todo 
export const tefillinShamoaArray = () => {
  return [
    { "word": "והיה", "percent": 75 },
    { "word": "אם", "percent": 60 },
    { "word": "שמוע", "percent": 80 },
    { "word": "תשמעו", "percent": 70 },
    { "word": "אל", "percent": 90 },
    { "word": "מצוותי", "percent": 65 },
    { "word": "אשר", "percent": 85 },
    { "word": "אנכי", "percent": 50 },
    { "word": "מצווה", "percent": 75 },
    { "word": "אתכם", "percent": 80 },
    { "word": "היום", "percent": 70 },
    { "word": "לאהבה", "percent": 90 },
    { "word": "את", "percent": 95 },
    { "word": "ה", "percent": 100 },
    { "word": "אלוהיכם", "percent": 80 },
    { "word": "ולעבדו", "percent": 75 },
    { "word": "בכל", "percent": 85 },
    { "word": "לבבכם", "percent": 70 },
    { "word": "ובכל", "percent": 80 },
    { "word": "נפשכם", "percent": 60 },
    { "word": "ונתתי", "percent": 90 },
    { "word": "מטר", "percent": 75 },
    { "word": "ארצכם", "percent": 70 },
    { "word": "בעתו", "percent": 80 },
    { "word": "יורה", "percent": 60 },
    { "word": "ומלקוש", "percent": 70 },
    { "word": "ואספת", "percent": 85 },
    { "word": "את", "percent": 95 },
    { "word": "תבואתך", "percent": 70 },
    { "word": "ונתתי", "percent": 90 },
    { "word": "עשב", "percent": 80 },
    { "word": "בשדך", "percent": 60 },
    { "word": "לבמתך", "percent": 70 },
    { "word": "ואכלת", "percent": 80 },
    { "word": "ושבעת", "percent": 90 },
    { "word": "הישמרו", "percent": 75 },
    { "word": "לך", "percent": 60 },
    { "word": "פן", "percent": 85 },
    { "word": "יפתה", "percent": 70 },
    { "word": "לבבכם", "percent": 80 },
    { "word": "וסרתם", "percent": 60 },
    { "word": "ועבדתם", "percent": 90 },
    { "word": "אלהים", "percent": 75 },
    { "word": "אחרים", "percent": 70 },
    { "word": "והשתחוויתם", "percent": 80 },
    { "word": "להם", "percent": 60 },
    { "word": "וחרה", "percent": 90 },
    { "word": "אף", "percent": 75 },
    { "word": "ה", "percent": 100 },
    { "word": "בכם", "percent": 70 },
    { "word": "ועצר", "percent": 80 },
    { "word": "את", "percent": 95 },
    { "word": "השמים", "percent": 60 },
    { "word": "ולא", "percent": 85 },
    { "word": "יהיה", "percent": 70 },
    { "word": "מטר", "percent": 90 },
    { "word": "והאדמה", "percent": 75 },
    { "word": "לא", "percent": 60 },
    { "word": "תיתן", "percent": 80 },
    { "word": "את", "percent": 95 },
    { "word": "יבולה", "percent": 70 },
    { "word": "ואבדתם", "percent": 90 },
    { "word": "מהרה", "percent": 80 },
    { "word": "מעל", "percent": 60 },
    { "word": "הארץ", "percent": 70 },
    { "word": "הטובה", "percent": 85 },
    { "word": "אשר", "percent": 75 },
    { "word": "נתן", "percent": 90 },
    { "word": "ה", "percent": 100 },
    { "word": "אלוהיכם", "percent": 80 },
    { "word": "ושמתם", "percent": 75 },
    { "word": "את", "percent": 95 },
    { "word": "דברי", "percent": 70 },
    { "word": "אלה", "percent": 80 },
    { "word": "על", "percent": 60 },
    { "word": "לבבכם", "percent": 70 },
    { "word": "ועל", "percent": 85 },
    { "word": "נפשכם", "percent": 60 },
    { "word": "וקשרתם", "percent": 90 },
    { "word": "אותם", "percent": 75 },
    { "word": "לאות", "percent": 80 },
    { "word": "על", "percent": 95 },
    { "word": "ידיכם", "percent": 70 },
    { "word": "והיו", "percent": 90 },
    { "word": "לטוטפות", "percent": 80 },
    { "word": "בין", "percent": 60 },
    { "word": "עיניכם", "percent": 70 },
    { "word": "ולמדתם", "percent": 85 },
    { "word": "אותם", "percent": 75 },
    { "word": "את", "percent": 95 },
    { "word": "בניכם", "percent": 70 },
    { "word": "לדבר", "percent": 90 },
    { "word": "בם", "percent": 80 },
    { "word": "בשבתך", "percent": 60 },
    { "word": "בביתך", "percent": 70 },
    { "word": "ובילך", "percent": 85 },
    { "word": "בדרך", "percent": 75 },
    { "word": "ובשכבך", "percent": 90 },
    { "word": "ובקומך", "percent": 80 },
    { "word": "וקשרתם", "percent": 60 },
    { "word": "אותם", "percent": 70 },
    { "word": "לאות", "percent": 85 },
    { "word": "על", "percent": 75 },
    { "word": "ידיכם", "percent": 90 },
    { "word": "והיו", "percent": 80 },
    { "word": "לטוטפות", "percent": 60 },
    { "word": "בין", "percent": 70 },
    { "word": "עיניכם", "percent": 85 },
    { "word": "וכתבתם", "percent": 75 },
    { "word": "על", "percent": 90 },
    { "word": "מזוזות", "percent": 80 },
    { "word": "ביתך", "percent": 60 },
    { "word": "ובשעריך", "percent": 70 },

    { "word": "ומשערההדיך", "percent": 70 },
  ];

}

export const tefillinVehayaWithLines = () => {
  return [
    'והיה כי יבאך יהוה אל ארץ הכנעני כאשר נשבע לך ולאבתיך ונתנה לך והעברת כל פטר',
    'רחם ליהוה וכל פטר שגר בהמה אשר יהיה לך הזכרים ליהוה וכל פטר חמר תפדה בשה',
    'ואם לא תפדה וערפתו וכל בכור אדם בבניך תפדה והיה כי ישאלך בנך מחר לאמר מה',
    'זאת ואמרת אליו בחזק יד הוציאנו יהוה ממצרים מבית עבדים ויהי כי הקשה פרעה',
    'לשלחנו ויהרג יהוה כל בכור בארץ מצרים מבכר אדם ועד בכור בהמה על כן אני זבח',
    'ליהוה כל פטר רחם הזכרים וכל בכור בני אפדה והיה לאות על ידכה ולטוטפת בין',
    'עיניך כי בחזק יד הוציאנו יהוה ממצרים']
}

export const getMezuzaTextByLinesWithThreshold = () => {
  const threshold = 100;
  return [
    { 'שמע ישראל יהוה אלהינו יהוה אחד ואהבת את': threshold },
    { 'יהוה אלהיך בכל לבבך ובכל נפשך ובכל מאדך והיו': threshold },
    { 'הדברים האלה אשר אנכי מצוך היום על לבבך ושננתם': threshold },
    { 'לבניך ודברת בם בשבתך בביתך ובלכתך בדרך': threshold },
    { 'ובשכבך ובקומך וקשרתם לאות על ידך והיו לטטפת': threshold },
    { 'בין עיניך וכתבתם על מזזות ביתך ובשעריך': threshold },
    { 'והיה אם שמע תשמעו אל מצותי אשר אנכי': threshold },
    { 'מצוה אתכם היום לאהבה את יהוה אלהיכם ולעבדו': threshold },
    { 'בכל לבבכם ובכל נפשכם ונתתי מטר ארצכם בעתו': threshold },
    { 'יורה ומלקוש ואספת דגנך ותירשך ויצהרך ונתתי': threshold },
    { 'עשב בשדך לבהמתך ואכלת ושבעת השמרו לכם': threshold },
    { 'פן יפתה לבבכם וסרתם ועבדתם אלהים אחרים': threshold },
    { 'והשתחויתם להם וחרה אף יהוה בכם ועצר את': threshold },
    { 'השמים ולא יהיה מטר והאדמה לא תתן את יבולה': threshold },
    { 'ואבדתם מהרה מעל הארץ הטבה אשר יהוה נתן לכם': threshold },
    { 'ושמתם את דברי אלה על לבבכם ועל נפשכם וקשרתם': threshold },
    { 'אתם לאות על ידכם והיו לטוטפת בין עיניכם ולמדתם': threshold },
    { 'אתם את בניכם לדבר בם בשבתך בביתך ובלכתך': threshold },
    { 'בדרך ובשכבך ובקומך וכתבתם על מזוזות ביתך': threshold },
    { 'ובשעריך למען ירבו ימיכם וימי בניכם על האדמה': threshold },
    { 'אשר נשבע יהוה לאבתיכם לתת להם כימי השמים': threshold },
    { 'על הארץ': threshold },
  ]
}