import React from 'react';

function DataDeletionPage() {

  return (
    <div className="data-deletion-page">
      <h1>Data Deletion Instructions</h1>
      <p>If you wish to delete your data from our app, please follow the steps below:</p>
      <ol>
        <li>Send an email to stamscanner2@gmail.com with the subject "Data Deletion Request".</li>
        <li>In the email, include your name, email address, and any other information that can help us identify your account.</li>
        <li>We will process your request within 30 days and confirm once your data has been deleted.</li>
      </ol>
      <p>If you have any questions, please contact us at stamscanner2@gmail.com.</p>
    </div>
  );
}

export default DataDeletionPage;
