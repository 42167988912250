import React, { useEffect, useRef } from 'react';

const ImageCanvas =({ imageUrl }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      // Draw the image on the canvas once it's loaded
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
  }, [imageUrl]);

  return <canvas ref={canvasRef} width={500} height={500} />;

}

export default ImageCanvas;