import { useEffect, useRef, useState } from "react";
import './spinner-styles.scss'
import { useSelector } from "react-redux";

export default function Spinner({ speed }) {//value
    const value=100;
    const [spinner, setSpinner] = useState(0)
    const [spinnerValue, setSpinnerValue] = useState(0)
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const divRef = useRef(null);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        let intervalId;
        setSpinnerValue(value * 2);
        if (spinnerValue !== null && spinnerValue > 1) {
            if (spinner < 100) {
                intervalId = setInterval(() => {
                    setSpinner((prevSpinner) => prevSpinner + 1);
                    setSpinnerValue((prevValue) => prevValue - 1);
                }, speed || 100);
            }
            else {
                setSpinner(0)
                setSpinnerValue(value * 2)
            }
        }

        return () => clearInterval(intervalId);

    }, [spinnerValue]);

    useEffect(() => {
        // Disable scrolling and swiping on mount
        document.body.style.overflow = 'hidden';

        // Enable scrolling and swiping on unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    });


    useEffect(() => {
        const handleKeyPress = (event) => {
            event.preventDefault();
        };

        if (divRef.current) {
            divRef.current.setAttribute('tabIndex', '0');
            divRef.current.addEventListener('keydown', handleKeyPress);
        }

        return () => {
            if (divRef.current) {
                divRef.current.removeEventListener('keydown', handleKeyPress);
            }
        };
    });

    return (
        <>
            <div className={`spinner-container ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>
                <div className={`spinner-track ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>
                    <div className="spinner-progress" style={{ width: `${spinner}%` }} />
                </div>
            </div>

            <div
                ref={divRef}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.6)', // Semi-transparent black overlay
                    zIndex: 999, // Ensure it's on top of everything
                    pointerEvents: 'auto', // Capture all user input events
                }}

            />
        </>
    )
}