import { useDispatch, useSelector } from "react-redux"
import { getAllUsers } from "../../user/store/action";
import { useEffect, useState } from "react";
import './user-management.scss'
import Header from "../../header/header";

export default function UserManagement() {
    const dispatch = useDispatch();
    const [usersList, setUsersList] = useState([])
    const usersListStore = useSelector((store) => store.userReducer.users)

    const getUsersList = async () => {
        const usersList = await dispatch(getAllUsers())
        const sortedUsers = [...usersList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setUsersList(sortedUsers)
    }

    useEffect(() => {
        getUsersList()
    }, [])


    useEffect(() => {
        if (usersListStore?.length > 0) {
            const sortedUsers = [...usersListStore].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setUsersList(sortedUsers)
        }
    }, [usersListStore])

    return (
        <>
            <div>

                <Header page={'user-management'} context={['ניהול משתמשים']}></Header>
                <div> {usersList.length} משתמשים</div>

                <div className={`table-row`}>
                    <div className="header-bold-text table-col col">שם המשתמש</div>
                    <div className="header-bold-text table-col col">Role</div>
                    <div className="header-bold-text table-col col">אימייל</div>
                    <div className="header-bold-text table-col col">תאריך הצטרפות</div>
                    <div className="header-bold-text table-col col">יתרה</div>
                </div>

                <div className="scrollable-div users">
                    {usersList.map((user, index) => {
                        const dateOnly = user.createdAt.split('T')[0];
                        return (

                            <div key={index} className={`table-row`}>
                                <div className="table-col col">{user.customerName}</div>
                                <div className="table-col col">{user.Role}</div>
                                <div className="table-col col">{user.email}</div>
                                <div className="table-col col">{dateOnly}</div>
                                <div className="table-col col">{user.balance}</div>
                            </div>
                        )
                    }
                    )}
                </div>
            </div>

        </>
    )
}