import produce from "immer";
import { FETCH_PROJECTS, SET_LANGUAGE, ADD_PROJECT, UPDATE_PROJECT, SET_PROJECT_ID, DELETE_PROJECT, SET_PROJECTS_OF_TYPE } from "./types";

const initialState = {
    projects: [],
    projectsOfType: [],
    projectId: "",
    locale: 'he'
};

export const projectReducer = produce((initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_PROJECTS:
            initialState.projects = payload;
            return initialState;
        case SET_PROJECTS_OF_TYPE:
            initialState.projectsOfType = payload;
            return initialState;
        case SET_LANGUAGE:
            initialState.locale = payload;
            return initialState;
        case DELETE_PROJECT:
            initialState.projectsOfType = payload;
            return initialState;
        case SET_PROJECT_ID:
            sessionStorage.setItem('currentProjectId', JSON.stringify(payload));
            initialState.projectId = payload;
            return initialState;
        case ADD_PROJECT:
            sessionStorage.setItem('currentProjectId', JSON.stringify(payload.id));
            initialState.projects = payload.data;
            return initialState;
        case UPDATE_PROJECT:
            const projectIndex = initialState.projects.findIndex(project => project._id === payload._id);
            if (projectIndex !== -1) {
                initialState.projects[projectIndex] = { ...initialState.projects[projectIndex], ...payload };
            }
            break;
        default:
            return initialState;
    }
}, initialState)