import * as PaymentApiClient from '../api/tranzila-api-client'
import { CREATE_DOCUMENT, CREATE_TRANSACTION_PAYPAL, CREATE_TRANSACTION_WITH_TOKEN, GET_DOCUMENT, GET_IFRAME, GET_PAYPAL_LINK, GET_TOKEN, REFUND_TRANSACTION } from './types';

export const getIframe = (obj) => async dispatch => {
    try {
        const res = await PaymentApiClient.get_token(obj);
        dispatch({
            type: GET_IFRAME,
            payload: res.url
        })
        return res.url
    }
    catch (error) {
        console.log('error getiframe', error);
    }
}

export const getToken = (obj) => async dispatch => {
    try {
        const res = await PaymentApiClient.get_token(obj);
        dispatch({
            type: GET_TOKEN,
            payload: res.token
        })
        return res.token
    }
    catch (error) {
        console.log('error getiframe', error);
    }
}

export const createTransactionWithToken = (obj) => async dispatch => {
    try {
        const res = await PaymentApiClient.create_transaction_with_token(obj);
        dispatch({
            type: CREATE_TRANSACTION_WITH_TOKEN,
            payload: res
        })
        return res; 
    
    }
    catch (error) {
        console.log(error);
    }
}

export const createTransactionPaypal = (obj) => async dispatch => {
    try {
        const res = await PaymentApiClient.create_transaction_paypal(obj);
        dispatch({
            type: CREATE_TRANSACTION_PAYPAL,
            payload: res.status
        })
        return res; 
    
    }
    catch (error) {
        console.log(error);
    }
}

export const refundTransaction = (obj) => async dispatch => {
    try {
        const res = await PaymentApiClient.refund_transaction(obj);
        console.log('res status refundTransaction', res.status);
        dispatch({
            type: REFUND_TRANSACTION,
            payload: res.status
        })
        console.log('res status', res.status);
        return res; 
    
    }
    catch (error) {
        console.log(error);
    }
}

export const createDocument = (obj) => async dispatch => {
    try {
        const res = await PaymentApiClient.create_document(obj);
        dispatch({
            type: CREATE_DOCUMENT,
            payload: res.status
        })
        return res; 
    
    }
    catch (error) {
        console.log(error);
    }
}

export const getDocument = (obj) => async dispatch => {
    try {
        const res = await PaymentApiClient.get_document(obj);
        console.log('res status getDocument', res.status);
        dispatch({
            type: GET_DOCUMENT,
            payload: res.status
        })
        console.log('res status', res.status);
        return res; // Return the resolved value
    }
    catch (error) {
        console.log(error);
    }
}
export const getPaypalLink = (obj) => async dispatch => {
    try {
        const res = await PaymentApiClient.get_paypal_link(obj);
        console.log('res status getDocument', res);
        dispatch({
            type: GET_PAYPAL_LINK,
            payload: res
        })
        console.log('res status', res.status);
        return res; // Return the resolved value
    }
    catch (error) {
        console.log(error);
    }
}