import React from "react"
import Project from "../project/project";

export default function ProjectList({ projects }) {
    let sortedProjects = [];
    if (Array.isArray(projects)) {
        sortedProjects = [...projects].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    else { sortedProjects = projects }

    return <>
        <div >
            {Array.isArray(sortedProjects) && sortedProjects.map((project) => (
                <Project key={project._id} proj={project} />
            ))}
        </div>
    </>
};
    