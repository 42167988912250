import React, { useEffect, useRef, useState } from "react"
import Scan from "../scan/scan";
import { useDispatch, useSelector } from "react-redux";
import { scansIdsToUpload } from "../store/action";
import './scan-list-style.scss'
import { smallScreen, useLanguage } from "../../../utils/constants";

export default function ScanList({ scans }) {
    let sortedScans = [];
    const t = useLanguage();
    const dispatch = useDispatch();
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const scansIdsToUploadStore = useSelector((store) => store.scanReducer.scansIdsToUpload)
    const [check, setCheck] = useState(false);
    const [checkScan, setCheckScan] = useState(null);
    const isSmallScreen = smallScreen();

    useEffect(() => {
        if (scans.length == 1) {
            setCheckScan(true)
            dispatch(scansIdsToUpload([scans[0]._id]));
        }
    }, [scans])

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    if (Array.isArray(scans)) {
        sortedScans = [...scans].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    else {
        sortedScans = scans
    }

    const handleCheckboxChange = async (event) => {
        const checkbox = event.target;
        if (checkbox.checked) {
            if (scans.length == 1)
                setCheckScan(true);
            const arr = [];
            sortedScans.map((scan) => arr.push(scan._id.toString()))
            await dispatch(scansIdsToUpload(arr));
        }
        else {
            if (scans.length == 1)
                setCheckScan(false);
            console.log('checkScan', checkScan);
            await dispatch(scansIdsToUpload([]));
        }
    }

    useEffect(() => {
        if (scansIdsToUploadStore.length < scans.length)
            setCheck(false)
        if (scansIdsToUploadStore.length == scans.length && scans.length != 0)
            setCheck(true)
    }, [scansIdsToUploadStore])

    return <>
        <div>
            <div className={isSmallScreen ?'scan-message-mobile':"scan-message"}>
                <div className={`choose-all-wrapper ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>
                    <input className={`choose-all-checkbox`} type="checkbox" onChange={(e) => handleCheckboxChange(e)} checked={check} />
                    <div className={`choose-all`}>{t.scan.chooseAll}</div>
                </div>
                <div>{t.scan.scanMessage}</div>
            </div>

            {Array.isArray(sortedScans) && sortedScans.map((scan) => (
                <div key={'scan' + scan._id}>
                    <Scan key={scan._id} scan={scan}
                        checkedScan={checkScan}
                        scansLength={scans.length}
                    />
                </div>))
            }
        </div>
    </>
};
