export default {
    scanTypes: {
        mezuza: 'מזוזות',
        tefillin: 'תפילין',
        tefillinofYad: ' תפילין של יד',
        headTefillin: ' תפילין של ראש',
        torah: 'ספרי תורה',
        ketuvim: 'נביאים וכתובים'
    },
    scanTypesSingle: {
        mezuza: 'מזוזה',
        tefillin: 'תפילין',
        tefillinofYad: 'תפילין של יד',
        headTefillin: 'תפילין של ראש',
        torah: 'ספר תורה',
        ketuvim: 'נביאים וכתובים'
    },
    subTypes: {
        head: 'ראש',
        hand: 'יד',
    },
    errorsType: {
        TOUCHING_LETTER_SELF: 'חלקי אות נוגעים',
        TOUCHING_LETTER_H: 'אותיות נוגעות',
        TOUCHING_LETTER_V: 'נגיעה בשורה אחרת',
        MISSING_LETTER_OR_TOUCHING_LETTER_H: 'אות חסרה או נוגעת',
        MISSING_SPACE_BETWEEN_WORDS: 'רווח חסר בין מילים',
        EXTRA_SPACE_BETWEEN_WORDS: 'רווח מיותר בין מילים',
        LETTER_SHAPE_CHANGED: 'אות שגויה',
        BROKEN_LETTER: 'אות קטועה',
        MISSING_LETTER: 'אות חסרה',
        MISSING_WORD: 'מילה חסרה',
        ADDED_WORD: 'מילה יתירה',
        SWITCHED_WORD: 'מילה שגויה',
        ADDED_LETTER: 'אות  יתירה',
        ALTERED_WORD: 'מילה ששונתה',
        TOUCHING_LETTERS_READ_AS_NEW: 'אות נוגעת נקראת כחדשה',
        TOUCHING_LETTERS_READ_AS_ERROR: 'אות נוגעת נקראת כטעות',
        STAIN: 'כתם',
        EXTRA_SPACE_BETWEEN_LETTERS: 'רווח מיותר בין אותיות',
        OUT_OF_LINE_NOISE: 'רעש מחוץ לשורה'

    },
    forms: {
        invalidCustomerName: 'שם לקוח חייב להכיל מינימום 2 תווים',
        requireField: 'זהו שדה חובה',
        requireFieldCharacters: 'צריך להכניס לפחות 8 תוים',
        invalidEmail: 'מייל לא תקין',
        invalidPhone: ' מס טלפון חייב להכיל 10 ספרות',
        invalidPassword: 'סיסמה צריכה להכיל  אות קטנה אות גדולה ומספר'
    },
    user: {
        customerName: 'שם לקוח',
        userName: "שם משתמש",
        phone: "מספר טלפון",
        email: 'אימייל',
        password: "סיסמה",
        confirmPassword: "אימות סיסמה",
        userForgotPassword: "שכחתי סיסמה",
        resetPassword: "איפוס סיסמה",
        noPasswordExist: 'לא קיימת סיסמה לחשבון זה. נא לאפס סיסמה',
        signup: "הרשם",
        googleLogin: "התחבר באמצעות גוגל",
        appleLogin: 'התחבר באמצעות אפל',
        facebookLogin: 'התחבר באמצעות פייסבוק',
        googleSignin: 'הירשם באמצעות גוגל',
        facebookSingin: 'הירשם באמצעות פייבסוק',
        appleSingin: 'הירשם באמצעות אפל',
        notHaveAccountYet: "עדיין אין לך חשבון?",
        alreadyHaveAccount: "יש לך כבר חשבון?",
        connect: "התחבר",
        login: 'היכנס לחשבון שלך',
        or: 'או',
        newPassword: 'הכנס סיסמה חדשה',
        letsStart: ' בואו נתחיל:)',
        whatIsTheCode: 'מהו הקוד',
        emailVerification: 'הודעת אימייל עם קוד אימות נשלחה עכשיו לכתובת',
        wrongPassword: 'סיסמה  שגויה',
        passwordPattern: '(8 תוים שכוללים אות קטנה אות גדולה ומספר)',
        phoneMessage: 'ליצירת קשר לשירות ותמיכה',
        // passwordPattern: '( אות גדולה אות קטנה ומספר)'
    },
    project: {
        newFolder: "תיקייה חדשה",
        createFolder: "צור תיקייה",
        enterFolderName: 'הכנס שם לתיקייה',
        SelectFolderTypes: "בחר סוגי תיקייה",
        AddingToExistingFolder: "הוספה לתקייה קיימת",
        projects: "פרויקטים",
        close: 'סגור',
        save: 'שמור',
        cancel: 'ביטול',
        scanDetails: 'פרטים על הסריקה',
        sortBy: 'מיין לפי',
        selectfilter: 'בחר מסנן',
        cleanFilters: 'נקה מסננים',
        createDate: 'תאריך יצירה',
        updateDate: 'תאריך עדכון',
        name: 'שם',
        noFolders: 'אין תיקיות זמינות '

    },
    scan: {
        scans: "סריקות",
        lastUpdate: 'עדכון אחרון',
        sortBy: 'מיין לפי',
        selectfilter: 'בחר מסנן',
        cleanFilters: 'נקה מסננים',
        createDate: 'תאריך יצירה',
        updateDate: 'תאריך עדכון',
        dateOfLastScan: 'תאריך סריקה אחרונה',
        name: 'שם',
        noScans: 'אין סריקות זמינות',
        chooseAll: 'בחר הכל',
        scanMessage: 'שים לב! הסריקות עולות לפי הסדר של זמן הצילום'
    },
    newScan: {
        nameScan: "שם סריקה",
        typeScan: "סוג סריקה",
        selectTypeScan: " בחר סוג סריקה",
        selectProj: "בחר פרויקט",
        insertScanName: "הכנס שם סריקה",
        addScan: "הוסף סריקה",
        addImageScan: "הוסף תמונה לסריקה",
        uploadImgs: "לחץ להוספת סריקות  ",
        addFile: "+ הוסף קובץ",
        editScan: 'ערוך סריקה ',
        noAvailableFolders: 'אין תיקיות זמינות',
        chooseExistingFolder: 'בחר תיקייה קיימת:',
        locateScan: ':בחר מיקום שמירת הסריקות',
        chooseTypeScan: ':בחר סוג תיקייה'
    },
    dashboard: {
        goodMorning: 'בוקר טוב',
        goodAfternoon: 'צהריים טובים',
        goodEvening: 'ערב טוב',
        search: 'חיפוש',
        lastUse: 'שימוש אחרון:',
        credits: 'קרדיטים',// '₪',
        leftToUse: 'יתרתך',
        buyMoreCredits: 'לרכישת קרדיטים',
        // buyCredits: 'לרכישת קרדיטים',
        buyCredits: 'לרכישת סריקות',
        newScan: 'סריקה חדשה',
        folders: 'תיקיות',
        comingSoon: 'בקרוב',
        beInContact: '...בוא נשמור על קשר',
        scans:'סריקות',
    },
    navbar: {
        homePage: 'דף הבית',
        folders: 'תיקיות',
        scans: 'סריקות',
        report: 'דוח שגיאות',
        accountManagement: 'ניהול חשבון',
        contact: 'צור קשר',
        addProfile: 'הוספת חשבון',
        removeProfile: 'יציאה מהחשבון',
        editProfile: 'עריכת פרופיל',
        areYouSure: 'האם אתה בטוח?',
        about: 'אודות'
    },
    uploadScans: {
        uploadImages: ' העלאת  צילומים ',
        scans: ' צילומים ',
        areYouSure: '?האם אתה בטוח',
        takeAPicture: 'צילום תמונה',
        uploadScansFromGallery: 'העלאת תמונות מהגלריה',
        confirmScan: 'אשר סריקה',
        ok: 'אישור',
        cancel: 'ביטול',
        back: 'חזרה/העברה לתיקיה',
        addFiles: 'לחץ כאן להעלאת צילום אחד או יותר',
        noBalance: 'אין יתרת כסף בחשבונך',
        goToPayments: 'עבור לטעינת כסף >',
        insufficientBalance: 'יתרה לא מספיקה',
        cropImage: 'חתוך',
        single: 'סריקה בודדת',
        full: 'תפילין שלם',
        resolutionMessage: 'רזולוציית התמונה נמוכה מדי.אנא נסה לצלם שוב.',
        instructions: <div>
            <div>לתוצאה מיטבית יש לצלם על רקע לבן וחלק ולא תחת אור ישיר</div>
            <div>אנו ממליצים באופן גורף לצלם על דף A4 לבן</div>
        </div>,
        imagesNotUpload: <div className="images-not-uploaded">
            <div className="images-not-uploaded-header">.התמונות לא עלו</div>
        </div>
        ,
        imagesNotUploadMessage:
            <div className="images-not-uploaded">
                <div className="images-not-uploaded-header">:אנא נסה שנית עם הקפדה על ההנחיות הבאות</div>
                <div className="images-not-uploaded-row">.יש לצלם על רקע לבן חלק ולא תחת אור ישיר -</div>
                <div className="images-not-uploaded-row">אם התמונה מסובבת - סובבו את התמונה חזרה -</div>
                <div className="images-not-uploaded-row pad">.בכפתור המיועד לכך בתוכנה</div>
            </div>,
        imagesNotUploadMessageMobile:
            <div className="images-not-uploaded">
                <div className="images-not-uploaded-header">אנא נסה שנית עם הקפדה על ההנחיות </div>
                <div className="images-not-uploaded-header">:הבאות</div>
                <div className="images-not-uploaded-row">יש לצלם על רקע לבן חלק ולא תחת -</div>
                <div className="images-not-uploaded-row">.אור ישיר</div>
                <div className="images-not-uploaded-row">אם התמונה מסובבת סובבו את -</div>
                <div className="images-not-uploaded-row">התמונה חזרה בכפתור המיועד לכך</div>
                <div className="images-not-uploaded-row">.בתוכנה</div>
            </div>,
        only: 'רק',
        from: 'תמונות מתוך',
        uploaded: 'עלו',
        cropImageNumber: 'חתוך תמונה ',
        of: 'מתוך',
        cropInstructions: 'במידת הצורך תקן את החיתוך כך שישארו רק מילות הטקסט',
        crop: 'חיתוך',
        rotate: '+90° סיבוב',
        delete: 'מחיקה',
        rotateLess1: '-' + 'סיבוב 1°',
        rotateAdd1: '+' + 'סיבוב 1°',
        dontShowAgain: 'אל תציג שוב',
        saveScans: 'שמירת סריקות',
        saveScanOne: 'שמירת סריקה 1',
        scanning: 'המערכת סורקת את ה',
        yours: 'שלך'
    },
    cropper: {
        crop: 'חתוך תמונה'
    },
    scans: {
        createReport: 'הפקת דו"ח סיכום',
        editReport: 'סינון דו"ח שגיאות',
        chooseScans: 'בחר סריקות',
    },
    report: {
        report: 'דו"ח שגיאות',
        nextMezuza: 'עבור למזוזה הבאה',
        confirmScanEdit: 'אשר עריכת',
        noErrors: 'לא זוהו שגיאות בסריקה זו',
        pdfReady: '!הדוח שלך מוכן',
        open: 'פתיחה',
        download: 'הורדה',
        downloadClicked: '..הדוח יורד ',
        fyi: 'לידיעתך סוכמו',
        shareLink: 'שיתוף',
        linkCopied: 'קישור הועתק',
        reportMessage: 'שים לב! רק שגיאה שתסמן בוי תעבור לדו"ח.'
    },
    contact: {
        contact: 'צור קשר',
        leaveContact: 'השאר לנו פניה:',
        header: 'כותרת',
        contactDescription: 'תיאור פנייה',
        contactContent: 'תוכן הפניה',
        sendContact: 'שלח פניה',
        details: 'פרטים ליצירת קשר:',
        phone: 'טלפון (מקבל גם וואטצאפ):',
        phone1: 'טלפון:',
        email: 'מייל:',
        activityTime: 'שעות פעילות:',
        centerIsClosed: 'המוקד סגור',
        onFridayAndolHidayEves: 'בימי ו וערבי חג:',
        contactSendSuccessfuly: 'הפנייה שלך נשלחה בהצלחה!',
        weTreatContact: 'אנחנו מטפלים בה בהקדם האפשרי',
        sundayThursday: "ימים א' -ה':"
    },
    payment: {
        creditManagment: 'ניהול חשבון ',
        choosePackage: "בחר חבילת קרדיטים:",
        nisIcon: '₪',
        nis: 'קרדיטים',// '₪',
        pay: 'לרכישה',
        priceList: 'מחירון סריקות:',
        mezuza: 'מזוזה',
        nisPerOne: 'קרדיטים ליחידה',// '₪ ליחידה',
        nisPerMezuza: 'קרדיטים למזוזה',
        tefilin: 'תפילין',
        nisPerParsha: 'קרדיטים לכל פרשייה',//'₪ לכל פרשייה',
        seferTora: 'ספר תורה',
        nisPerColumn: 'קרדיטים לכל יריעה',// '₪ לכל יריעה',
        paymentByCreditCard: 'תשלום באמצעות כרטיס אשראי',
        amount: 'סכום',
        numberOfPayment: 'מספר תשלומים',
        creditCardNum: 'מספר כרטיס',
        expiry: 'תוקף',
        cvv: `3 ספרות בגב הכרטיס`,
        cvvShort: 'cvv',
        cardHolderIdNumber: 'תעודת זהות',
        pay: 'לתשלום',
        scans: 'סריקות',
        basicPackage: 'חבילת בסיס',
        silverPackage: 'חבילת כסף',
        goldPackage: 'חבילת זהב',
        marchantPackage: 'חבילת סוחרים',
        adjustPackage: 'צור קשר להתאמת חבילה',
        singlePackage: 'קנייה בודדת',
        specialPrices: 'מחירים מיוחדים לרגל ההשקה בלבד!!',
        payWithBit: 'שלם באמצעות Bit',
        payWithPaypal: 'שלם באמצעות Paypal',
        cardHolderId: 'תעודת זהות',
        cardNumber: 'מספר כרטיס',
        expiryPlaceholder: 'MM/YYYY',
        cuponCodeError: 'קוד קופון לא תקין',
        insertCuponCode: 'הכנס כאן קוד קופון',
        ok: 'אישור',
        cuponCode: 'קוד קופון (אם יש ברשותך)',
        enterCuponCode: 'הקלד קוד קופון',
        copyCuponCode: 'קוד קופון 50% הנחה להעתקה:',
        couponCodeApplied: 'קוד קופון הוחל',
        lastUse: 'שימוש אחרון',
        leftToUse: 'יתרתך',
        payAttention: 'שים לב!',
        message: 'בתפילין המחיר הוא לפרשייה בודדת'

    },
    paymentPageError: {
        credit_card_number: 'מספר כרטיס שגוי',
        card_holder_id_number: 'תעודת זהות שגויה',
        expiry: 'תוקף שגוי',
        cvv: 'מספר שגוי',
    },
    editProfile: {
        editProfile: 'ניהול פרופיל',
        personalInformation: 'פרטים אישיים',
        changePassword: 'שינוי סיסמא',
        saveData: 'שמור נתונים',
        currentPassword: 'סיסמה נוכחית',
    },
    paymentMessages: {
        paymentSucceeded: "!התשלום בוצע בהצלחה",
        paymentFailed: "התשלום לא הצליח, אנא נסה שנית",
        ok: "אישור"

    },
    onePage: {
        about: 'אודות',
        home: 'בית',
        Instruction: 'הדרכה',
        contact: 'צור קשר',
        questions: 'שו"ת',
        login: 'כניסה לחשבון',
        openAcount: 'לפתיחת חשבון',
        questionsAndAnswer: 'שאלות ותשובות',
        sale: 'מבצע היכרות לזמן מוגבל!',
        scansFree:
            <div>
                <div>טעינה של 20 סריקות חינם </div>
                <div>לכל משתמש שפותח חשבון</div>
            </div>,
        moreDetails: 'לפרטים נוספים',
        content3: 'עם הטבות משתלמות להגהה לזמן מוגבל',
        content2: 'יוצאת לדרך!',
        content4: 'הגהת מחשב מדוייקת וחדשנית בכף ידך',
        howItWorks: 'איך זה עובד?',
        scans: 'סורקים',
        cut: 'חותכים',
        getReport: 'מקבלים דו"ח',
        aboutHeader: 'אז מי אנחנו...',
        aboutText:
            <div>
                <div>אנו קבוצה של יזמים, סופרי סת"ם בהווה, שחברנו יחד, וגיבשנו את החזון. </div>
                <div>שנים שנצרכנו להגהה נגישה ומהירה, אם בספרי תורה כדי לדעת את עלות התיקונים האמיתית, אם בקניית מזוזות מסופר מזדמן
                    ואם במהלך עבודה על תפילין בכדי למנוע פסילות.</div>
                <div>יחד תרנו אחר מפתחים כלבבנו שהבינו לעומק את הצורך,
                    וברוך השם לאחר מסע לא קל ולא קצר כלל, הגענו לתוצאה הרצויה.</div>
                <div>ברוך ה' כעת תוכנת ההגהה מדויקת מאי פעם,
                    ומצריכה עבודה מינימלית בלבד בסינון הדו"ח.
                    כמות התראות השווא כעת היא זניחה.
                    בתוכנות ההגהה עד היום היה נצרך שאדם ישב ויעבוד על ההתראות הרבות על מנת לדלות מתוכן את התראות האמת,
                    בעזר ה' בתוכנת סת"ם סקאנר הדבר הזה איננו נצרך יותר.</div>
                <div>החשוב מכל בעינינו הוא שסופרי תפילין העושים שימוש בתוכנת ההגהה שפיתחנו, מגיהים בסיום כתיבת כל פרשייה משמונה פרשיות התפילין, וכך נמנעים לגמרי מפסולים.</div>
            </div>
        ,
        termsofUse: 'תנאי השימוש',
        privacyPolicy: 'מדיניות פרטיות',
        accessibility: 'נגישות',
        cancelTransaction: 'ביטול עסקה',
        steps: 'איך המערכת עובדת',
        openningSale: 'מבצע היכרות',
        video: 'סרטון תדמית - ',
        ourPartners: 'השותפים שלנו',
        otVehadar: 'אות והדר',
        otVehadarText: <div>
            <div>חברת 'אות והדר' גאה לתמוך ב STAM SCANNER,</div>
            <div>המסייעת בשליחות להפצת כתבי קודש מהודרים</div>
            <div>לכל קהילות העולם. יחד, אנו מבטיחים שמירת ההלכה</div>
            <div>והידור המצווה באיכות ללא פשרות. בזכות שיתוף פעולה</div>
            <div>זה, אנו מקרבים את הערכים היהודיים לכל בית ובית.</div>
        </div>,
        otVehadarTextMobile: <div>
            <div>חברת 'אות והדר' גאה לתמוך ב STAM SCANNER,</div>
            <div>המסייעת בשליחות להפצת כתבי קודש מהודרים לכל קהילות העולם.</div>
            <div> יחד, אנו מבטיחים שמירת ההלכה והידור</div>
            <div>  המצווה באיכות ללא פשרות.</div>
            <div>בזכות שיתוף פעולה זה אנו מקרבים את </div>
            <div> הערכים היהודיים לכל בית ובית.</div>
        </div>

    },
    tranzilaErrMessages: {
        0: "שגיאה לא ידועה",
        10018: "פרמטר אחד או יותר שגוי",
        10000: "מפתח חובה חסר",
        10001: "שם טרמינל לא חוקי",
        10002: "מספר כרטיס אשראי שגוי",
        10003: " CVV לא חוקי",
        10004: "חודש תפוגה לא חוקי",
        10005: "שנת תפוגה לא חוקית",
        10006: "סכום לא חוקי או סכום אפס",
        10007: "תוכנית תשלום לא חוקית",
        10008: "מספר התשלומים הכולל לא חוקי",
        10009: "סכום תשלום ראשון לא חוקי",
        10010: "סכום תשלומים אחרים לא חוקיים",
        10011: "מספר זיהוי בעל כרטיס לא חוקי",
        10012: "מטבע לא חוקי",
        10013: "טרמינל לא נמצא ",
        10014: "מצב מעבר לא חוקי",
        10015: "חסר מספר התשלומים הכולל",
        10016: "חסרים שדות כמות התשלומים",
    },
    onePageQuestions: {
        1: 'איך אני עושה קיצור דרך לאפליקציה על מסך הבית?',
        2: 'לפעמים אני מקבל הודעה שהשרת לא זיהה את התמונה ולא חוייבתי, למה זה קורה?',
        3: 'האם ניתן לבחור להגיה רק חסרות ויתרות?',
        4: 'במה האפליקציה שלכם טובה יותר מהתוכנות הקיימות?',
        5: 'האם אני יכול לעזור במשהו?',
    },
    onePageAnswers: {
        1: <div>
            <div>
                בתפריט בפינת המסך ישנה אופציה להוסיף את העמוד במסך הבית.
                יש לחפש את אחת מהאופציות הבאות:
            </div>
            <div>
                ○ הוסף דף ל- מסך הבית
            </div>
            <div>
                ○ צור קיצור דרך במסך הבית
            </div>
            <div>
                ○ התקן כאפליקציה
            </div>
        </div>,
        2: 'יכולות להיות מספר סיבות להופעת ההודעה. הרבה מהמקרים הם בגלל צילום באיכות לא מספיקה לניתוח נתוני התמונה, כמו צילום תחת אור מרובה המייצר השתקפות על האותיות, צילום עם צל משמעותי על הכתב, כשאין קליטת אינטרנט מספיק חזקה, בהעלאת תמונה כאשר היא במצב מאונך בלי שסובבנו אותה- הצילום חייב להיעשות תמיד במצב צילום לאורך.',
        3: "כן, במסך המתקבל לאחר העלאת התמונה ישנן שתי אופציות, 'סנן דוח שגיאות' ו'הפקת דוח סיכום', לאחר שנבחר בסנן דוח שגיאות נקבל את כל ההערות מקוטלגות לפי סוגים, שם נוכל להוריד את סימון ה'וי' מקטגוריות אותן אנו לא מעוניינים להציג בדוח הסופי.",
        4: 'בפיתוח האפליקציה הושקעו שנים של עבודה על מספר אלגוריתמים מתקדמים שנועדו לנתח בדיוק רב את כל סוגי הכתבים. היום אנו נמצאים ברמות דיוק המייתרות את העבודה שנעשית על התוכנות הקיימות בסינון מספר רב של זיהוי שווא של הערות. עם זאת העבודה לא פסקה ואיננו שוקטים על השמרים. הפיתוח ממשיך במלוא הקצב ואנו ממשיכים ומשפרים את היכולות כל הזמן. אנו מצפים להשתפר בכל התחומים שאמורים להקל על מהלך העבודה, וכן לשפר את הדיוק של התוצאות לרמות שטרם נראו.',
        5: 'בהחלט. אנו לומדים מכל משתמש. כל הערה והארה יכולים רק לתרום ולקדם את הפרוייקט שלנו, אנו נדע גם לעמוד בקשר ולעדכן את כלל הלקוחות על שיפורים שנעשו וייעשו. ',
    }

}