import Modal from 'react-bootstrap/Modal';
import { BASE_URL, smallScreen, useLanguage } from '../constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { saveAs } from 'file-saver';
import ReactGA from 'react-ga4';
import { gonative_run_after_storage_permissions, gonativeDownloadBlobUrl } from './BlobDownloader';

export default function ViewPdf({ isShowPdf, setIsShowPdf, reportId }) {

    const [linkCopied, setLinkCopied] = useState(false)
    const [downloadClick, setDownloadClick] = useState(false)
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const t = useLanguage();
    const isSmallScreen = smallScreen();
    const currentUser = useSelector((store) => store.userReducer.currentUser);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    const openPdf = async (pdfLink) => {
        console.log('pdfLink', pdfLink);
        try {
            const newTab = window.open(pdfLink, "_blank", "noopener noreferrer");
            console.log('newTab', newTab);
            const newWindow = window.open(pdfLink, '_blank');

            if (newWindow && !newWindow.closed) {
                console.log('New tab opened successfully');
            } else {
                downloadPdf(pdfLink)
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    function ensureStoragePermissions(callback) {
        // Assuming `window.gonative_storage_permissions` is an indicator for permissions
        if (window.gonative_storage_permissions && window.gonative_storage_permissions.granted) {
            callback();
        } else {
            // Request permissions
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.requestStoragePermissions) {
                window.webkit.messageHandlers.requestStoragePermissions.postMessage({});
            }
            if (window.gonative_storage_permissions_requester && window.gonative_storage_permissions_requester.postMessage) {
                window.gonative_storage_permissions_requester.postMessage({});
            }

            // Callback to execute after permissions are granted
            gonative_run_after_storage_permissions.push(callback);
        }
    }

    const downloadPdf = async (pdfLink) => {
        console.log('downloadPdf');
        
        try {
            setDownloadClick(true)
            const platform = navigator.platform.toLowerCase();

            if (isSmallScreen && platform.includes('linux')) {
                console.log('a');

                ensureStoragePermissions(() => gonativeDownloadBlobUrl(pdfLink));
                return;
            }
            const encodedLink = encodeURIComponent(pdfLink);
            const response = await axios.get(`${BASE_URL}/api/file/downloadPdf/${encodedLink}`, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(blob, `דוח ${reportId}.pdf`);

            setDownloadClick(false)
            if (currentUser.Role == 'user')
                ReactGA.event({
                    category: 'Button',
                    action: 'הורדת דוח',
                });

        } catch (error) {
            console.error('Error in downloadPdf:', error);
        }
    };
    // const downloadPDFfromURL = async (pdfUrl, fileName = 'download.pdf') => {
    //     console.log('downloadPDFfromURL');

    //     try {
    //         const response = await fetch(pdfUrl);
    //         console.log(response);
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = fileName;
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error('Error downloading PDF:', error);
    //     }
    // };

    const activateOpenLink = () => {
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'פתיחת דוח',
            });
    }
    const activateCopyLink = () => {
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'העתקת לינק דוח',
            });
    }
    return (
        <div>
            <Modal
                show={isShowPdf}
                onHide={() => setIsShowPdf('')}
                centered
                className={`modal ${isSmallScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body className='pdf-modal' >
                    <Modal.Title>{t.report.pdfReady}</Modal.Title>
                    <div className={`pdf-link-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                        <button className='open-pdf-link copy' onClick={() => { navigator.clipboard.writeText(isShowPdf); setLinkCopied(true); activateCopyLink() }}>{linkCopied ? t.report.linkCopied : t.report.shareLink}</button>
                        <button className='open-pdf-link' onClick={() => { window.open(isShowPdf, "_blank", "noopener noreferrer"); activateOpenLink() }} >{t.report.open}</button>
                        <button className={`open-pdf-link copy ${downloadClick ? 'disabled' : ''}`} onClick={downloadClick ? undefined : () => { downloadPdf(isShowPdf); }} >{downloadClick == true ? t.report.downloadClicked : t.report.download}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}