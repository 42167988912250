import { useDispatch } from "react-redux";
import { getUsersByRole } from "../../pages/user/store/action";
import SendOpenningEmail from "./send-email";
import { useState } from "react";

export default function SendEmailForAllUsers() {
    const dispatch = useDispatch();
    const [emails, setEmails] = useState([]);
    const [isSending, setIsSending] = useState(false);

    const prepareUsersEmailList = async (role) => {
        setIsSending(true);

        try {
            const users = await dispatch(getUsersByRole(role));
            console.log('users', users.length, users);
            const usersEmailWithIndex = users.map((user, index) => {
                return {
                    index: index,
                    _id: user._id,
                    email: user.email,
                    customerName: user.customerName
                };
            });
            // console.log('usersEmailWithIndex', usersEmailWithIndex.slice(110, 120));
            const emailComponents = usersEmailWithIndex.map((user, index) => {
                if (Object.values(localStorage).includes(user.email)) {
                    console.log('❌');
                    return null;
                }
                else {
                    return (
                        <SendOpenningEmail key={index} id={user._id} userName={user.customerName} email={user.email} />
                    )
                }
            });
            setEmails(emailComponents);
        } catch (error) {
            console.error('Error fetching users:', error);
        }

        setIsSending(false); // Reset sending status after processing
    }

    const localStorageEmails = () => {
        console.log('localStorage', localStorage.length);
        // console.log('😉', Object.values(localStorage));
    }

    const prepareUsersEmailList2 = async () => {
        setIsSending(true);

        try {
            const users = [{ _id: '11', customerName: 'aa', email: 'tzp8281@gmail.com' },
            { _id: '22', customerName: 'bb', email: 'stamscanner2@gmail.com' },
            { _id: '33', customerName: 'cc', email: 'stamscanner.co.il@gmail.com' },
            { _id: '44', customerName: 'dd', email: 'omersvilim5@gmail.com' },
            { _id: '55', customerName: 'ee', email: 'ozp777@gmail.com' },
            ]

            // const users = await dispatch(getUsersByRole(role));
            console.log('users', users.length, users);
            const emailComponents = users.map((user, index) => {
                if (Object.values(localStorage).includes(user.email)) {
                    console.log('❌');
                    return null;
                }
                else {
                    return (
                        <SendOpenningEmail key={index} id={user._id} userName={user.customerName} email={user.email} />
                    )
                }
            });
            setEmails(emailComponents);
        } catch (error) {
            console.error('Error fetching users:', error);
        }

        setIsSending(false); // Reset sending status after processing
    }

    return (
        <>
            {/* <div> 
         <button onClick={() => prepareUsersEmailList('admin')} disabled={isSending}>Send email for all admin users</button>
            </div>
            <div>
                <button onClick={() => prepareUsersEmailList('user')} disabled={isSending}>Send email for all users</button>
            </div>
            <div>
                <button onClick={() => prepareUsersEmailList2()} disabled={isSending} className="btn btn-primary mt-4">Send email for users in list </button>
            </div>

            {emails}
            <div>
                <button onClick={localStorageEmails}>הצגת המשתמשים שנשלח אליהם מייל בפועל</button>
            </div> */}
        </>
    )
}
