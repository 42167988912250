
import { Route, Routes } from 'react-router-dom';
import OnePage from '../one-page/one-page.js';
import ReactGA from 'react-ga4';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
ReactGA.initialize('G-VLLNDJJMGD');

export default function LayoutOnePage() {
    const isReactGaEffect = useRef(true)
    const currentUser = useSelector((store) => store.userReducer.currentUser);

    useEffect(() => {
        if (isReactGaEffect.current == true && currentUser?.Role == 'user') {
            ReactGA.send({ hitType: "pageview", page: '/', title: 'one-page' });
            isReactGaEffect.current = false;
        }
    }, []);

    return (
        <div className="container-fluid" dir='rtl'>
            <div className={`row navbar-row`}>
                <Routes>
                    <Route path="/" element={<OnePage />}></Route>
                </Routes>
            </div>
        </div>
    )
}

