// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-profile-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 1.25rem;
  flex-direction: column;
  height: 100vh;
}

.edit-profile-wrapper.mobile {
  justify-content: unset;
}

.user-edit-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  color: aliceblue;
  border-radius: 0.25rem;
}

.user-wrapper-edit {
  position: relative;
  display: inline-block;
}

.edit-profile-img {
  width: 1.7rem;
  position: absolute;
  top: 76%;
  right: -1%;
  transform: translate(-50%, -50%);
}

.edit-profile-img.ltr {
  left: 5.5%;
  right: unset;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/edit-profile/edit-profile-style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,6BAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AACA;EACI,sBAAA;AAEJ;;AACA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;AAEJ;;AACA;EACI,kBAAA;EACA,qBAAA;AAEJ;;AACA;EACI,aAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,gCAAA;AAEJ;;AAAA;EACI,UAAA;EACA,YAAA;AAGJ","sourcesContent":[".edit-profile-wrapper {\n    display: flex;\n    justify-content: space-around;\n    padding: 1.25rem;\n    flex-direction: column;\n    height: 100vh;\n}\n.edit-profile-wrapper.mobile{\n    justify-content: unset;\n}\n\n.user-edit-profile {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 5rem;\n    height: 5rem;\n    color: aliceblue;\n    border-radius: 0.25rem;\n}\n\n.user-wrapper-edit {\n    position: relative;\n    display: inline-block;\n}\n\n.edit-profile-img {\n    width: 1.7rem;\n    position: absolute;\n    top: 76%;\n    right: -1%;\n    transform: translate(-50%, -50%);\n}\n.edit-profile-img.ltr{\n    left: 5.5%;\n    right: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
