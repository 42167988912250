export default {
    scanTypes: {
        mezuza: 'Mezuzot',
        // mezuza: 'Mezuza',
        tefillin: 'Tefillin',
        tefillinofYad: 'Tefillin of Yad',
        headTefillin: 'Head tefillin',
        torah: 'Torah',
        ketuvim: 'Ketuvim'
    },
    scanTypesSingle: {
        mezuza: 'Mezuza',
        tefillin: 'Tefillin',
        tefillinofYad: 'Tefillin of Yad',
        headTefillin: 'Head tefillin',
        torah: 'Torah',
        ketuvim: 'Nevi`im and Ketuvim'
    },
    subTypes: {
        head: 'head',
        hand: 'hand',
    },
    errorsType: {
        TOUCHING_LETTER_SELF: 'Touching letters',
        TOUCHING_LETTER_H: 'Adjacent letters touch',
        TOUCHING_LETTER_V: 'Letters from different rows touch',
        MISSING_LETTER_OR_TOUCHING_LETTER_H: 'Missing letter or touching letter',
        MISSING_SPACE_BETWEEN_WORDS: 'Missing space between words',
        EXTRA_SPACE_BETWEEN_WORDS: 'Unnecessary space between words',
        LETTER_SHAPE_CHANGED: 'Letter that has been changed',
        BROKEN_LETTER: 'Broken letter',
        MISSING_LETTER: 'Missing letter',
        MISSING_WORD: 'Missing word',
        ADDED_WORD: 'Added word',
        ADDED_LETTER: 'Added letter',
        SWITCHED_WORD: 'Switched word',
        ALTERED_WORD: 'Altered word',
        TOUCHING_LETTERS_READ_AS_NEW: 'A touching letter is read as new',
        TOUCHING_LETTERS_READ_AS_ERROR: 'A touching letter is read as an error',
        STAIN: 'Stain',
        EXTRA_SPACE_BETWEEN_LETTERS: 'Extra space between letters',
        OUT_OF_LINE_NOISE: 'Noise out of line'
    },
    forms: {
        invalidCustomerName: 'First name must have at least two letters.',
        requireField: 'This is a required field',
        requireFieldCharacters: 'At least 8 characters must be entered',
        invalidEmail: 'Invalid email',
        invalidPhone: 'Income must contain 10 digits',
        invalidPassword: 'Uppercase and lowercase letter and number'
    },
    user: {
        customerName: 'Customer name',
        userName: "User name",
        email: "Email",
        phone: "Phone Number",
        password: "Password",
        confirmPassword: "Confirm Password",
        userForgotPassword: "I forgot password",
        resetPassword: "Password reset",
        noPasswordExist: 'There is no password for this account. Please reset password',
        signup: "Signup",
        googleLogin: "Sign in with Google",
        facebookLogin: 'Sign in with Facebook',
        appleLogin: 'Sign in with Apple',
        googleSignin: 'Sign up with Google',
        facebookSingin: 'Sign up with Apple',
        appleSingin: 'Sign in with Apple',
        notHaveAccountYet: "You don't have an account yet?",
        alreadyHaveAccount: "You already have an account?",
        connect: "Connect",
        login: 'Login to your account',
        or: 'Or',
        newPassword: 'Enter New Password',
        letsStart: 'lets start :)',
        whatIsTheCode: 'What is the code',
        emailVerification: 'An email with a verification code has now been sent to the address',
        wrongPassword: 'Wrong password',
        passwordPattern: '(uppercase letter, a lowercase letter and a number)',
        phoneMessage: 'To contact service and support'

    },
    project: {
        createFolder: "Create a folder",
        newFolder: "New folder",
        enterFolderName: 'Enter a name for the folder',
        SelectFolderTypes: "Select folder types",
        AddingToExistingFolder: "Add to existing folder",
        projects: "Projects",
        projScansInProj: "Amount of scans",
        projPageNewProjectButton: "New project",
        projPopupButton: "Show project",
        close: 'close',
        save: 'Save',
        cancel: 'Cancel',
        scanDetails: 'Scan Details',
        sortBy: 'Sort By',
        selectfilter: 'Select A Filter',
        cleanFilters: 'Clean Filters',
        createDate: 'Create Date',
        updateDate: 'Update Date',
        name: 'Name',
        noFolders: 'No Available Folders'
    },
    scan: {
        scans: "Scans",
        newScanButton: "New scan",
        lastUpdate: 'Last Update',
        sortBy: 'Sort By',
        selectfilter: 'Select A Filter',
        cleanFilters: 'Clean Filters',
        createDate: 'Create Date',
        updateDate: 'Update Date',
        dateOfLastScan: 'Date of last scan',
        name: 'Name',
        noScans: 'No Available Scans',
        chooseAll: 'choose all',
        scanMessage: 'The scans went up in chronological order of the time of the photo'
    },
    newScan: {
        nameScan: "Scan name",
        typeScan: "Scan type",
        selectTypeScan: "Select scan type",
        selectProj: "Select project",
        insertScanName: "Input a scan name",
        addScan: "Add scan",
        addImageScan: "Add Image To Scan",
        uploadImgs: "Click to add scans or drag scans here",
        addFile: "Add file +",
        editScan: 'Edit Scan',
        noAvailableFolders: 'no available folders',
        chooseExistingFolder: 'choose existing folder:',
        locateScan: 'Choose a location to save the scans:',
        chooseTypeScan: 'Choose Type Scan:'

    },
    dashboard: {
        goodMorning: 'Good Morning',
        goodAfternoon: 'Good afternoon',
        goodEvening: 'Good Evening',
        search: 'Search',
        lastUse: 'Previous Use:',
        credits: 'credits',
        leftToUse: 'Balance:',
        buyMoreCredits: 'Buy More Credits',
        buyCredits: 'Buy Credits',
        newScan: 'New Scan',
        folders: 'Folders',
        comingSoon: 'Coming soon',
        scans:'scans',
        
    },
    navbar: {
        homePage: 'Home Page',
        folders: 'Folders',
        scans: 'Scans',
        report: 'Report',
        accountManagement: 'Account',
        contact: 'Contact Us',
        addProfile: 'Add profile',
        removeProfile: 'Remove profile',
        editProfile: 'Edit profile',
        areYouSure: 'Are you sure?',
        about: 'About Us',

    },
    uploadScans: {
        uploadImages: ' Upload Images ',
        scans: 'Scans',
        areYouSure: 'Are you sure?',
        takeAPicture: 'Take A Picture',
        uploadScansFromGallery: 'Upload Scans From Gallery',
        confirmScan: 'Confirm scan',
        ok: 'ok',
        cancel: 'cancel',
        back: 'Return/move to folder',
        addFiles: 'Click to add photos or drag photos here',
        noBalance: 'There is no balance in your account',
        goToPayments: 'Go to paymens <',
        insufficientBalance: 'Insufficient balance',
        cropImage: 'crop',
        single: 'Single scan',
        full: 'Full tefillin',
        resolutionMessage: 'Image resulution is too low. Please try capturing again.',
        instructions: 'For best results, shoot on a smooth background and not under direct light',
        instructions: <div>
            <div>For best results, shoot on a smooth background and not under direct light</div>
            <div>We strongly recommend taking photos on a white A4 page</div>
        </div>,
        imagesNotUpload: 'The pictures didnt upload.',
        imagesNotUploadMessage: < div className="images-not-uploaded ltr" >
            <div className="images-not-uploaded-header"> Please try again following the instructions below:</div>
            <div className="images-not-uploaded-row">Make sure to photograph lengthwise and not horizontally.</div>
            <div className="images-not-uploaded-row">Photographs must be taken on a smooth white background and not under direct light.</div>
        </div>,
        imagesNotUploadMessageMobile: < div className="images-not-uploaded ltr" >
            <div className="images-not-uploaded-header"> Please try again following the instructions below:</div>
            <div className="images-not-uploaded-row">Make sure to photograph lengthwise and not horizontally.</div>
            <div className="images-not-uploaded-row">Photographs must be taken on a smooth white background and not under direct light.</div>
        </div>,
        only: 'Only',
        from: 'from',
        uploaded: 'uploaded',
        cropImageNumber: 'Crop image',
        of: 'of',
        cropInstructions: 'Leave only the words of the text without the borders of the image',
        crop: 'Crop',
        rotate: 'Rotate +90°',
        delete: 'Delete',
        rotateLess1: 'Rotate -1°',
        rotateAdd1: 'Rotate +1°',
        dontShowAgain: 'Dont Show Again',
        saveScans:'Save scans',
        saveScanOne:'Save scan one',
        scanning:'The system scans your',
        yours:''
    },
    cropper: {
        crop: 'Crop Image'
    },
    scans: {
        createReport: 'Create Report',
        editReport: 'Edit Report',
        chooseScans: 'choose scans',
        chooseScansToFilterError: 'choose scans to filter error',
    },
    report: {
        report: 'Error Report',
        nextMezuza: 'next mezuza',
        confirmScanEdit: 'Confirm edit',
        noErrors: 'No errors were detected in this scan',
        pdfReady: 'Your report is ready!',
        open: 'Open',
        download: 'Download',
        downloadClicked: 'The pdf is dounloading',
        fyi: "FYI have been agreed upon",
        shareLink: 'Share pdf',
        linkCopied: 'link copied',
        reportMessage: 'Attention! Only an error that will indicate that you will go to the report'
    },
    contact: {
        contact: 'Contact Us',
        leaveContact: 'Leave as a message',
        header: 'header',
        contactDescription: 'Message Description',
        contactContent: 'Message Content',
        sendContact: 'Send',
        details: 'Contact Information:',
        phone: 'Phone:',
        email: 'Email:',
        activityTime: 'Activity Time:',
        centerIsClosed: 'The center is closed',
        onFridayAndolHidayEves: 'On Friday And Holiday Eves:',
        contactSendSuccessfuly: 'Contact send successfuly!',
        weTreatContact: 'We treat contact',
        sundayThursday: 'Sunday-Thursday'
    },
    payment: {
        creditManagment: 'Credits Managment',
        choosePackage: "Choose package:",
        nis: '₪',
        pay: 'Pay',
        priceList: 'Price List:',
        mezuza: 'Mezuza',
        nisPerOne: 'credits per one',
        nisPerMezuza: 'credits per Mezuza',
        tefilin: 'Tefilin',
        nisPerParsha: 'credits per parsha',
        seferTora: 'Sefer Tora',
        nisPerColumn: 'credits per sheet',
        paymentByCreditCard: 'Payment by credit card',
        amount: 'Amount',
        numberOfPayment: 'Number Of Payment',
        creditCardNum: 'Credit Card Num',
        expiry: 'expiry',
        cvv: 'cvv',
        cardHolderIdNumber: 'Card Holder Id Number',
        pay: 'Pay',
        scans: 'scans',
        basicPackage: 'Basic package',
        silverPackage: 'Silver package',
        goldPackage: 'Gold package',
        marchantPackage: 'Marchant package',
        adjustPackage: 'Contact us to adjust a package',
        singlePackage:'Single package',
        specialPrices: 'Special prices for the launch only!!',
        payWithBit: 'Pay With Bit',
        payWithPaypal: 'Pay With Paypal',
        cardHolderId: 'Card Holder Id',
        cardNumber: 'Card Number',
        expiryPlaceholder: 'MM/YYYY',
        cuponCodeError: "Incorrect Cupon Code",
        insertCuponCode: 'Insert Cupon Code',
        ok: 'ok',
        cuponCode: 'Coupon code (if you have one)',
        enterCuponCode: 'Enter a coupon code',
        copyCuponCode: 'Copy cupon code:',
        couponCodeApplied: 'Coupon code applied ',
        lastUse: 'Previous Use',
        leftToUse: 'Balance',
        payAttention: 'Pay attention!',
        message: 'In tefillin, the price is for a single parshia'


    },
    paymentPageError: {
        credit_card_number: 'Credit card number invalid',
        card_holder_id_number: 'Card holder id number invalid',
        expiry: 'Expiry invalid',
        cvv: 'CVV invalid',
    },
    editProfile: {
        editProfile: 'Edit profile',
        personalInformation: 'Personal Information',
        changePassword: 'change password',
        saveData: 'Save data',
        currentPassword: 'current password'
    },
    paymentMessages: {
        paymentSucceeded: "The payment was successfully completed!",
        paymentFailed: "Payment failed, please try again",
        ok: "OK"
    },
    tranzilaErrMessages: {
        0: "Unknown error",
        10018: "One or more parameters are formatted incorrectly",
        10000: "MANDATORY KEY IS MISSING",
        10001: "INVALID TERMINAL NAME",
        10002: "INVALID CREDIT CARD NUMBER",
        10003: "INVALID CVV",
        10004: "INVALID EXPIRATION MONTH",
        10005: "INVALID EXPIRATION YEAR",
        10006: "INVALID AMOUNT OR AMOUNT ZERO",
        10007: "INVALID PAYMENT PLAN",
        10008: "INVALID TOTAL INSTALLMENTS NUMBER",
        10009: "INVALID FIRST INSTALLMENT AMOUNT",
        10010: "INVALID OTHER INSTALLMENTS AMOUNT",
        10011: "INVALID CARD HOLDER ID NUMBER",
        10012: "INVALID CURRENCY",
        10013: "TERMINAL NOT FOUND OR BAD CONFIGURATION",
        10014: "INVALID TRAN MODE",
        10015: "TOTAL INSTALLMENTS NUMBER MISSING",
        10016: "INSTALLMENTS AMOUNT FIELDS MISSING",
    },
    onePageQuestions: {
        1: 'איך אני עושה קיצור דרך לאפליקציה על מסך הבית?',
        2: 'לפעמים אני מקבל הודעה שהשרת לא זיהה את התמונה ולא חוייבתי, למה זה קורה?',
        3: 'האם ניתן לבחור להגיה רק חסרות ויתרות?',
        4: 'במה האפליקציה שלכם טובה יותר מהתוכנות הקיימות?',
        5: 'האם אני יכול לעזור במשהו?',
    },
    onePageAnswers: {
        1: <div>
            <div>
                בתפריט בפינת המסך ישנה אופציה להוסיף את העמוד במסך הבית.
                יש לחפש את אחת מהאופציות הבאות:
            </div>
            <div>
                ○ הוסף דף ל- מסך הבית
            </div>
            <div>
                ○ צור קיצור דרך במסך הבית
            </div>
            <div>
                ○ התקן כאפליקציה
            </div>
        </div>,
        2: 'יכולות להיות מספר סיבות להופעת ההודעה. הרבה מהמקרים הם בגלל צילום באיכות לא מספיקה לניתוח נתוני התמונה, כמו צילום תחת אור מרובה המייצר השתקפות על האותיות, צילום עם צל משמעותי על הכתב, כשאין קליטת אינטרנט מספיק חזקה, בהעלאת תמונה כאשר היא במצב מאונך בלי שסובבנו אותה (יש לכך כפתור ייעודי בתוכנה).',
        3: "כן, במסך המתקבל לאחר העלאת התמונה ישנן שתי אופציות, 'סנן דוח שגיאות' ו'הפקת דוח סיכום', לאחר שנבחר בסנן דוח שגיאות נקבל את כל ההערות מקוטלגות לפי סוגים, שם נוכל להוריד את סימון ה'וי' מקטגוריות אותן אנו לא מעוניינים להציג בדוח הסופי.",
        4: 'בפיתוח האפליקציה הושקעו שנים של עבודה על מספר אלגוריתמים מתקדמים שנועדו לנתח בדיוק רב את כל סוגי הכתבים. היום אנו נמצאים ברמות דיוק המייתרות את העבודה שנעשית על התוכנות הקיימות בסינון מספר רב של זיהוי שווא של הערות. עם זאת העבודה לא פסקה ואיננו שוקטים על השמרים. הפיתוח ממשיך במלוא הקצב ואנו ממשיכים ומשפרים את היכולות כל הזמן. אנו מצפים להשתפר בכל התחומים שאמורים להקל על מהלך העבודה, וכן לשפר את הדיוק של התוצאות לרמות שטרם נראו.',
        5: 'בהחלט. אנו לומדים מכל משתמש. כל הערה והארה יכולים רק לתרום ולקדם את הפרוייקט שלנו, אנו נדע גם לעמוד בקשר ולעדכן את כלל הלקוחות על שיפורים שנעשו וייעשו. ',
    }

}