
import axios from 'axios'
import { BASE_URL } from '../../../utils/constants'

const URL =`${BASE_URL}/api/payment/`

export const get_token = (obj) => {
    return axios.post(`${URL}getToken`,obj).then((response) => response.data)
}

export const submit_form = (transactionObj) => {
    return axios.post(`${URL}submitForm`,transactionObj).then((response) => response.data)
}

export const create_transaction_with_token=(obj)=>{
    console.log('create_transaction_with_token');
    return axios.post(`${URL}createTransactionWithToken`,obj).then((response) => response.data)
}

export const create_transaction_paypal=(obj)=>{
    return axios.post(`${URL}createTransactionPaypal`,obj).then((response) => response.data)
}

export const refund_transaction=(obj)=>{
    return axios.post(`${URL}creditingTransaction`,obj).then((response) => response.data)
}

export const create_document=(obj)=>{
    return axios.post(`${URL}createDocument`,obj).then((response)=>response.data)
}

export const get_document=(obj)=>{
    return axios.post(`${URL}getDocument`,obj).then((response)=>response.data)
}

export const get_paypal_link=(obj)=>{
    return axios.post(`${URL}generatePayPalLink`,obj).then((response)=>response.data)
}